
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from './../../environments/environment'
// const url = environment.serviceUrl



@Injectable({
  providedIn: 'root'
})
export class LoginService {
  url = environment.serviceUrl
  constructor(private http: HttpClient,) {
    if (location.hostname != "localhost")
      this.url = `${location.origin}/api/`;
  }


  login(data) {
    return this.http.post(this.url + 'Account/Login', data)
  }
  loginNew(data) {
    let url = location.href == 'https://oukdevapplicant.samvaadpro.com/login' ? 'https://oukdevapplicant.samvaadpro.com/api/' :
      location.href == 'https://oukqaapplicant.samvaadpro.com/login' ? 'https://oukqaapplicant.samvaadpro.com/api/' :
        location.href == 'https://oukadmissionsdev.konza.go.ke/login' ? 'https://oukadmissionsdev.konza.go.ke/api/' :
        location.href=='https://oukdevapplicanttest.samvaadpro.com/login'?'https://oukdevapplicanttest.samvaadpro.com/login':
          'https://admissions.ouk.ac.ke/api/'
    return this.http.post(url + 'Account/InitialLogin', data)
  }

  forgotPassword(payload: string) {
    return this.http.get(`${this.url}${payload}`);
  }
}

