import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent,
  HttpErrorResponse
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { Router } from "@angular/router";

@Injectable()
export class NoopInterceptor implements HttpInterceptor {
  constructor(private route: Router) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.url.includes('Account/SetToken')) {
      // Exclude the token for this request
      return next.handle(req);
    }
    const authToken = localStorage.getItem('tkn');

    // Clone the request and add the Authorization header if token is present
    const secureReq = authToken
      ? req.clone({
        setHeaders: {
          "Authorization": `Bearer ${authToken}`
        }
      })
      : req;

    return next.handle(secureReq).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 400) {
          console.error('Bad Request:', error.error);
        } else if (error.status === 401) {
          console.error('Unauthorized:', error.error);
          this.route.navigate(['/UnauthorizedComponent']);
          localStorage.clear();
        } else if (error.status === 403) {
          console.error('Forbidden:', error.error);
        } else if (error.status === 404) {
          console.error('Not Found:', error.error);
        } else if (error.status === 500) {
          console.error('Internal Server Error:', error.error);
        } else {
          console.error('An error occurred:', error.error);
        }

        return throwError(error);
      })
    );
  }
}
