<div class="wrapper" *ngIf="isShow=='false'">
    <div class="page-wrapper pt-4">
        <div class="page-content pt-0 pe-2 ps-2">
            <div class="container-fluid">
                <div class="default_class">
                    <div class="content-wrapper p-0">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-xl-12">
                                    <div class="card rounded shadow-lg">
                                        <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                            <h5 class="mb-0 ">Programs</h5>
                                        </div>
                                        <div class="card-body text-center">
                                            <h5>Please submit your personal details to proceed for program application!.</h5>
                                            <button type="button" class="btn btn-primary btn-sm" (click)="redirectToReg()">Click Here</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-student-home *ngIf="roleId==3 && isShow=='true'"></app-student-home>