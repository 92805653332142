<div id="printtable">


    <table width="980" cellspacing="0" cellpadding="0" style="margin: auto;">
        <tbody>
            <tr>
                <td>
                    <table width="980" border="0" cellspacing="10" cellpadding="0">
                        <tbody>
                            <tr>
                                <td height="" style="text-align: center; " valign="middle"><img
                                        src="../../../../assets/img/ouk-logo-new.png" width="135" alt=""></td>
                                <td rowspan="4">
                                    <div class="pt-2 d-flex" style="width: 150px; height: 150px;">
                                        <img src="../assets/img/sample-photo.jpg" class="img-fluid m-auto"
                                            style="margin: auto; max-height: 100%;" alt="">
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td style="text-align: center; ">
                                    <h3 style="margin-top: 0; margin-bottom: 0;"> OPEN UNIVERSITY OF KENYA</h3>
                                </td>
                            </tr>
                            <tr>
                                <td style="text-align: center; ">
                                    <p style="margin: 0px;">P.O. BOX xxxxxxxx, NAIROBI KENYA. TELEPHONE: XXXXXX, EMAIL :
                                        registration@ouk.ac.ke</p>

                                </td>
                            </tr>
                            <tr>
                                <td style="text-align: center; ">
                                    <h4 style="margin-top: 0; margin-bottom: 0;"> Office of the Director, School of
                                        Open,
                                        Distance and eLearning </h4>
                                </td>
                            </tr>


                        </tbody>
                    </table>
                </td>

            </tr>

            <tr>
                <td colspan="2" style="padding: 10px">
                    <table width="100%" border="0" cellspacing="0" cellpadding="6"
                        style="border-top:2px dashed #333; font-size:13px;">
                        <tbody>
                            <tr>
                                <td colspan="4" style="font-size:16px; padding:15px 0px 10px 0px;"><b><u>PERSONAL
                                            DETAILS</u></b>
                                </td>
                            </tr>
                            <tr>
                                <td width="24%"><b>First Name</b></td>
                                <td width="2%" style="text-align: center;">:</td>
                                <td width="24%">{{userDetails?.result?.firstname}}</td>
                                <td width="24%"><b>Middle Name</b></td>
                                <td width="2%" style="text-align: center;">:</td>
                                <td width="24%">{{userDetails?.result?.middlename}}</td>
                            </tr>
                            <tr>
                                <td width="24%"><b>Last Name</b></td>
                                <td width="2%" style="text-align: center;">:</td>
                                <td width="24%">{{userDetails?.result?.lastname}}</td>
                                <td width="24%"><b>Email ID</b></td>
                                <td width="2%" style="text-align: center;">:</td>
                                <td width="24%">{{userDetails?.result?.username}}</td>
                            </tr>

                            <!-- <tr>
                            <td><b>KCSE Index No</b></td>
                            <td width="2%" style="text-align: center;">:</td>
                            <td> {{userDetails?.result?.kcse_index_no}}</td>
                            <td><b>Postal Address</b></td>
                            <td width="2%" style="text-align: center;">:</td>
                            <td>{{userDetails?.result?.postal_address}}</td>
                        </tr> -->

                            <tr>
                                <td><b>P.O.Box</b></td>
                                <td width="2%" style="text-align: center;">:</td>
                                <td>{{userDetails?.result?.postal_address}} </td>
                                <td><b>Postal Code</b></td>
                                <td width="2%" style="text-align: center;">:</td>
                                <td>{{userDetails?.result?.postalcode_of_emergency}}</td>
                            </tr>
                            <tr>
                                <td><b>Physical Address</b></td>
                                <td width="2%" style="text-align: center;">:</td>
                                <td>{{userDetails?.result?.physical_address}}</td>
                                <td><b>Birth Date</b></td>
                                <td width="2%" style="text-align: center;">:</td>
                                <td>{{userDetails?.result?.dob}}</td>
                            </tr>

                            <tr>
                                <td><b>Nationality</b></td>
                                <td width="2%" style="text-align: center;">:</td>
                                <td>{{userDetails?.result?.nationality}}</td>
                                <td><b>Town/City</b></td>
                                <td width="2%" style="text-align: center;">:</td>
                                <td>{{userDetails?.result?.nearest_town}}</td>
                            </tr>
                            <tr>
                                <td><b>Country</b></td>
                                <td width="2%" style="text-align: center;">:</td>
                                <td>{{userDetails?.result?.country_name}}</td>
                                <td><b>ID/Passport No</b></td>
                                <td width="2%" style="text-align: center;">:</td>
                                <td>{{userDetails?.result?.id_passport}}</td>
                            </tr>
                            <tr>
                                <td><b>Gender</b></td>
                                <td width="2%" style="text-align: center;">:</td>
                                <td>{{userDetails?.result?.gender_name}}</td>
                                <td><b>Mobile Number</b></td>
                                <td width="2%" style="text-align: center;">:</td>
                                <td>{{userDetails?.result?.mobileno}}</td>
                            </tr>

                            <tr>
                                <td><b>Martial Status</b></td>
                                <td width="2%" style="text-align: center;">:</td>
                                <td>{{userDetails?.result?.maritalstatus_name}}</td>
                                <td><b>Languages</b></td>
                                <td width="2%" style="text-align: center;">:</td>
                                <td>{{userDetails?.result?.specify_highest_qualification}}</td>
                                <!-- <td><b>Specify Heighest Qualification</b></td>
                            <td width="2%" style="text-align: center;">:</td>
                            <td>{{userDetails?.result?.specify_highest_qualification}}</td> -->
                            </tr>
                            <tr>
                                <td><b>Programme interested in</b></td>
                                <td width="2%" style="text-align: center;">:</td>
                                <td>{{userDetails?.result?.course_name}}</td>
                                <td><b>Mode Of Study</b></td>
                                <td width="2%" style="text-align: center;">:</td>
                                <td>{{userDetails?.result?.mode_of_study}}</td>
                            </tr>
                            <tr>
                                <td colspan="6" style="border-bottom:1px dashed #333; font-size:13px;">
                                    <h5><b>Contacts of next of kin in case of emergency :</b></h5>
                                </td>
                            </tr>
                            <tr>
                                <td><b>Name</b></td>
                                <td width="2%" style="text-align: center;">:</td>
                                <td>{{userDetails?.result?.name_of_emergency}}</td>
                                <td><b>Relationship</b></td>
                                <td width="2%" style="text-align: center;">:</td>
                                <td>{{userDetails?.result?.relationshipname_of_emergency}}</td>
                            </tr>
                            <tr>
                                <td><b>Telephone</b></td>
                                <td width="2%" style="text-align: center;">:</td>
                                <td>{{userDetails?.result?.mobile_no_emergency}}</td>
                                <td><b>Address</b></td>
                                <td width="2%" style="text-align: center;">:</td>
                                <td>{{userDetails?.result?.address_of_emergency}}</td>
                            </tr>
                            <tr>
                                <td><b>Postal Code</b></td>
                                <td width="2%" style="text-align: center;">:</td>
                                <td>{{userDetails?.result?.postalcode_of_emergency}}</td>
                                <td><b>Town/City</b></td>
                                <td width="2%" style="text-align: center;">:</td>
                                <td>{{userDetails?.result?.towncity_of_emergency}}</td>

                            </tr>

                            <tr>
                                <td><b>Country</b></td>
                                <td width="2%" style="text-align: center;">:</td>
                                <td>{{userDetails?.result?.country_name}}</td>
                                <td><b>Email ID</b></td>
                                <td width="2%" style="text-align: center;">:</td>
                                <td>{{userDetails?.result?.emailid_of_emergency}}</td>

                            </tr>
                            <tr style="border-bottom:0px dashed #333; font-size:13px;">
                                <td colspan="6">
                                    <h5><b>Education Details :</b></h5>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div *ngFor="let item of userDetails?.lst">
                        <table width="100%" border="1" cellspacing="0" cellpadding="6">
                            <tbody>
                                <tr>
                                    <td width="24%"><b>School/College/University </b></td>
                                    <td width="2%" style="text-align: center;">:</td>
                                    <td width="24%" colspan="3">{{item.INSTITUTION_NAME}}</td>
                                    <td width="24%"><b>Qualification Obtained</b></td>
                                    <td width="2%" style="text-align: center;">:</td>
                                    <td width="24%">{{item.QUALIFICATION}}</td>
                                </tr>
                                <tr>
                                    <td width="24%"><b>From</b></td>
                                    <td width="2%" style="text-align: center;">:</td>
                                    <td width="24%">{{item.FROM_PERIOD | date:'yyyy-MM-dd'}}</td>
                                    <td width="24%"><b>To</b></td>
                                    <td width="2%" style="text-align: center;">:</td>
                                    <td width="24%">{{item.TO_PERIOD | date:'yyyy-MM-dd'}}</td>

                                </tr>
                                <tr>
                                    <td><b>Address</b></td>
                                    <td width="2%" style="text-align: center;">:</td>
                                    <td> {{item.ADDRESS}}</td>
                                    <td><b>Additional Information</b></td>
                                    <td width="2%" style="text-align: center;">:</td>
                                    <td>{{item.ADDITIONAL_INFORMATION}}</td>
                                </tr>
                                <tr>
                                    <td><b>Exam No./Exam Reg No</b></td>
                                    <td width="2%" style="text-align: center;">:</td>
                                    <td colspan="4"><span>{{item.EXAM_REG_NO}}</span></td>

                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <table width="100%" border="0" cellspacing="0" cellpadding="6">
                        <tbody>
                            <tr style="border-bottom:0px dashed #333; font-size:13px;">
                                <td colspan="6">
                                    <h5><b>Applicant's Working Experience</b></h5>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div *ngFor="let item of userDetails?.lstt">
                        <table width="100%" border="1" cellspacing="0" cellpadding="6">
                            <tbody>

                                <tr>
                                    <td width="24%"><b>Employer</b></td>
                                    <td width="2%" style="text-align: center;">:</td>
                                    <td width="24%">{{item.WORKEXP_DETAIL_EMPLOYER}}</td>
                                    <td width="24%"><b>Designation</b></td>
                                    <td width="2%" style="text-align: center;">:</td>
                                    <td width="24%">{{item.WORKEXP_DETAIL_DESIGNATION}}</td>

                                </tr>
                                <tr>
                                    <td width="24%"><b>From Year</b></td>
                                    <td width="2%" style="text-align: center;">:</td>
                                    <td width="24%">{{item.WORKEXP_DETAIL_FROM_YEAR | date:'yyyy-MM-dd'}}</td>
                                    <td width="24%"><b>To Year</b></td>
                                    <td width="2%" style="text-align: center;">:</td>
                                    <td width="24%">{{item.WORKEXP_DETAIL_TO_YEAR | date:'yyyy-MM-dd'}}</td>

                                </tr>

                            </tbody>
                        </table>
                    </div>
                    <table width="100%" border="0" cellspacing="0" cellpadding="6">
                        <tbody>

                            <tr style="border-bottom:1px dashed #333; font-size:13px;">
                                <td colspan="6">
                                    <h5><b>Referees :</b></h5>
                                </td>
                            </tr>

                            <tr>
                                <td width="24%"><b>Name</b></td>
                                <td width="2%" style="text-align: center;">:</td>
                                <td width="24%">{{userDetails?.result?.sponsors_name}}</td>
                                <td width="24%"><b>Mobile No</b></td>
                                <td width="2%" style="text-align: center;">:</td>
                                <td width="24%">{{userDetails?.result?.sponsors_mobile}}</td>

                            </tr>
                            <tr>
                                <td width="24%"><b>Email Id</b></td>
                                <td width="2%" style="text-align: center;">:</td>
                                <td width="74%" colspan="3"> {{userDetails?.result?.sponsors_email}}</td>
                            </tr>

                        </tbody>
                    </table>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<div class="footer float-end pt-2">
    <button class="btn btn-save btn-sm" (click)="downloadForm()"> Download</button>
</div>