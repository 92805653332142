<div class="wrapper">
    <div class="page-wrapper pt-4">
        <div class="page-content pt-0 pe-2 ps-2">
            <div class="container-fluid">
                <div class="default_class">
                    <div class="content-wrapper p-0">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-xl-12">
                                    <div class="card rounded">
                                        <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                            <h5 class="mb-0">Application</h5>
                                        </div>
                                        <div class="card-body">
                                            <div class="table-responsive" *ngIf="userData.length">
                                                <table id="patreggraph" class="pt-4" datatable
                                                    class="table table-striped table-bordered mt-2 pt-2 table-sm small display"
                                                    style="width:100%">
                                                    <thead class="top"
                                                        style="background-color: #624fd7; color:#ffffff;">
                                                        <tr>
                                                            <th>Programme Name </th>
                                                            <!-- <th>Application Fee </th> -->
                                                            <!-- <th>Fee Payment Status</th> -->

                                                            <th>Application Status</th>
                                                            <th>Edit/Update</th>

                                                            <th>Application Form</th>
                                                            <!-- <th>Admission Letter</th> -->
                                                            <!-- *ngIf="(isPaid == 2 && aprvId == 1) || (aprvId == 4 && isPaid == 2)" -->
                                                            <!-- <th>Acceptance</th> -->
                                                            <!-- <th>Enrollment</th> -->
                                                            <th *ngIf="tenantCode !='88968955'">Payment</th>


                                                        </tr>
                                                    </thead>

                                                    <tbody>

                                                        <tr class="text-center" *ngFor="let item of userData">
                                                            <td>{{item.course_name}}</td>
                                                            <!-- <td> NA</td> -->
                                                            <!-- <td>
                                                                <p *ngIf="isPaid == 0">NA</p>
                                                                <button *ngIf="isPaid == 1" type="button"
                                                                    class="btn btn-info btn-sm" (click)="pay()">Pay
                                                                </button>
                                                            </td> -->

                                                            <td>
                                                                <!-- {{isPaid==0?'Saved':isPaid==1?'Submitted':isPaid==2?'Submitted':'Submitted'}} -->
                                                                {{item.application_status}} <br>
                                                                <!-- <p
                                                                    *ngIf="item.application_status =='FOLLOW UP'">
                                                                    (Please check your email)</p> -->

                                                                <a href="javascript:" style="cursor: pointer;"
                                                                    *ngIf="item.application_status =='FOLLOW UP'"
                                                                    (click)="showComments(item)">View Comments</a>

                                                                <!-- <a href="javascript:" style="cursor: pointer;"
                                                                   *ngIf="item.application_status =='SAVED'"
                                                                   (click)="gotoregform(item)"
                                                                  >Resume</a> -->
                                                                <!-- data-bs-target="#commentsModel" -->
                                                                <!-- data-bs-target="modal" -->
                                                                <!-- <button class="btn btn-save btn-sm" *ngIf="userData?.application_status =='FOLLOW UP'"
                                                            data-bs-target="#commentsModel"
                                                            data-bs-toggle="modal" >View</button> -->
                                                            </td>
                                                            <!-- *ngIf="userData?.application_status == 'RESUBMITTED'"
                                                             *ngIf="item.application_status == 'FOLLOW UP'" -->
                                                            <td><button class="btn btn-save btn-sm"
                                                                    *ngIf="item.application_status == 'FOLLOW UP'"
                                                                    (click)="edit(item.course_id)">Edit</button>
                                                                <button class="btn btn-save btn-sm"
                                                                    *ngIf="item.application_status == 'SAVED'"
                                                                    (click)="gotoregform(item)">Resume</button>
                                                            </td>
                                                            <!-- data-bs-target="#editPopup" data-bs-toggle="modal" -->

                                                            <td>

                                                                <button class="btn btn-save btn-sm"
                                                                    data-bs-target="#applicationForm"
                                                                    *ngIf="isPaid == 2" (click)="getUserDetails(item)"
                                                                    data-bs-toggle="modal">View</button>

                                                            </td>
                                                            <!-- *ngIf="aprvId == 3 || aprvId == 4 && isPaid == 2" -->
                                                            <!-- <td>
                                                                <button class="btn btn-save btn-sm"
                                                                    *ngIf="aprvId == 3 && isPaid == 2"
                                                                    (click)="openAdmissionLtr()">Download Admission
                                                                    Letter</button>
                                                                <button class="btn btn-save btn-sm"
                                                                    (click)="openAdmissionLtr()">Download</button>

                                                            </td> -->
                                                            <!-- <td>
                                                                <button class="btn btn-save btn-sm"
                                                                    *ngIf="isPaid == 2 && aprvId == 3"
                                                                    (click)="AcceptMthd()">Accept</button>
                                                                <b *ngIf="aprvId == 4 && isPaid == 2"
                                                                    class="text-success">Accepted</b>
                                                            </td> -->
                                                            <!-- <td><button class="btn btn-save btn-sm"
                                                                    *ngIf="userDetails?.result?.is_submit==5 && aprvId == 4"
                                                                    (click)="edit()">Enroll</button>
                                                                <p *ngIf="userDetails?.result?.is_submit==8">
                                                                    <b>Pending</b>
                                                                </p>
                                                            </td> -->

                                                            <!-- data-bs-target="#editPopup" data-bs-toggle="modal" -->

                                                            <td *ngIf="tenantCode !='88968955'">
                                                                <span *ngIf="aprvId == 4">
                                                                    <button class="btn btn-save btn-sm"
                                                                        *ngIf="makepayment" (click)="makePayment()">Make
                                                                        Payment</button>
                                                                </span>

                                                                <p *ngIf="amountPaid"><b>Enrolled</b></p>

                                                            </td>
                                                            <!-- <b *ngIf="aprvId == 4 && isPaid == 2"
                                                                class="text-success">Accepted</b> -->

                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div class="text-center" *ngIf="!userData.length">
                                                No Records to display
                                            </div>

                                        </div>
                                        <!-- <div class="card-header  headerbg pt-2" style="border-bottom:3px solid #d8eff8;">
                                            <h5 class="mb-0">Available Programs</h5>
                                        </div>
                                        <div>
                                            <app-student-available-programs *ngIf="roleId==3" [applicationString]="applicationString"></app-student-available-programs>

                                        </div> -->
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<app-student-available-programs *ngIf="roleId==3"></app-student-available-programs>

<input type="button" class="d-none" id="btnShowModel" data-bs-target="#InstallmentDetails" data-bs-toggle="modal">
<app-paid-installments *ngIf="showInstallmentModel" [InstallmentList]="InstallmentList"
    (modelCloseEvent)="showInstallmentModel=false;">
</app-paid-installments>


<button data-bs-target="#paymentModel" data-bs-toggle="modal" id="payment"></button>
<div class="modal" tabindex="-1" id="paymentModel">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Modal title</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body text-center">
                <p> <b>Payment Successful!</b> </p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
            </div>
        </div>
    </div>
</div>


<div class="modal" data-backdrop="static" data-keyboard="false" id="applicationForm">
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Application Details</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" id="md_close"
                    aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div id="printtable" class="pt-5">


                    <table width="980" cellspacing="0" cellpadding="0" style="margin: auto;">
                        <tbody>
                            <tr>
                                <td>
                                    <table width="980" border="0" cellspacing="10" cellpadding="0">
                                        <tbody>
                                            <tr>
                                                <td height="" style="text-align: center; " valign="middle"><img
                                                        src="../../../../assets/img/ouk-logo-new.png" width="200"
                                                        alt=""></td>
                                                <td rowspan="4">
                                                    <div class="pt-2 d-flex" style="width: 150px; height: 150px;">
                                                        <img [src]="userDetails?.result?.upload_4"
                                                            class="img-fluid m-auto"
                                                            style="margin: auto; max-height: 100%;" alt="">
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style="text-align: center; ">
                                                    &nbsp;
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style="text-align: center; ">
                                                    <table width="100%">
                                                        <tr>
                                                            <td width="35%">
                                                                <p style="margin: 0px; font-size: 12px !important;">
                                                                    <b>P.O. BOX</b> 2440-00606 NAIROBI, KENYA
                                                                </p>
                                                            </td>
                                                            <td width="35%">
                                                                <p style="margin: 0px; font-size: 12px !important;">
                                                                    <b>TELEPHONE:</b> 0202000211/0202000212
                                                                </p>
                                                            </td>
                                                            <td width="30%">
                                                                <p style="margin: 0px; font-size: 12px !important;">
                                                                    <b>EMAIL:</b> admissions@ouk.ac.ke
                                                                </p>
                                                            </td>
                                                        </tr>
                                                    </table>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td style="text-align: center; ">
                                                    &nbsp;
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style="text-align: center; ">
                                                    <h4 style="margin-top: 0; margin-bottom: 10px; font-weight: 600;">
                                                        OUK Admissions Office</h4>
                                                    <h5 style="color: #fe7f50; font-weight: 500">STUDENT APPLICATION
                                                        ONLINE FORM</h5>
                                                </td>
                                            </tr>


                                        </tbody>
                                    </table>
                                </td>

                            </tr>


                            <tr>
                                <td colspan="2" style="padding: 10px">
                                    <table width="100%" border="0" cellspacing="0" cellpadding="6"
                                        style="border-top:2px dashed #333; font-size:13px;">
                                        <tbody>
                                            <tr>
                                                <td colspan="6" style="border-bottom:1px dashed #333; font-size:13px;">
                                                    <h5><b>(A) PERSONAL DETAILS</b></h5>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="24%"><b>First Name</b></td>
                                                <td width="2%" style="text-align: center;">:</td>
                                                <td width="24%">{{userDetails?.result?.firstname}}</td>
                                                <td width="24%"><b>Middle Name</b></td>
                                                <td width="2%" style="text-align: center;">:</td>
                                                <td width="24%">{{userDetails?.result?.middlename}}</td>

                                            </tr>
                                            <tr>
                                                <td width="24%"><b>Last Name</b></td>
                                                <td width="2%" style="text-align: center;">:</td>
                                                <td width="24%">{{userDetails?.result?.lastname}}</td>
                                                <td><b>Country of Residence</b></td>
                                                <td width="2%" style="text-align: center;">:</td>
                                                <td>{{userDetails?.result?.country_of_residence}}</td>
                                            </tr>

                                            <tr>

                                                <td *ngIf="userDetails?.result?.counties_id !=0"><b>County</b></td>
                                                <td *ngIf="userDetails?.result?.counties_id !=0" width="2%"
                                                    style="text-align: center;">:</td>
                                                <td *ngIf="userDetails?.result?.counties_id !=0">
                                                    {{userDetails?.result?.counties_name}}</td>


                                                <td><b>Postal Address</b></td>
                                                <td width="2%" style="text-align: center;">:</td>
                                                <td>{{userDetails?.result?.postal_address}} </td>


                                            </tr>
                                            <tr>

                                                <td *ngIf="userDetails?.result?.counties_id !=0"><b>Sub County</b></td>
                                                <td *ngIf="userDetails?.result?.counties_id !=0" width="2%"
                                                    style="text-align: center;">:</td>
                                                <td *ngIf="userDetails?.result?.counties_id !=0">
                                                    {{userDetails?.result?.sub_counties_name}}</td>


                                                <td *ngIf="userDetails?.result?.counties_id !=0"><b>Constituency</b>
                                                </td>
                                                <td *ngIf="userDetails?.result?.counties_id !=0" width="2%"
                                                    style="text-align: center;">:</td>
                                                <td *ngIf="userDetails?.result?.counties_id !=0">
                                                    {{userDetails?.result?.con_counties_name}}</td>

                                            </tr>
                                            <tr>
                                                <td><b>Physical Address</b></td>
                                                <td width="2%" style="text-align: center;">:</td>
                                                <td>{{userDetails?.result?.physical_address}}</td>
                                                <td><b>Postal Code</b></td>
                                                <td width="2%" style="text-align: center;">:</td>
                                                <td>{{userDetails?.result?.postal_code}}</td>



                                            </tr>

                                            <tr>
                                                <td><b>Town/City</b></td>
                                                <td width="2%" style="text-align: center;">:</td>
                                                <td>{{userDetails?.result?.nearest_town}}</td>
                                                <td width="24%"><b>Current Email</b></td>
                                                <td width="2%" style="text-align: center;">:</td>
                                                <td width="24%">{{userDetails?.result?.username}}</td>


                                            </tr>

                                            <tr>
                                                <td><b>Mobile Number 1</b></td>
                                                <td width="2%" style="text-align: center;">:</td>
                                                <td>{{userDetails?.result?.mobileno_code_name}}
                                                    {{userDetails?.result?.mobileno}}</td>
                                                <td><b>Mobile Number 2</b></td>
                                                <td width="2%" style="text-align: center;">:</td>
                                                <td>{{userDetails?.result?.mobileno2_code_name}}
                                                    {{userDetails?.result?.mobileno2}}</td>

                                            </tr>
                                            <tr>
                                                <td><b>English</b></td>
                                                <td width="2%" style="text-align: center;">:</td>
                                                <td>{{userDetails?.result?.english_proficiency_name}}</td>
                                                <td><b>Computing Skills</b></td>
                                                <td width="2%" style="text-align: center;">:</td>
                                                <td>{{userDetails?.result?.computing_skills_proficiency_name}}</td>

                                            </tr>
                                            <tr>
                                                <td colspan="6" style="border-bottom:1px dashed #333; font-size:13px;">
                                                    <h5><b>(B) Biographical Information</b></h5>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="24%"><b>Gender</b></td>
                                                <td width="2%" style="text-align: center;">:</td>
                                                <td width="24%">{{userDetails?.result?.gender_name}}</td>
                                                <td width="24%"><b>Date of Birth</b></td>
                                                <td width="2%" style="text-align: center;">:</td>
                                                <td width="24%">{{userDetails?.result?.dob}}</td>
                                            </tr>
                                            <tr>
                                                <td width="24%"><b>Marital Status</b></td>
                                                <td width="2%" style="text-align: center;">:</td>
                                                <td width="24%">{{userDetails?.result?.maritalstatus_name}}</td>
                                                <td width="24%"><b>Birth Country</b></td>
                                                <td width="2%" style="text-align: center;">:</td>
                                                <td width="24%">{{userDetails?.result?.birth_country}}</td>
                                            </tr>
                                            <tr>
                                                <td width="24%"><b>Orphan</b></td>
                                                <td width="2%" style="text-align: center;">:</td>
                                                <td width="24%">{{userDetails?.result?.orphan
                                                    ==1?'YES':userDetails?.result?.orphan ==2?'NO':'Do Not Wish To
                                                    Disclose'}}</td>
                                            </tr>
                                            <tr>
                                                <td width="24%"><b>Disability</b></td>
                                                <td width="2%" style="text-align: center;">:</td>
                                                <td width="24%">{{userDetails?.result?.isdisability_name}}</td>
                                                <td width="24%" *ngIf="userDetails?.result?.isdisability_name =='YES'">
                                                    <b>Type Of Disability</b>
                                                </td>
                                                <td width="2%" *ngIf="userDetails?.result?.isdisability_name =='YES'"
                                                    style="text-align: center;">:</td>
                                                <td width="24%" *ngIf="userDetails?.result?.isdisability_name =='YES'">
                                                    {{userDetails?.result?.typeofdisability_name}}</td>
                                            </tr>
                                            <tr>
                                                <td width="24%" *ngIf="userDetails?.result?.isdisability_name =='YES'">
                                                    <b>Disability Description</b>
                                                </td>
                                                <td width="2%" *ngIf="userDetails?.result?.isdisability_name =='YES'"
                                                    style="text-align: center;">:</td>
                                                <td width="24%" *ngIf="userDetails?.result?.isdisability_name =='YES'">
                                                    {{userDetails?.result?.disability_description}}</td>
                                                <td><b>Nationality</b></td>
                                                <td width="2%" style="text-align: center;">:</td>
                                                <td>{{userDetails?.result?.nan_code}}</td>

                                            </tr>
                                            <tr>

                                                <td><b>Identification Document Type</b></td>
                                                <td width="2%" style="text-align: center;">:</td>
                                                <td>{{userDetails?.result?.id_passport_type_name}}</td>
                                                <td><b>{{userDetails?.result?.id_passport_type_name}}</b></td>
                                                <td width="2%" style="text-align: center;">:</td>
                                                <td colspan="4">{{userDetails?.result?.id_passport}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table width="100%" border="0" cellspacing="0" cellpadding="6"
                                        style="font-size:13px;">
                                        <tbody>
                                            <tr>
                                                <td colspan="6" style="border-bottom:1px dashed #333; font-size:13px;">
                                                    <h5><b>(C) program selection</b></h5>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><b>Programme</b></td>
                                                <td width="2%" style="text-align: center;">:</td>
                                                <td>{{userDetails?.result?.course_name}}</td>
                                                <td><b>Application Type</b></td>
                                                <td width="2%" style="text-align: center;">:</td>
                                                <td>{{userDetails?.get_cluster?.APPLICATION_TYPE_NAME}}</td>
                                                <!-- <td><b>Is your placement
                                                        to OUK by KUCCPS?</b></td>
                                                <td width="2%" style="text-align: center;">:</td>
                                                <td>{{userDetails?.get_cluster?.IS_KUCCPS == true ? 'Yes':'No'}}</td> -->
                                            </tr>

                                            <tr *ngIf="userDetails?.get_cluster?.APPLICATION_TYPE == 1">
                                                <td><b>Programme Cluster
                                                    </b></td>
                                            </tr>
                                            <tr *ngIf="userDetails?.get_cluster?.APPLICATION_TYPE == 1">
                                                <td colspan="6">
                                                    <table class="table-bdr" width="100%" border="1" cellspacing="0"
                                                        cellpadding="6">
                                                        <tbody>
                                                            <tr>
                                                                <th>&nbsp;</th>
                                                                <th>Subject Name(eg MAT)</th>
                                                                <th>Grade obtained</th>
                                                            </tr>
                                                            <tr>
                                                                <td>CLUSTER SUBJECT 1</td>
                                                                <td>{{userDetails?.get_cluster?.CLUSTER_SUBJECT1_NAME}}
                                                                </td>
                                                                <td>{{userDetails?.get_cluster?.CLUSTER_SUBJECT1_GRADE}}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>CLUSTER SUBJECT 2</td>
                                                                <td>{{userDetails?.get_cluster?.CLUSTER_SUBJECT2_NAME}}
                                                                </td>
                                                                <td>{{userDetails?.get_cluster?.CLUSTER_SUBJECT2_GRADE}}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>CLUSTER SUBJECT 3</td>
                                                                <td>{{userDetails?.get_cluster?.CLUSTER_SUBJECT3_NAME}}
                                                                </td>
                                                                <td>{{userDetails?.get_cluster?.CLUSTER_SUBJECT3_GRADE}}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>CLUSTER SUBJECT 4</td>
                                                                <td>{{userDetails?.get_cluster?.CLUSTER_SUBJECT4_NAME}}
                                                                </td>
                                                                <td>{{userDetails?.get_cluster?.CLUSTER_SUBJECT4_GRADE}}
                                                                </td>
                                                            </tr>


                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>

                                            <tr>
                                                <!-- <td><b>Upload Previous Supporting Certificates</b></td>
                                                <td width="2%" style="text-align: center;">:</td>
                                                <td></td> -->
                                                <td><b>Are you applying for Recognition of Prior Learning (RPL)?</b>
                                                </td>
                                                <td width="2%" style="text-align: center;">:</td>
                                                <td>{{userDetails?.result?.is_rpl == true ? 'Yes' : 'No'}}</td>
                                            </tr>

                                            <!-- <tr>
                                                <td *ngIf="userDetails?.get_cluster?.APPLICATION_TYPE == 1"><b>Basic
                                                        Education System</b></td>
                                                <td *ngIf="userDetails?.get_cluster?.APPLICATION_TYPE == 1" width="2%"
                                                    style="text-align: center;">:</td>
                                                <td *ngIf="userDetails?.get_cluster?.APPLICATION_TYPE == 1">
                                                    {{userDetails?.get_cluster?.BASIC_EDUCATION_SYSTEM_NAME ||
                                                    userDetails?.get_cluster?.BASIC_EDUCATION_SYSTEM_OTHER}}</td>
                                            </tr> -->

                                        </tbody>
                                    </table>
                                    <table width="100%" border="0" cellspacing="0" cellpadding="6"
                                        style="border-bottom:1px dashed #333; font-size:13px;">
                                        <tbody>
                                            <tr>
                                            <tr style="border-bottom:0px dashed #333; font-size:13px;">
                                                <td colspan="6">
                                                    <h5><b>(D) Previous Education Details :</b></h5>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <div *ngFor="let item of userDetails?.lst">
                                        <table width="100%" border="1" cellspacing="0" cellpadding="6">
                                            <tbody>
                                                <tr>
                                                    <td width="24%"><b>Institution Name</b></td>
                                                    <td width="2%" style="text-align: center;">:</td>
                                                    <td width="24%">{{item.INSTITUTION_NAME}}</td>
                                                    <td width="24%"><b>Country</b></td>
                                                    <td width="2%" style="text-align: center;">:</td>
                                                    <td width="24%">{{item.EDUCATION_COUNTRY}}</td>
                                                </tr>
                                                <tr>
                                                    <td width="24%"><b>Level of Study</b></td>
                                                    <td width="2%" style="text-align: center;">:</td>
                                                    <td width="24%" colspan="4">{{item.LEVEL_OF_STUDY}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="24%"><b>Start Year</b></td>
                                                    <td width="2%" style="text-align: center;">:</td>
                                                    <td width="24%">{{item.FROM_PERIOD | date:'yyyy'}}</td>
                                                    <td width="24%"><b>End Year</b></td>
                                                    <td width="2%" style="text-align: center;">:</td>
                                                    <td width="24%">{{item.TO_PERIOD | date:'yyyy'}}</td>

                                                </tr>
                                                <tr>
                                                    <td width="24%"><b>Examination Number e.g. KCSE index number</b>
                                                    </td>
                                                    <td width="2%" style="text-align: center;">:</td>
                                                    <td width="24%">{{item.EXAM_REG_NO}}</td>
                                                    <td width="24%"><b>Qualifications Attained</b></td>
                                                    <td width="2%" style="text-align: center;">:</td>
                                                    <td width="24%">{{item.QUALIFICATION}}</td>

                                                </tr>
                                                <tr>
                                                    <td width="24%"><b>Mean Grade</b>
                                                    </td>
                                                    <td width="2%" style="text-align: center;">:</td>
                                                    <td width="24%">{{item.GRADE}}</td>


                                                </tr>
                                                <!-- <tr>
                                            <td><b>Address</b></td>
                                            <td width="2%" style="text-align: center;">:</td>
                                            <td > {{item.ADDRESS}}</td>
                                            <td><b>Additional Information</b></td>
                                            <td width="2%" style="text-align: center;">:</td>
                                            <td>{{item.ADDITIONAL_INFORMATION}}</td>
                                        </tr> -->

                                            </tbody>
                                        </table>
                                    </div>

                                    <table width="100%" border="0" cellspacing="0" cellpadding="6">
                                        <tbody>
                                            <tr style="border-bottom:1px dashed #333; font-size:13px;">
                                                <td colspan="6">
                                                    <h5><b>(E) Working Experience</b></h5>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div *ngFor="let item of userDetails?.lstt">
                                        <table width="100%" border="1" cellspacing="0" cellpadding="6">
                                            <tbody>

                                                <tr>
                                                    <td width="24%"><b>Company/Employer</b></td>
                                                    <td width="2%" style="text-align: center;">:</td>
                                                    <td width="24%">{{item.WORKEXP_DETAIL_EMPLOYER}}</td>
                                                    <td width="24%"><b>Designation</b></td>
                                                    <td width="2%" style="text-align: center;">:</td>
                                                    <td width="24%">{{item.WORKEXP_DETAIL_DESIGNATION}}</td>

                                                </tr>
                                                <tr>
                                                    <td width="24%"><b>Start Date</b></td>
                                                    <td width="2%" style="text-align: center;">:</td>
                                                    <td width="24%">{{item.WORKEXP_DETAIL_FROM_YEAR |
                                                        date:'yyyy-MM-dd'}}</td>
                                                    <td width="24%"><b>End Date</b></td>
                                                    <td width="2%" style="text-align: center;">:</td>
                                                    <td width="24%">{{item.WORKEXP_DETAIL_TO_YEAR | date:'yyyy-MM-dd'}}
                                                    </td>

                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>




                                    <table width="100%" border="0" cellspacing="0" cellpadding="6">
                                        <tbody>

                                            <tr style="border-bottom:1px dashed #333; font-size:13px;">
                                                <td colspan="6">
                                                    <h5><b>(F) Recommendations :</b></h5>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div *ngFor="let item of userDetails?.lst_recommandations">
                                        <table width="100%" border="1" cellspacing="0" cellpadding="6">
                                            <tbody>
                                                <tr>
                                                    <td width="24%"><b>Full Name</b></td>
                                                    <td width="2%" style="text-align: center;">:</td>
                                                    <td width="24%">{{item.NAME}}</td>
                                                    <td width="24%"><b>Organization</b></td>
                                                    <td width="2%" style="text-align: center;">:</td>
                                                    <td width="24%">{{item.ORGANIZATION}}</td>

                                                </tr>
                                                <tr>
                                                    <td width="24%"><b>Country</b></td>
                                                    <td width="2%" style="text-align: center;">:</td>
                                                    <td width="24%">{{item.COUNTRY_NAME}}
                                                    </td>
                                                    <td width="24%"><b>Mobile Number</b></td>
                                                    <td width="2%" style="text-align: center;">:</td>
                                                    <td width="24%">{{item.COUNTRY_CONNECTING_CODE}}
                                                        {{item.MOBILE_NUMBER}}
                                                    </td>

                                                    <!-- <td width="24%"><b>Nationality</b></td>
                                        <td width="2%" style="text-align: center;">:</td>
                                        <td width="24%"> {{userDetails?.result?.sponsors_email}}</td> -->
                                                </tr>
                                                <tr>
                                                    <td width="24%"><b>City/Town</b></td>
                                                    <td width="2%" style="text-align: center;">:</td>
                                                    <td width="24%">{{item.CITY_TOWN}}</td>
                                                    <td width="24%"><b>Nationality</b></td>
                                                    <td width="2%" style="text-align: center;">:</td>
                                                    <td width="24%"> {{item.RECOMMENDATION_NATIONALITY}}</td>

                                                </tr>
                                                <tr>
                                                    <td width="24%"><b>Email Address</b></td>
                                                    <td width="2%" style="text-align: center;">:</td>
                                                    <td width="24%" colspan="4">{{item.EMAIL_ADDRESS}}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <table width="100%" border="0" cellspacing="0" cellpadding="6"
                                        style="font-size:13px;">
                                        <tbody>
                                            <tr>
                                                <td colspan="6" style="border-bottom:1px dashed #333; font-size:13px;">
                                                    <h5><b>(G) Next of Kin/Emergency Contact :</b></h5>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><b>Full Name</b></td>
                                                <td width="2%" style="text-align: center;">:</td>
                                                <td>{{userDetails?.result?.name_of_emergency}}</td>
                                                <td><b>Relationship</b></td>
                                                <td width="2%" style="text-align: center;">:</td>
                                                <td>{{userDetails?.result?.relationshipname_of_emergency_name}}</td>
                                            </tr>
                                            <tr>
                                                <td><b>Telephone</b></td>
                                                <td width="2%" style="text-align: center;">:</td>
                                                <td>{{userDetails?.result?.emergency_mobileno_code_name}}
                                                    {{userDetails?.result?.mobile_no_emergency}}</td>
                                                <td><b>Email Address</b></td>
                                                <td width="2%" style="text-align: center;">:</td>
                                                <td>{{userDetails?.result?.emailid_of_emergency}}</td>
                                            </tr>
                                            <tr>
                                                <td><b>Postal Address</b></td>
                                                <td width="2%" style="text-align: center;">:</td>
                                                <td>{{userDetails?.result?.address_of_emergency}}</td>
                                                <td><b>Postal Code</b></td>
                                                <td width="2%" style="text-align: center;">:</td>
                                                <td>{{userDetails?.result?.postalcode_of_emergency}}</td>

                                            </tr>
                                            <tr>
                                                <td><b>City/Town</b></td>
                                                <td width="2%" style="text-align: center;">:</td>
                                                <td>{{userDetails?.result?.
                                                    nearest_town}}</td>
                                                <td><b>Country</b></td>
                                                <td width="2%" style="text-align: center;">:</td>
                                                <td>{{userDetails?.result?.emergency_country}}</td>

                                            </tr>
                                            <tr>

                                                <td *ngIf="userDetails?.result?.counties_id_of_emergency !=0">
                                                    <b>County</b>
                                                </td>
                                                <td *ngIf="userDetails?.result?.counties_id_of_emergency !=0" width="2%"
                                                    style="text-align: center;">:</td>
                                                <td *ngIf="userDetails?.result?.counties_id_of_emergency !=0">
                                                    {{userDetails?.result?.counties_name_of_emergency}}</td>

                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="footer float-end pt-2">
                        <button id="Closemodal" class="btn btn-sm btn-danger"
                            data-bs-dismiss="modal">Close</button>&nbsp;&nbsp;
                        <button class="btn btn-save btn-sm" (click)="downloadForm('application')">
                            Download</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal" tabindex="-1" data-backdrop="static" data-keyboard="false" id="commentsModel">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Comments</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body text-center">
                <h6>{{userComments}}</h6>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-danger btn-sm" data-bs-dismiss="modal">Close</button>
                <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
            </div>
        </div>
    </div>
</div>



<div class="modal" tabindex="-1" id="editPopup">
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title"></h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">

                <form action="" [formGroup]="signUpForm">
                    <!-- *ngIf="(aprvId == 0|| aprvId==2 ) && (payId == 0)" -->
                    <div class="row">
                        <div class="col-xl-2">
                            <div class="d-flex flex-column" style="max-width: 200px;">
                                <ul class="nav nav-tabs" id="myTab" role="tablist" style="cursor: pointer;">
                                    <li class="nav-item" role="presentation"
                                        [ngClass]="{'disabled': personalDetailsSaved|| disablePersonalDetails, 'active': personalDetailsActive}">
                                        <a data-bs-target="#home-tab" class="nav-link active" id="home"
                                            data-bs-toggle="tab" role="tab" aria-controls="home-tab"
                                            aria-selected="true">Personal Details</a>
                                    </li>

                                    <li class="nav-item" role="presentation"
                                        [ngClass]="{'disabled': !personalDetailsSaved}">
                                        <a data-bs-target="#Biological-info-tab" id="Biological-info" class="nav-link"
                                            data-bs-toggle="tab" role="tab" aria-controls="Biological-info-tab"
                                            aria-selected="false">Biographical
                                            Information</a>
                                    </li>
                                    <li class="nav-item" role="presentation"
                                        [ngClass]="{'disabled': !biographicalInfoSaved}">
                                        <a data-bs-target="#application-info-tab" id="application-info" class="nav-link"
                                            data-bs-toggle="tab" role="tab" aria-controls="application-info-tab"
                                            aria-selected="false">Program Selection</a>
                                    </li>
                                    <li class="nav-item" role="presentation"
                                        [ngClass]="{'disabled': !programSelection}">
                                        <a data-bs-target="#Education-Bg-tab" id="Education-Bg" class="nav-link"
                                            data-bs-toggle="tab" role="tab" aria-controls="Education-Bg-tab"
                                            aria-selected="false">Education
                                            Background</a>
                                    </li>


                                    <li class="nav-item" role="presentation"
                                        [ngClass]="{'disabled': !educationDetails}">
                                        <a data-bs-target="#Working-Exp-tab" id="Working-Exp" class="nav-link"
                                            data-bs-toggle="tab" role="tab" aria-controls="Working-Exp-tab"
                                            aria-selected="false">Work
                                            Experience</a>
                                    </li>
                                    <li class="nav-item" role="presentation" [ngClass]="{'disabled': !workExp}">
                                        <a data-bs-target="#Recommendations-tab" id="Recommendations" class="nav-link"
                                            data-bs-toggle="tab" role="tab" aria-controls="Recommendations-tab"
                                            aria-selected="false">Recommendations</a>
                                    </li>
                                    <li class="nav-item" role="presentation" [ngClass]="{'disabled': !recomondations}">
                                        <a data-bs-target="#Next-of-Kin-tab" id="Next-of-Kin" class="nav-link"
                                            data-bs-toggle="tab" role="tab" aria-controls="Next-of-Kin-tab"
                                            aria-selected="false">Next
                                            of
                                            Kin/ Emergency Contact</a>
                                    </li>
                                    <li class="nav-item" role="presentation" [ngClass]="{'disabled': !nextOfKin}">
                                        <a data-bs-target="#Review-tab" id="Review" class="nav-link"
                                            data-bs-toggle="tab" role="tab" aria-controls="Review-tab"
                                            aria-selected="false">Review and Submit</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-xl-10">
                            <div class="tab-content bg-white" id="myTabContent">
                                <div class="tab-pane fade show active" role="tabpanel" aria-labelledby="home"
                                    id="home-tab">
                                    <div class="row">
                                        <div class="col-md-12 m-auto">
                                            <div class="card border-0 card-shadow h-100">
                                                <div class="card-body">
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <div class="card_title mb-3 px-2 py-1 text-white">
                                                                <h6 class="mb-0">Name</h6>
                                                            </div>
                                                            <div class="row mb-2">
                                                                <div class="col-md-4">First name <span
                                                                        class="text-danger"
                                                                        style="font-size:20px;">*</span>
                                                                </div>
                                                                <div class="col-md-6"><input type="text"
                                                                        class="form-control form-control-sm"
                                                                        formControlName="FIRSTNAME"
                                                                        onkeypress="return /[a-a-zA-Z'-'\s]/i.test(event.key)">
                                                                </div>
                                                            </div>
                                                            <div class="row mb-2">
                                                                <div class="col-md-4">Middle name

                                                                </div>
                                                                <div class="col-md-6"><input type="text"
                                                                        formControlName="MIDDLENAME"
                                                                        onkeypress="return /[a-a-zA-Z'-'\s]/i.test(event.key)"
                                                                        class="form-control form-control-sm">
                                                                </div>
                                                            </div>
                                                            <div class="row mb-2">
                                                                <div class="col-md-4">Last name <span
                                                                        class="text-danger"
                                                                        style="font-size:20px;">*</span>
                                                                </div>
                                                                <div class="col-md-6"><input type="text"
                                                                        formControlName="LASTNAME"
                                                                        onkeypress="return /[a-a-zA-Z'-'\s]/i.test(event.key)"
                                                                        class="form-control form-control-sm">
                                                                </div>
                                                            </div>
                                                            <div class="card_title mt-2 mb-3 px-2 py-1 text-white">
                                                                <h6 class="mb-0">Address</h6>
                                                            </div>
                                                            <div class="row mb-2">
                                                                <div class="col-md-4">Country of
                                                                    Residence
                                                                    <span class="text-danger"
                                                                        style="font-size:20px;">*</span>
                                                                </div>
                                                                <div class="col-md-6"> <select
                                                                        (change)="changeCountry($any($event.target)?.value,'personalDetailsCountry')"
                                                                        class="form-select form-select-sm"
                                                                        formControlName="COUNTRY_ID">
                                                                        <option value="0">Select
                                                                        </option>
                                                                        <option *ngFor='let item of country'
                                                                            [value]='item.COUNTRY_ID'>
                                                                            {{item.COUNTRY_NAME}}
                                                                        </option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div class="row mb-2" *ngIf="countyDrpdown">
                                                                <div class="col-md-4">County
                                                                    <span class="text-danger"
                                                                        style="font-size:20px;">*</span>
                                                                </div>
                                                                <div class="col-md-6"> <select
                                                                        (change)="subCounties($any($event.target).value)"
                                                                        class="form-select form-select-sm"
                                                                        formControlName="COUNTIES_ID">
                                                                        <option value="">Select
                                                                        </option>
                                                                        <option *ngFor='let item of County'
                                                                            [value]='item.COUNTIES_CODE'>
                                                                            {{item.COUNTIES_NAME}}
                                                                        </option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div class="row mb-2" *ngIf="countyDrpdown">
                                                                <div class="col-md-4">Sub County
                                                                    <span class="text-danger"
                                                                        style="font-size:20px;">*</span>
                                                                </div>
                                                                <div class="col-md-6"> <select
                                                                        class="form-select form-select-sm"
                                                                        formControlName="SUBCOUNTIES_ID">
                                                                        <option value="">Select
                                                                        </option>
                                                                        <option *ngFor='let item of subCounty'
                                                                            [value]='item.sub_counties_id'>
                                                                            {{item.sub_counties_name}}
                                                                        </option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div class="row mb-2" *ngIf="countyDrpdown">
                                                                <div class="col-md-4">Constituency
                                                                    <span class="text-danger"
                                                                        style="font-size:20px;">*</span>
                                                                </div>
                                                                <div class="col-md-6"> <select
                                                                        class="form-select form-select-sm"
                                                                        formControlName="COUNTIES_CONSTITUENCIES_ID">
                                                                        <option value="">Select
                                                                        </option>
                                                                        <option *ngFor='let item of Constituency'
                                                                            [value]='item.con_counties_id'>
                                                                            {{item.con_counties_name}}
                                                                        </option>
                                                                    </select>
                                                                </div>
                                                            </div>



                                                            <div class="row mb-2">
                                                                <div class="col-md-4">Postal Address
                                                                </div>
                                                                <div class="col-md-6"><input type="text"
                                                                        class="form-control form-control-sm"
                                                                        placeholder="P.O.Box"
                                                                        formControlName="POSTAL_ADDRESS">
                                                                </div>
                                                            </div>
                                                            <div class="row mb-2">
                                                                <div class="col-md-4">Physical Address
                                                                </div>
                                                                <div class="col-md-6"><input type="text"
                                                                        class="form-control form-control-sm"
                                                                        formControlName="PHYSICAL_ADDRESS">
                                                                </div>
                                                            </div>
                                                            <div class="row mb-2">
                                                                <div class="col-md-4">Postal Code</div>
                                                                <div class="col-md-6"><input type="text" maxlength="10"
                                                                        class="form-control form-control-sm"
                                                                        formControlName="POSTAL_CODE">
                                                                </div>
                                                            </div>
                                                            <div class="row mb-2">
                                                                <div class="col-md-4">City/Town</div>
                                                                <div class="col-md-6"><input type="text"
                                                                        class="form-control form-control-sm"
                                                                        formControlName="NEAREST_TOWN"
                                                                        onkeypress="return /[a-a-zA-Z'-'\s]/i.test(event.key)">
                                                                </div>
                                                            </div>
                                                            <div class="card_title mb-3 px-2 py-1 text-white">
                                                                <h6 class="mb-0">Email</h6>
                                                            </div>
                                                            <div class="row mb-2">
                                                                <div class="col-md-4">Current Email
                                                                    <span class="text-danger"
                                                                        style="font-size:20px;">*</span>
                                                                </div>
                                                                <div class="col-md-6"><input type="text"
                                                                        class="form-control form-control-sm" disabled
                                                                        [value]="userDetails?.result?.username">
                                                                </div>
                                                            </div>
                                                            <div class="card_title mb-3 px-2 py-1 text-white">
                                                                <h6 class="mb-0">Telephone Numbers</h6>
                                                            </div>
                                                            <div class="row mb-2">
                                                                <div class="col-md-4">Mobile Number 1
                                                                    <span class="text-danger"
                                                                        style="font-size:20px;">*</span>
                                                                </div>
                                                                <div class="col-md-3">

                                                                    <select class="form-select form-select-sm"
                                                                        formControlName="MOBILENO_CODE_ID"
                                                                        [(ngModel)]="countryCode">
                                                                        <option *ngFor="let item of country"
                                                                            [value]="item.COUNTRY_ID">
                                                                            {{item.COUNTRY_CONNECTING_CODE}}
                                                                        </option>
                                                                    </select>
                                                                </div>&nbsp;

                                                                <div class="col-md-4">
                                                                    <input type="text"
                                                                        class="form-control form-control-sm"
                                                                        placeholder="Ex : 712345678, do not start with 0"
                                                                        formControlName="MOBILENO"
                                                                        (blur)="mobileValidation($event,1)"
                                                                        (keypress)="onKeyPress($event)" maxlength="12">
                                                                </div>
                                                            </div>
                                                            <div class="row mb-2">
                                                                <div class="col-md-4">Mobile Number 2
                                                                </div>
                                                                <div class="col-md-3">
                                                                    <select class="form-select form-select-sm"
                                                                        formControlName="MOBILENO2_CODE_ID"
                                                                        [(ngModel)]="countryCodeOne">
                                                                        <option *ngFor="let item of country"
                                                                            [value]="item.COUNTRY_ID">
                                                                            {{item.COUNTRY_CONNECTING_CODE}}
                                                                        </option>
                                                                    </select>
                                                                </div>&nbsp;
                                                                <div class="col-md-4">
                                                                    <input type="text"
                                                                        class="form-control form-control-sm"
                                                                        placeholder="Ex : 712345678, do not start with 0"
                                                                        formControlName="MOBILENO2"
                                                                        (blur)="mobileValidation($event,2)"
                                                                        (keypress)="onKeyPress($event)" maxlength="12">
                                                                </div>
                                                            </div>
                                                            <div class="card_title mb-3 px-2 py-1 text-white">
                                                                <h6 class="mb-0">Proficiency</h6>
                                                            </div>
                                                            <div class="row mb-2">
                                                                <div class="col-md-4">English <span
                                                                        class="text-danger">*</span>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <div class="dropdown">
                                                                        <select class="form-control form-control-sm"
                                                                            formControlName="ENGLISH_PROFICIENCY">
                                                                            <option value="0">Select
                                                                                Level
                                                                            </option>
                                                                            <option value="1">Beginner
                                                                            </option>
                                                                            <option value="2">
                                                                                Intermediate
                                                                            </option>
                                                                            <option value="3">Advanced
                                                                            </option>
                                                                            <option value="4">Mastery
                                                                            </option>
                                                                        </select>

                                                                    </div>
                                                                    <span class="text-blue small">(your
                                                                        language proficiency
                                                                        level in both speaking and
                                                                        writing)</span>
                                                                </div>

                                                            </div>
                                                            <div class="row mb-2">
                                                                <div class="col-md-4">Computing
                                                                    Skills<span class="text-danger">*</span>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <div class="dropdown">
                                                                        <select class="form-control form-control-sm"
                                                                            formControlName="COMPUTING_SKILLS_PROFICIENCY">
                                                                            <option value="0">Select
                                                                                Level
                                                                            </option>
                                                                            <option value="1">Beginner
                                                                            </option>
                                                                            <option value="2">
                                                                                Intermediate
                                                                            </option>
                                                                            <option value="3">Advanced
                                                                            </option>
                                                                            <option value="4">Mastery
                                                                            </option>
                                                                        </select>
                                                                    </div>
                                                                    <span class="text-blue small">(describe
                                                                        your computer
                                                                        handling skills)</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 text-end">
                                                            <div id="btnNext">
                                                                <a class="btn btn-sm admin-btn btn-sm px-4 rounded-0 btnNext"
                                                                    (click)="savePersonalDetails(signUpForm)">Save
                                                                    & Next</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="card-footer bg-white mt-3 d-none">
                                                    <div style="width: 35%" class="mb-2"><img
                                                            src="../../../../assets/img/OUK-Admissions-logo.png"
                                                            class="img-fluid" alt=""></div>
                                                    <p class="small">OUK is a public university that was
                                                        established and
                                                        Chartered in 2023. We aim
                                                        to provide
                                                        inclusive and flexible high quality higher
                                                        education
                                                        opportunities
                                                        suited for the global
                                                        citizens in the
                                                        21st century. At OUK admissions, we recruit and
                                                        enroll diverse class
                                                        of students.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- -------------------------Biological Information Tab-------------------------->
                                <div class="tab-pane fade" id="Biological-info-tab" role="tabpanel"
                                    aria-labelledby="Biological-info">
                                    <div class="card border-0 card-shadow h-100">
                                        <div class="card-body assesment pt-0 style-1 ">
                                            <div class="row">
                                                <div class="col-md-12 m-auto">
                                                    <div class="card border-0 card-shadow h-100">
                                                        <div class="card-body ">
                                                            <div class="card_title mb-3 px-2 py-1 text-white">
                                                                <h6 class="mb-0">Biographical
                                                                    Information
                                                                </h6>
                                                            </div>
                                                            <div class="row mb-2">
                                                                <div class="col-md-4">Gender <span class="text-danger"
                                                                        style="font-size:20px;">*</span>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <select class="form-select form-select-sm"
                                                                        formControlName="GENDER">
                                                                        <option value="0">Select
                                                                        </option>
                                                                        <option *ngFor="let item of gender"
                                                                            [value]='item.DICTIONARYID'>
                                                                            {{item.DICTIONARYNAME}}
                                                                        </option>
                                                                    </select>

                                                                </div>
                                                            </div>
                                                            <div class="row mb-2">
                                                                <div class="col-md-4">Date of Birth
                                                                    <span class="text-danger"
                                                                        style="font-size:20px;">*</span>
                                                                </div>
                                                                <div class="col-md-3">
                                                                    <div class="form-group">
                                                                        <label>Year</label>
                                                                        <select class="form-select form-select-sm"
                                                                            [(ngModel)]="selectedYear"
                                                                            [ngModelOptions]="{standalone:true}"
                                                                            (ngModelChange)="generateDropdowns()"
                                                                            (change)="clearMonthDay()">
                                                                            <option *ngFor="let year of years"
                                                                                [value]="year">{{ year
                                                                                }}</option>
                                                                        </select>
                                                                    </div>
                                                                </div>

                                                                <div class="col-md-2">
                                                                    <div class="form-group">
                                                                        <label>Month</label>
                                                                        <select class="form-select form-select-sm"
                                                                            [(ngModel)]="selectedMonth"
                                                                            [ngModelOptions]="{standalone:true}"
                                                                            (ngModelChange)="generateDropdowns()">
                                                                            <option *ngFor="let month of months"
                                                                                [value]="month">{{ month
                                                                                }}</option>
                                                                        </select>
                                                                    </div>
                                                                </div>

                                                                <div class="col-md-3">
                                                                    <div class="form-group">
                                                                        <label>Day</label>
                                                                        <select class="form-select form-select-sm"
                                                                            [(ngModel)]="selectedDay"
                                                                            [ngModelOptions]="{standalone:true}"
                                                                            (change)="validateAge()">
                                                                            <option *ngFor="let day of days"
                                                                                [value]="day">{{ day }}
                                                                            </option>
                                                                        </select>
                                                                    </div>
                                                                </div>


                                                            </div>
                                                            <div class="row mb-2">
                                                                <div class="col-md-4">Marital Status
                                                                </div>
                                                                <div class="col-md-6"> <select
                                                                        formControlName="MARTIALSTATUS"
                                                                        class="form-select form-select-sm">
                                                                        <option value="0">Select
                                                                        </option>
                                                                        <option *ngFor='let item of MartialStatus'
                                                                            [value]='item.DICTIONARYID'>
                                                                            {{item.DICTIONARYNAME}}
                                                                        </option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div class="row mb-2">
                                                                <div class="col-md-4">Birth Country
                                                                    <span class="text-danger"
                                                                        style="font-size:20px;">*</span>
                                                                </div>
                                                                <div class="col-md-6"> <select
                                                                        [(ngModel)]="birthCountry"
                                                                        class="form-select form-select-sm"
                                                                        formControlName="BIRTH_COUNTRY_ID">
                                                                        <option value="0">Select
                                                                        </option>
                                                                        <option *ngFor='let item of country'
                                                                            [value]='item.COUNTRY_ID'>
                                                                            {{item.COUNTRY_NAME}}
                                                                        </option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div class="row mb-2">
                                                                <div class="col-md-4">Orphan
                                                                    <span class="text-danger"
                                                                        style="font-size:20px;">*</span>
                                                                </div>
                                                                <div class="col-md-6"> <select
                                                                        class="form-select form-select-sm"
                                                                        formControlName="orphan">
                                                                        <option value="0">Select
                                                                        </option>
                                                                        <option value="1">Yes</option>
                                                                        <option value="2">No</option>
                                                                        <option value="3">Do not wish to disclose
                                                                        </option>

                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div class="row mb-2">
                                                                <div class="col-md-4">Disability
                                                                    <span class="text-danger"
                                                                        style="font-size:20px;">*</span>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <input type="radio" class="form-check-input"
                                                                        (change)="radioChanged(true)" name="yes"
                                                                        id="isDisabilityYes"
                                                                        [checked]="isDisabilityStatus === true">
                                                                    <label class="form-check-label"
                                                                        for="isDisabilityYes">Yes</label>
                                                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                                                    <input type="radio" class="form-check-input"
                                                                        (change)="radioChanged(false)" name="no"
                                                                        id="isDisabilityNo"
                                                                        [checked]="isDisabilityStatus === false">
                                                                    <label class="form-check-label"
                                                                        for="isDisabilityNo">No</label> &nbsp;
                                                                </div>


                                                            </div>
                                                            <div class="row mb-2" *ngIf="showDropdown">
                                                                <div class="col-md-4">Type of disability
                                                                    <span class="text-danger"
                                                                        style="font-size:20px;">*</span>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <select class="form-select form-select-sm"
                                                                        (change)="dropdownChanged($event.target.value)"
                                                                        formControlName="type_of_disability">
                                                                        <option value="">Select Disablity</option>
                                                                        <option value="1">Visual</option>
                                                                        <option value="2">Physical</option>
                                                                        <option value="3">Impaired hearing</option>
                                                                        <option value="4">Autism</option>
                                                                        <option value="5">Other</option>
                                                                    </select>

                                                                </div>
                                                            </div>
                                                            <div class="row mb-2" *ngIf="showReasonTextbox">
                                                                <div class="col-md-4">
                                                                    <span class="text-danger"
                                                                        style="font-size:20px;"></span>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <input type="text"
                                                                        class="form-control form-control-sm"
                                                                        formControlName="disability_description"
                                                                        placeholder="Please provide a reason">


                                                                </div>
                                                            </div>
                                                            <div class="row mb-2">
                                                                <div class="col-md-4">Passport Size
                                                                    Photograph <span class="text-danger">*</span>
                                                                    <small>(only
                                                                        allow .jpeg or .png)</small>
                                                                </div>

                                                                <div class="col-md-6">
                                                                    <div class="input-group">
                                                                        <input type="file" formControlName="UPLOAD_4"
                                                                            (change)="changeFile($event,4)"
                                                                            class="form-control"
                                                                            placeholder="Recipient's username"
                                                                            aria-label="Recipient's username"
                                                                            aria-describedby="basic-addon2"
                                                                            #fileInputRefPassport>
                                                                        <span class="input-group-text"
                                                                            style="cursor: pointer;" id="basic-addon2"
                                                                            (click)="removeFile('3')">Remove</span>
                                                                    </div>
                                                                    <p *ngIf="upload_2 !=''||upload_4 !=''">
                                                                        {{upload_2||upload_4}}</p>
                                                                    <div *ngIf="isProgress" class="progress my-3">
                                                                        <div class="progress-bar progress-bar-info progress-bar-striped"
                                                                            role="progressbar"
                                                                            attr.aria-valuenow="{{ progress }}"
                                                                            aria-valuemin="0" aria-valuemax="100"
                                                                            [ngStyle]="{ width: progress + '%' }">
                                                                            {{ progress }}%
                                                                        </div>
                                                                    </div>
                                                                    <!-- <div class="text-blue"><small>(If
                                                                   you
                                                                   applying for RPL
                                                                   your
                                                                   portfolio documents should
                                                                   be
                                                                   scanned and
                                                                   uploaded
                                                                   as a single pdf
                                                                   file.)</small>
                                                           </div> -->
                                                                </div>







                                                                <!-- <div class="col-md-6">
                                                           <div class="input-group mb-3">
                                                               <input type="file"
                                                                   formControlName="UPLOAD_4"
                                                                   (change)="changeFile($event,4)"
                                                                   class="form-control form-control-sm"><br>
                                                             
                                                           </div>
                                                       </div> -->
                                                                <div class="card_title mt-2 mb-3 px-2 py-1 text-white">
                                                                    <h6 class="mb-0">Citizenship
                                                                        Information
                                                                    </h6>
                                                                </div>
                                                                <div class="row mb-2">
                                                                    <div class="col-md-4">Nationality
                                                                        <span class="text-danger"
                                                                            style="font-size:20px;">*</span>
                                                                    </div>
                                                                    <div class="col-md-6">
                                                                        <select [(ngModel)]="nationalityKenya"
                                                                            class="form-select form-control-sm"
                                                                            formControlName="NATIONALITY">
                                                                            <option value="0">Select
                                                                            </option>
                                                                            <option *ngFor="let item of nationalityList"
                                                                                [value]="item.NAN_ID">
                                                                                {{item.NAN_CODE}}
                                                                            </option>
                                                                        </select>


                                                                    </div>
                                                                </div>
                                                                <div class="row mb-2">
                                                                    <div class="col-md-4">Identification
                                                                        Document Type
                                                                        <span class="text-danger"
                                                                            style="font-size:20px;">*</span>
                                                                    </div>
                                                                    <div class="col-md-6"> <select
                                                                            (change)="documentTypeId($event)"
                                                                            class="form-select form-select-sm"
                                                                            formControlName="ID_PASSPORT_TYPE">
                                                                            <option value="0">Select
                                                                            </option>
                                                                            <option
                                                                                *ngFor="let item of passportTypeList"
                                                                                [value]="item.id">
                                                                                {{item.name}}</option>
                                                                            <!-- <option value="1">National
                                                                       ID</option>
                                                                   <option value="2">Passport
                                                                   </option>
                                                                   <option value="3">Birth
                                                                       Certificate </option> -->

                                                                        </select>
                                                                    </div>
                                                                </div>

                                                                <div class="row mb-2">
                                                                    <div class="col-md-4">
                                                                        {{selectedValueText ||
                                                                        userDetails?.result?.id_passport_type_name}}
                                                                        Number
                                                                        <span class="text-danger"
                                                                            style="font-size:20px;">*</span>
                                                                    </div>
                                                                    <div class="col-md-6"> <input type="text"
                                                                            (keypress)="onlyAlphanumerics($event)"
                                                                            formControlName="ID_PASSPORT" maxlength="15"
                                                                            class="form-control form-control-sm">
                                                                    </div>
                                                                </div>
                                                                <div class="row mb-2">
                                                                    <div class="col-md-4">Identification Document Upload
                                                                        <small>(Only file format PDF allowed)<span
                                                                                class="text-danger"
                                                                                style="font-size:20px;">*</span></small>
                                                                    </div>

                                                                    <div class="col-md-6">
                                                                        <div class="input-group">
                                                                            <input type="file"
                                                                                formControlName="identity_document_upload"
                                                                                (change)="changeFile($event,6)"
                                                                                class="form-control"
                                                                                placeholder="Recipient's username"
                                                                                aria-label="Recipient's username"
                                                                                aria-describedby="basic-addon2"
                                                                                #fileInputRefupload2>
                                                                            <span class="input-group-text"
                                                                                style="cursor: pointer;"
                                                                                id="basic-addon2"
                                                                                (click)="removeFile('4')">Remove</span>
                                                                        </div>
                                                                        <p *ngIf="upload_6 !=''">
                                                                            {{upload_6}}</p>
                                                                        <div *ngIf="isProgressOne"
                                                                            class="progress my-3">
                                                                            <div class="progress-bar progress-bar-info progress-bar-striped"
                                                                                role="progressbar"
                                                                                attr.aria-valuenow="{{ progressOne }}"
                                                                                aria-valuemin="0" aria-valuemax="100"
                                                                                [ngStyle]="{ width: progressOne + '%' }">
                                                                                {{ progressOne }}%
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-12 mt-3">
                                                                    <div id="btnPrevious"><a
                                                                            class="float-start btn btn-sm admin-btn btn-sm px-xl-4 px-2 rounded-0 btnPrevious"
                                                                            (click)="previousTab()">Previous</a>
                                                                    </div>
                                                                    <div id="btnNext"><a
                                                                            class="float-end btn btn-sm admin-btn btn-sm px-xl-4 px-2 rounded-0 btnNext"
                                                                            (click)="saveBiographicalData(signUpForm)">Save
                                                                            & Next</a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="card-footer bg-white mt-3 d-none">
                                                                <div style="width: 35%" class="mb-2">
                                                                    <img src="../../../../assets/img/OUK-Admissions-logo.png"
                                                                        class="img-fluid" alt="">
                                                                </div>
                                                                <p class="small">OUK is a public
                                                                    university
                                                                    that was
                                                                    established and Chartered in 2023.
                                                                    We
                                                                    aim
                                                                    to provide
                                                                    inclusive and flexible high quality
                                                                    higher education
                                                                    opportunities suited for the global
                                                                    citizens in the
                                                                    21st century. At OUK admissions, we
                                                                    recruit and enroll
                                                                    diverse class of students.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-------------------------------------------------------------------------------------------------->
                                <div class="tab-pane fade" id="application-info-tab" role="tabpanel"
                                    aria-labelledby="application-info">
                                    <div class="card border-0 card-shadow h-100">
                                        <div class="card-body assesment pt-0 style-1 ">
                                            <div class="row">
                                                <div class="col-md-12 m-auto">
                                                    <div class="card border-0 card-shadow h-100">
                                                        <div class="card-body">
                                                            <div class="card_title mb-3 px-2 py-1 text-white">
                                                                <h6 class="mb-0">What would you like to
                                                                    study?</h6>
                                                            </div>

                                                            <!-- <div class="row mb-2"
                                                   *ngIf="!ispostgraduate">
                                                   <div class="col-md-4">Is your placement
                                                       to OUK by KUCCPS? <span
                                                           class="text-danger"
                                                           style="font-size:20px;">*</span>
                                                   </div>
                                                   <div class="col-md-6">
                                                       <select formControlName="IS_KUCCPS"
                                                           class="form-select form-select-sm">
                                                           <option value="true">YES
                                                           </option>
                                                           <option value="false">NO
                                                           </option>
                                                       </select>
                                                   </div>
                                               </div> -->
                                                            <div class="row mb-2">
                                                                <div class="col-md-4">Application Type
                                                                    <span class="text-danger"
                                                                        style="font-size:20px;">*</span>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <select [(ngModel)]="apptype"
                                                                        class="form-select form-select-sm"
                                                                        (change)="applicationType($event)"
                                                                        formControlName="APPLICATION_TYPE"
                                                                        aria-label="Default select example">
                                                                        <option value="0">Select
                                                                        </option>
                                                                        <option value="1">Undergraduate
                                                                        </option>
                                                                        <option value="2">Postgraduate
                                                                        </option>
                                                                    </select>


                                                                    <span class="text-blue"><small>(e.g.
                                                                            Undergraduate or
                                                                            Postgraduate)</small></span>
                                                                </div>
                                                            </div>
                                                            <div class="row mb-2">
                                                                <div class="col-md-4">Programme <span
                                                                        class="text-danger"
                                                                        style="font-size:20px;">*</span>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <select [(ngModel)]="courseId"
                                                                        (change)="loadClusterDetails($any($event.target).value)"
                                                                        [ngModelOptions]="{standalone:true}"
                                                                        class="form-control form-control-sm">
                                                                        <option value="">Select</option>
                                                                        <option *ngFor="let item of  courseList"
                                                                            [value]="item.COURSE_ID">
                                                                            {{item.COURSE_NAME}}
                                                                        </option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div *ngIf="clusters">
                                                                <!-- <div class="row mb-2">
                                                           <div class="col-md-4">Basic
                                                               Education
                                                               System

                                                           </div>
                                                           <div class="col-md-6">


                                                               <select
                                                                   [(ngModel)]="basicEduSystem"
                                                                   [ngModelSystem]="{standalone:true}"
                                                                   class="form-select form-select-sm"
                                                                   formControlName="BASIC_EDUCATION_SYSTEM"
                                                                   aria-label="Default select example">
                                                                   <option value="0">Select
                                                                   </option>
                                                                   <option value="1">KCSE
                                                                   </option>
                                                                   <option value="2">IGCSE
                                                                   </option>
                                                                   <option value="3">GCSE
                                                                   </option>
                                                                   <option value="4">IB
                                                                   </option>
                                                                   <option value="5">Others
                                                                   </option>
                                                               </select>
                                                               <div class="pt-2"
                                                                   *ngIf="basicEduSystem == '5'">
                                                                   <input type="text"
                                                                       class="form-control form-control-sm"
                                                                       formControlName="BASIC_EDUCATION_SYSTEM_OTHER"
                                                                       onkeypress="return /[a-a-zA-Z'-'\s]/i.test(event.key)">
                                                               </div>
                                                               <span class="text-blue"><small>(e.g.
                                                                       education system
                                                                       that
                                                                       describes your secondary
                                                                       school
                                                                       education
                                                                       journey)</small></span>
                                                           </div>
                                                       </div> -->
                                                                <!-- <div class="row mb-2">
                                                           <div class="col-md-4">Mean Grade

                                                           </div>
                                                           <div class="col-md-6">
                                                               <input type="text"
                                                                   [(ngModel)]="meanGrade"
                                                                   [ngModelOptions]="{standalone:true}"
                                                                   (blur)="validateGrade($event)"
                                                                   formControlName="MEAN_GRADE"
                                                                   maxlength="2">
                                                           </div>
                                                       </div> -->

                                                                <div class="row mb-2">
                                                                    <div class="col-md-4">Programme
                                                                        Cluster
                                                                    </div>
                                                                    <div class="col-md-6">
                                                                        <span class="text-blue"><small>(for
                                                                                undergraduates
                                                                                provide
                                                                                relevant cluster
                                                                                subjects
                                                                                and
                                                                                grades based on
                                                                                KUCCPS
                                                                                guidelines)</small></span>
                                                                    </div>
                                                                </div>
                                                                <div class="row">
                                                                    <div class="col-12">
                                                                        <table class="table table-bordered table-bdr">
                                                                            <tr>
                                                                                <th></th>
                                                                                <th>Subject Name(eg MAT)
                                                                                </th>
                                                                                <th>Grade obtained</th>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>CLUSTER SUBJECT 1
                                                                                </td>
                                                                                <!-- <td><input type="text" onkeypress="return /[a-a-zA-Z'-'\s]/i.test(event.key)"
                                                                           formControlName="CLUSTER_SUBJECT1_NAME"
                                                                           class="form-control form-control-sm">
                                                                   </td>
                                                                   <td><input type="text"
                                                                           formControlName="CLUSTER_SUBJECT1_GRADE"
                                                                           class="form-control form-control-sm">
                                                                   </td> -->
                                                                                <td>
                                                                                    <select
                                                                                        class="form-select form-control-sm"
                                                                                        formControlName="CLUSTER_SUBJECT1_NAME">
                                                                                        <option value=''>
                                                                                            Select
                                                                                        </option>
                                                                                        <option
                                                                                            *ngFor="let item of clusterDropdowns[0]"
                                                                                            [value]="item.CLUSTERDTL_ID">
                                                                                            {{item.CLUSTER_SUBJECT_NAME}}
                                                                                        </option>
                                                                                    </select>
                                                                                </td>
                                                                                <td>
                                                                                    <select
                                                                                        class="form-select form-control-sm"
                                                                                        formControlName="CLUSTER_SUBJECT1_GRADE">
                                                                                        <option value=''>
                                                                                            Select
                                                                                        </option>
                                                                                        <option
                                                                                            *ngFor="let item of ClusterGradeList"
                                                                                            [value]="item.CLUSTER_GRADE_ID ">
                                                                                            {{item.CLUSTER_GRADE_NAME}}
                                                                                        </option>
                                                                                    </select>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>CLUSTER SUBJECT 2
                                                                                </td>
                                                                                <!-- <td><input type="text" onkeypress="return /[a-a-zA-Z'-'\s]/i.test(event.key)"
                                                                           formControlName="CLUSTER_SUBJECT2_NAME"
                                                                           class="form-control form-control-sm">
                                                                   </td>
                                                                   <td><input type="text"
                                                                           formControlName="CLUSTER_SUBJECT2_GRADE"
                                                                           class="form-control form-control-sm">
                                                                   </td> -->
                                                                                <td>
                                                                                    <select
                                                                                        class="form-select form-control-sm"
                                                                                        formControlName="CLUSTER_SUBJECT2_NAME">
                                                                                        <option value=''>
                                                                                            Select
                                                                                        </option>
                                                                                        <option
                                                                                            *ngFor="let item of clusterDropdowns[1]"
                                                                                            [value]="item.CLUSTERDTL_ID">
                                                                                            {{item.CLUSTER_SUBJECT_NAME}}
                                                                                        </option>
                                                                                    </select>
                                                                                </td>
                                                                                <td>
                                                                                    <select
                                                                                        class="form-select form-control-sm"
                                                                                        formControlName="CLUSTER_SUBJECT2_GRADE">
                                                                                        <option value=''>
                                                                                            Select
                                                                                        </option>
                                                                                        <option
                                                                                            *ngFor="let item of ClusterGradeList"
                                                                                            [value]="item.CLUSTER_GRADE_ID ">
                                                                                            {{item.CLUSTER_GRADE_NAME}}
                                                                                        </option>
                                                                                    </select>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>CLUSTER SUBJECT 3
                                                                                </td>
                                                                                <!-- <td><input type="text" onkeypress="return /[a-a-zA-Z'-'\s]/i.test(event.key)"
                                                                           formControlName="CLUSTER_SUBJECT3_NAME"
                                                                           class="form-control form-control-sm">
                                                                   </td>
                                                                   <td><input type="text"
                                                                           formControlName="CLUSTER_SUBJECT3_GRADE"
                                                                           class="form-control form-control-sm">
                                                                   </td> -->
                                                                                <td>
                                                                                    <select
                                                                                        class="form-select form-control-sm"
                                                                                        formControlName="CLUSTER_SUBJECT3_NAME">
                                                                                        <option value=''>
                                                                                            Select
                                                                                        </option>
                                                                                        <option
                                                                                            *ngFor="let item of clusterDropdowns[2]"
                                                                                            [value]="item.CLUSTERDTL_ID">
                                                                                            {{item.CLUSTER_SUBJECT_NAME}}
                                                                                        </option>
                                                                                    </select>
                                                                                </td>
                                                                                <td>
                                                                                    <select
                                                                                        class="form-select form-control-sm"
                                                                                        formControlName="CLUSTER_SUBJECT3_GRADE">
                                                                                        <option value=''>
                                                                                            Select
                                                                                        </option>
                                                                                        <option
                                                                                            *ngFor="let item of ClusterGradeList"
                                                                                            [value]="item.CLUSTER_GRADE_ID ">
                                                                                            {{item.CLUSTER_GRADE_NAME}}
                                                                                        </option>
                                                                                    </select>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>CLUSTER SUBJECT 4
                                                                                </td>
                                                                                <!-- <td><input type="text" onkeypress="return /[a-a-zA-Z'-'\s]/i.test(event.key)"
                                                                           formControlName="CLUSTER_SUBJECT4_NAME"
                                                                           class="form-control form-control-sm">
                                                                   </td>
                                                                   <td><input type="text"
                                                                           formControlName="CLUSTER_SUBJECT4_GRADE"
                                                                           class="form-control form-control-sm">
                                                                   </td> -->
                                                                                <td>
                                                                                    <select
                                                                                        class="form-select form-control-sm"
                                                                                        formControlName="CLUSTER_SUBJECT4_NAME">
                                                                                        <option value=''>
                                                                                            Select
                                                                                        </option>
                                                                                        <option
                                                                                            *ngFor="let item of clusterDropdowns[3]"
                                                                                            [value]="item.CLUSTERDTL_ID">
                                                                                            {{item.CLUSTER_SUBJECT_NAME}}
                                                                                        </option>
                                                                                    </select>
                                                                                </td>
                                                                                <td>
                                                                                    <select
                                                                                        class="form-select form-control-sm"
                                                                                        formControlName="CLUSTER_SUBJECT4_GRADE">
                                                                                        <option value=''>
                                                                                            Select
                                                                                        </option>
                                                                                        <option
                                                                                            *ngFor="let item of ClusterGradeList"
                                                                                            [value]="item.CLUSTER_GRADE_ID ">
                                                                                            {{item.CLUSTER_GRADE_NAME}}
                                                                                        </option>
                                                                                    </select>
                                                                                </td>
                                                                            </tr>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <!-- <div class="row mb-2">
                                                       <div class="col-md-4">English <span
                                                               class="text-danger">*</span>
                                                       </div>
                                                       <div class="col-md-6">
                                                           <div class="dropdown">
                                                               <select
                                                                   class="form-control form-control-sm"
                                                                   formControlName="ENGLISH_PROFICIENCY">
                                                                   <option value="0">Select
                                                                       Level
                                                                   </option>
                                                                   <option value="1">Beginner
                                                                   </option>
                                                                   <option value="2">
                                                                       Intermediate
                                                                   </option>
                                                                   <option value="3">Advanced
                                                                   </option>
                                                                   <option value="4">Mastery
                                                                   </option>
                                                               </select>

                                                           </div>
                                                           <span class="text-blue small">(your
                                                               language proficiency
                                                               level in both speaking and
                                                               writing)</span>
                                                       </div>

                                                   </div>
                                                   <div class="row mb-2">
                                                       <div class="col-md-4">Computing
                                                           Skills<span
                                                               class="text-danger">*</span>
                                                       </div>
                                                       <div class="col-md-6">
                                                           <div class="dropdown">
                                                               <select
                                                                   class="form-control form-control-sm"
                                                                   formControlName="COMPUTING_SKILLS_PROFICIENCY">
                                                                   <option value="0">Select
                                                                       Level
                                                                   </option>
                                                                   <option value="1">Beginner
                                                                   </option>
                                                                   <option value="2">
                                                                       Intermediate
                                                                   </option>
                                                                   <option value="3">Advanced
                                                                   </option>
                                                                   <option value="4">Mastery
                                                                   </option>
                                                               </select>
                                                           </div>
                                                           <span
                                                               class="text-blue small">(describe
                                                               your computer
                                                               handling skills)</span>
                                                       </div>
                                                   </div> -->
                                                            <!-- <div class="row mb-2">
                                                   <div class="col-md-4">Mode of Study
                                                       <span class="text-danger"
                                                           style="font-size:20px;">*</span>
                                                   </div>
                                                   <div class="col-md-6">
                                                       <select
                                                           formControlName="MODE_OF_STUDY"
                                                           class="form-control form-control-sm">
                                                           <option value="0">Select
                                                           </option>
                                                           <option value="1">Fulltime
                                                           </option>
                                                           <option value="2">Part-time
                                                           </option>
                                                           <option value="3">Modular
                                                           </option>
                                                       </select>
                                                   </div>
                                               </div> -->
                                                            <div class="row mb-2">
                                                                <div class="col-md-6">Are you applying
                                                                    for
                                                                    <b>Recognition of
                                                                        Prior Learning (RPL)?</b> <span
                                                                        class="text-danger"> *</span>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <select class="form-control form-control-sm"
                                                                        formControlName="IS_RPL">
                                                                        <!-- <option value="null">Select
                                                           </option> -->
                                                                        <option value="true">Yes
                                                                        </option>
                                                                        <option value="false">No
                                                                        </option>
                                                                    </select>
                                                                    <!-- <small>(dropdown: YES/NO)</small> -->
                                                                </div>
                                                            </div>
                                                            <div class="row mb-2">
                                                                <div class="col-md-6">Upload your
                                                                    Portfolio
                                                                    Documents</div>
                                                                <div class="col-md-6">
                                                                    <div class="input-group">
                                                                        <input type="file" formControlName="UPLOAD_3"
                                                                            (change)="changeFile($event,3)"
                                                                            class="form-control"
                                                                            placeholder="Recipient's username"
                                                                            aria-label="Recipient's username"
                                                                            aria-describedby="basic-addon2"
                                                                            #fileInputRef>
                                                                        <span class="input-group-text"
                                                                            style="cursor: pointer;" id="basic-addon2"
                                                                            (click)="removeFile('2')">Remove</span>
                                                                    </div>
                                                                    <div class="text-blue"><small>(If
                                                                            you
                                                                            applying for RPL
                                                                            your
                                                                            portfolio documents should
                                                                            be
                                                                            scanned and
                                                                            uploaded.)</small>
                                                                    </div>
                                                                    <p *ngIf="upload_3 !=''">
                                                                        {{upload_3}}</p>
                                                                    <div *ngIf="isProgressThree" class="progress my-3">
                                                                        <div class="progress-bar progress-bar-info progress-bar-striped"
                                                                            role="progressbar"
                                                                            attr.aria-valuenow="{{ progressThree }}"
                                                                            aria-valuemin="0" aria-valuemax="100"
                                                                            [ngStyle]="{ width: progressThree + '%' }">
                                                                            {{ progressThree }}%
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-12 mt-3">
                                                                <a class="float-start btn btn-sm admin-btn btn-sm px-xl-4 px-2 rounded-0 btnPrevious"
                                                                    (click)="previousTab()">Previous</a>
                                                                <a class="float-end btn btn-sm admin-btn btn-sm px-xl-4 px-2 rounded-0 btnNext"
                                                                    (click)="saveProgramData(signUpForm)">Save &
                                                                    Next</a>
                                                            </div>
                                                        </div>
                                                        <div class="card-footer bg-white mt-3 d-none ">
                                                            <div style="width: 35%" class="mb-2"><img
                                                                    src="../../../../assets/img/OUK-Admissions-logo.png"
                                                                    class="img-fluid" alt=""></div>
                                                            <p class="small">OUK is a public university
                                                                that
                                                                was established
                                                                and Chartered in 2023. We
                                                                aim
                                                                to provide
                                                                inclusive and flexible high quality
                                                                higher
                                                                education
                                                                opportunities suited for the global
                                                                citizens in the
                                                                21st century. At OUK admissions, we
                                                                recruit
                                                                and enroll
                                                                diverse class of students.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="Education-Bg-tab" role="tabpanel"
                                    aria-labelledby="Education-Bg">
                                    <div class="card border-0 card-shadow h-100">
                                        <div class="card-body assesment pt-0 style-1 ">
                                            <div class="row">
                                                <div class="col-md-12 m-auto">
                                                    <div class="card border-0 card-shadow h-100">
                                                        <div class="card-body">
                                                            <div class="card_title mb-3 px-2 py-1 text-white">
                                                                <h6 class="mb-0">Education Background
                                                                </h6>
                                                            </div>
                                                            <div>
                                                                <div class="row mb-2">
                                                                    <div class="col-md-4">Institution
                                                                        Name<span class="text-danger"
                                                                            style="font-size:20px;">*</span>
                                                                    </div>
                                                                    <div class="col-md-6"> <input type="text"
                                                                            [(ngModel)]="INSTITUTION_NAME"
                                                                            [ngModelOptions]="{standalone:true}"
                                                                            onkeypress="return /[a-a-zA-Z'-'\s]/i.test(event.key)"
                                                                            class="form-control form-control-sm">
                                                                    </div>
                                                                </div>
                                                                <div class="row mb-2">
                                                                    <div class="col-md-4">Country</div>
                                                                    <div class="col-md-6"> <select
                                                                            class="form-control form-control-sm"
                                                                            [(ngModel)]="EDUCATION_COUNTRY_ID"
                                                                            [ngModelOptions]="{standalone: true}">
                                                                            <option value="">Select
                                                                            </option>
                                                                            <option *ngFor='let item of country'
                                                                                [value]='item.COUNTRY_ID'>
                                                                                {{item.COUNTRY_NAME}}
                                                                            </option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div class="row mb-2">
                                                                    <div class="col-md-4">Level of
                                                                        Study<span class="text-danger"
                                                                            style="font-size:20px;">*</span>
                                                                    </div>
                                                                    <div class="col-md-6">
                                                                        <input type="text" [(ngModel)]="LEVEL_OF_STUDY"
                                                                            [ngModelOptions]="{standalone: true}"
                                                                            onkeypress="return /[a-a-zA-Z'-'\s]/i.test(event.key)"
                                                                            class="form-control form-control-sm"><span
                                                                            class="text-blue"><small>e.g.
                                                                                Primary School, High
                                                                                School, Certificate,
                                                                                Diploma,
                                                                                Degree,
                                                                                Masters</small></span>
                                                                    </div>
                                                                </div>
                                                                <div class="row mb-2">
                                                                    <div class="col-md-4">Qualifications
                                                                        Attained<span class="text-danger"
                                                                            style="font-size:20px;">*</span>
                                                                    </div>
                                                                    <div class="col-md-6">
                                                                        <select
                                                                            (change)="yearValidate($any($event.target).value);clearOther($any($event.target).value)"
                                                                            class="form-select form-select-sm"
                                                                            [(ngModel)]="QUALIFICATION_ID"
                                                                            [ngModelOptions]="{standalone: true}">
                                                                            <option value="">Select
                                                                            </option>
                                                                            <option value="1">KCSE
                                                                            </option>
                                                                            <option value="2">IGCSE
                                                                            </option>
                                                                            <option value="3">GCSE
                                                                            </option>
                                                                            <option value="4">IB
                                                                            </option>
                                                                            <option value="5">Others
                                                                            </option>
                                                                        </select>
                                                                        <div class="pt-2">
                                                                            <input class="form-control form-control-sm"
                                                                                [(ngModel)]="QUALIFICATION"
                                                                                [ngModelOptions]="{standalone: true}"
                                                                                *ngIf="QUALIFICATION_ID == '5'"
                                                                                type="text"
                                                                                onkeypress="return /[a-a-zA-Z'-'\s]/i.test(event.key)">
                                                                        </div>

                                                                    </div>
                                                                    <!-- <div
                                                   class="col-xl-3 col-md-6 col-12 mb-3 pt-4">
                                                   <button
                                                       class="btn btn-danger btn-sm "
                                                       (click)="remove(i)">Remove</button>
                                               </div> -->

                                                                </div>
                                                                <div class="row mb-2">
                                                                    <div class="col-md-4">Start
                                                                        Year<span class="text-danger"
                                                                            style="font-size:20px;">*</span>
                                                                    </div>


                                                                    <div class="col-md-3">
                                                                        <div class="form-group">
                                                                            <!-- <label>Year</label> -->
                                                                            <select class="form-select form-select-sm"
                                                                                [(ngModel)]="selectedYearStart"
                                                                                (change)="generateDropdowns();clearMonthDayWrk('start','edu')"
                                                                                [ngModelOptions]="{standalone:true}">
                                                                                <!-- <option value="0">YYYY</option> -->
                                                                                <option *ngFor="let year of years"
                                                                                    [value]="year">{{
                                                                                    year }}</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-md-2 d-none">
                                                                        <div class="form-group">
                                                                            <label>Month</label>
                                                                            <select class="form-select form-select-sm"
                                                                                [(ngModel)]="selectedMonthStart"
                                                                                (ngModelChange)="generateDropdowns();validateDateRange('edu')"
                                                                                [ngModelOptions]="{standalone:true}">
                                                                                <option *ngFor="let month of months"
                                                                                    [value]="month">{{
                                                                                    month }}</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-md-3 d-none">
                                                                        <div class="form-group">
                                                                            <label>Day</label>
                                                                            <select class="form-select form-select-sm"
                                                                                [(ngModel)]="selectedDayStart"
                                                                                (ngModelChange)="generateDropdowns();validateDateRange('edu')"
                                                                                [ngModelOptions]="{standalone:true}">
                                                                                <option *ngFor="let day of startDays"
                                                                                    [value]="day">{{ day
                                                                                    }}</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    <!-- <div class="col-md-6 d-none"> 
                                                   <input
                                                       type="date"
                                                       [(ngModel)]="FROM_PERIOD"
                                                       (keydown)="disableDate()"
                                                       [ngModelOptions]="{standalone: true}"
                                                       class="form-control form-control-sm"
                                                       id="fromDate"
                                                       (change)="validateStartDate('')">
                                               </div> -->
                                                                </div>
                                                                <div class="row mb-2">
                                                                    <div class="col-md-4">End Year<span
                                                                            class="text-danger"
                                                                            style="font-size:20px;">*</span>
                                                                    </div>

                                                                    <div class="col-md-3">
                                                                        <div class="form-group">
                                                                            <!-- <label>Year</label> -->
                                                                            <select class="form-select form-select-sm"
                                                                                [(ngModel)]="selectedYearEnd"
                                                                                (change)="generateDropdowns();validateDateRange('edu')"
                                                                                [ngModelOptions]="{standalone:true}">
                                                                                <option *ngFor="let year of years"
                                                                                    [value]="year">{{
                                                                                    year }}</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-md-2 d-none">
                                                                        <div class="form-group">
                                                                            <label>Month</label>
                                                                            <select class="form-select form-select-sm"
                                                                                [(ngModel)]="selectedMonthEnd"
                                                                                (ngModelChange)="generateDropdowns();validateDateRange('edu')"
                                                                                [ngModelOptions]="{standalone:true}">
                                                                                <option *ngFor="let month of months"
                                                                                    [value]="month">{{
                                                                                    month }}</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-md-3 d-none">
                                                                        <div class="form-group">
                                                                            <label>Day</label>
                                                                            <select class="form-select form-select-sm"
                                                                                [(ngModel)]="selectedDayEnd"
                                                                                (ngModelChange)="generateDropdowns();validateDateRange('edu')"
                                                                                [ngModelOptions]="{standalone:true}">
                                                                                <option *ngFor="let day of days"
                                                                                    [value]="day">{{ day
                                                                                    }}</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>




                                                                    <!-- <div class="col-md-6"> <input
                                                       type="date"
                                                       [(ngModel)]="TO_PERIOD"
                                                       (keydown)="disableDate()"
                                                       [ngModelOptions]="{standalone: true}"
                                                       class="form-control form-control-sm"
                                                       id="toDate"
                                                       (change)="validateEndDate('')">
                                                
                                               </div> -->
                                                                </div>
                                                                <div class="row mb-2">
                                                                    <div class="col-md-4">Examination
                                                                        Number
                                                                        e.g. KCSE index
                                                                        number<span class="text-danger"
                                                                            style="font-size:20px;">*</span>
                                                                    </div>
                                                                    <div class="col-md-6"> <input type="text"
                                                                            [(ngModel)]="EXAM_REG_NO"
                                                                            [ngModelOptions]="{standalone: true}"
                                                                            class="form-control form-control-sm">
                                                                    </div>
                                                                </div>

                                                                <div class="row mb-2">
                                                                    <div class="col-md-4">Upload
                                                                        CERTIFIED
                                                                        Copies
                                                                        <span class="text-danger"
                                                                            style="font-size:20px;">*</span>
                                                                    </div>

                                                                    <div class="col-md-6">
                                                                        <div class="input-group">
                                                                            <input type="file" [(ngModel)]="UPLOAD_1"
                                                                                [ngModelOptions]="{standalone: true}"
                                                                                (change)="changeFile($event,1)"
                                                                                class="form-control"
                                                                                placeholder="Recipient's username"
                                                                                aria-label="Recipient's username"
                                                                                aria-describedby="basic-addon2"
                                                                                #fileInputRefupload1>
                                                                            <span class="input-group-text"
                                                                                style="cursor: pointer;"
                                                                                id="basic-addon2"
                                                                                (click)="removeFile('1')">Remove</span>
                                                                        </div>
                                                                        <div class="text-blue">
                                                                            <small>(The
                                                                                previous education
                                                                                support
                                                                                documents should be
                                                                                scanned
                                                                                and
                                                                                uploaded.)</small>
                                                                        </div>
                                                                        <p *ngIf="upload_1 !=''">
                                                                            {{upload_1}}</p>
                                                                        <div *ngIf="isProgressTwo"
                                                                            class="progress my-3">
                                                                            <div class="progress-bar progress-bar-info progress-bar-striped"
                                                                                role="progressbar"
                                                                                attr.aria-valuenow="{{ progressTwo }}"
                                                                                aria-valuemin="0" aria-valuemax="100"
                                                                                [ngStyle]="{ width: progressTwo + '%' }">
                                                                                {{ progressTwo }}%
                                                                            </div>
                                                                        </div>
                                                                    </div>


                                                                </div>

                                                                <div class="row mb-2">
                                                                    <div class="col-md-4">Mean Grade<span
                                                                            class="text-danger">*</span>

                                                                    </div>
                                                                    <div class="col-md-6">
                                                                        <!-- [(ngModel)]="grade"  -->
                                                                        <select class="form-select form-select-sm"
                                                                            [(ngModel)]="MEAN_GRADE"
                                                                            [ngModelOptions]="{standalone:true}">
                                                                            <option value="">Select
                                                                                Grade</option>
                                                                            <option *ngFor="let item of grades"
                                                                                [value]="item.AMT_ID">
                                                                                {{item.DESCRIPTION}}
                                                                            </option>
                                                                        </select>

                                                                    </div>
                                                                </div>



                                                                <!-- <div class="row">
                                       <div class="col-12 text-center">
                                             
                                           <button type="button"
                                               class="btn btn-sm admin-btn btn-sm px-4 rounded-0" (click)="add()">Add</button>
                                       </div>
                                   </div> -->
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-12 text-center">
                                                                    <button type="button"
                                                                        class="btn btn-sm admin-btn btn-sm px-4 rounded-0"
                                                                        (click)="add()">Add and
                                                                        Save</button>
                                                                </div>
                                                            </div>
                                                            <div *ngIf="educationData.length" class="table-responsive">
                                                                <table datatable
                                                                    class=" table-striped table-bordered mt-2 pt-2 table-sm small"
                                                                    style="width:100%">
                                                                    <thead class="top"
                                                                        style="background-color: #624FD7; color:#ffffff;">
                                                                        <tr>
                                                                            <th>Institution</th>
                                                                            <!-- <th>Country</th> -->
                                                                            <th>Level of Study</th>
                                                                            <th>Qualifications Attained
                                                                            </th>
                                                                            <th>Start Date</th>
                                                                            <th>End Date</th>
                                                                            <th>KCSE Index Number </th>
                                                                            <th>Grade</th>
                                                                            <th>Action</th>

                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr
                                                                            *ngFor="let row of educationData;let i = index">
                                                                            <td>{{row?.INSTITUTION_NAME}}
                                                                            </td>
                                                                            <!-- <td><select disabled
class="form-control form-control-sm"
[(ngModel)]="row.EDUCATION_COUNTRY_ID" [ngModelOptions]="{standalone: true}"
>
<option value="">Select
</option>
<option
*ngFor='let item of country'
[value]='item.DICTIONARYID'>
{{item.DICTIONARYNAME}}
</option>
</select></td> -->
                                                                            <td>{{row?.LEVEL_OF_STUDY}}
                                                                            </td>
                                                                            <td> <select
                                                                                    [hidden]="row.QUALIFICATION != ''"
                                                                                    class="form-select form-select-sm"
                                                                                    disabled
                                                                                    [(ngModel)]="row.QUALIFICATION_ID"
                                                                                    [ngModelOptions]="{standalone: true}">
                                                                                    <option value="">
                                                                                        Select</option>
                                                                                    <option value="1">
                                                                                        KCSE</option>
                                                                                    <option value="2">
                                                                                        IGCSE</option>
                                                                                    <option value="3">
                                                                                        GCSE</option>
                                                                                    <option value="4">IB
                                                                                    </option>
                                                                                    <option value="5">
                                                                                        Others</option>
                                                                                </select>
                                                                                <div class="pt-2"
                                                                                    [hidden]="row.QUALIFICATION == ''">
                                                                                    <input type="text"
                                                                                        class="form-control form-control-sm"
                                                                                        disabled
                                                                                        [value]="row.QUALIFICATION">
                                                                                </div>

                                                                            </td>
                                                                            <td>{{row?.FROMPERIOD_YEAR
                                                                                }}
                                                                            </td>
                                                                            <td>{{row?.TOPERIOD_YEAR }}
                                                                            </td>
                                                                            <td>{{row?.EXAM_REG_NO}}
                                                                            </td>
                                                                            <td>{{row.GRADE}}</td>
                                                                            <td style="display: flex;">
                                                                                <button class="btn btn-save btn-sm"
                                                                                    (click)="editdata(row,'edu')">Edit</button>
                                                                                &nbsp;&nbsp;
                                                                                <button class="btn btn-danger btn-sm "
                                                                                    (click)="remove(i)">Remove</button>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <div class="text-center" *ngIf="!educationData.length">
                                                                No Records to display
                                                            </div>


                                                            <div class="col-12 mt-3">
                                                                <a class="float-start btn btn-sm admin-btn btn-sm px-xl-4 px-2 rounded-0 btnPrevious"
                                                                    (click)="previousTab()">Previous</a>
                                                                <!-- <a class="float-end btn btn-sm admin-btn btn-sm px-xl-4 px-2 rounded-0 btnNext"
                                               (click)="nextTab()">Next</a> -->
                                                                <button
                                                                    class="float-end btn btn-sm admin-btn btn-sm px-xl-4 px-2 rounded-0 btnNext"
                                                                    (click)="saveEducationData()"
                                                                    [disabled]="!educationData.length">Save
                                                                    & Next</button>
                                                            </div>
                                                        </div>

                                                        <div class="card-footer bg-white mt-3 d-none">
                                                            <div style="width: 35%" class="mb-2"><img
                                                                    src="../../../../assets/img/OUK-Admissions-logo.png"
                                                                    class="img-fluid" alt=""></div>
                                                            <p class="small">OUK is a public university
                                                                that
                                                                was established
                                                                and Chartered in 2023. We
                                                                aim
                                                                to provide
                                                                inclusive and flexible high quality
                                                                higher
                                                                education
                                                                opportunities suited for the global
                                                                citizens in the
                                                                21st century. At OUK admissions, we
                                                                recruit
                                                                and enroll
                                                                diverse class of students.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="tab-pane fade" id="Working-Exp-tab" role="tabpanel"
                                    aria-labelledby="Working-Exp">
                                    <div class="card border-0 card-shadow h-100">
                                        <div class="card-body assesment pt-0 style-1 ">
                                            <div class="row">
                                                <div class="col-md-12 m-auto">
                                                    <div class="card border-0 card-shadow h-100">
                                                        <div class="card-body ">
                                                            <div class="card_title mb-3 px-2 py-1 text-white">
                                                                <h6 class="mb-0">Working/Job Experience
                                                                </h6>
                                                            </div>
                                                            <div>
                                                                <div class="row mb-2">
                                                                    <div class="col-md-4">
                                                                        Company/Employer
                                                                    </div>
                                                                    <div class="col-md-6"> <input type="text"
                                                                            [(ngModel)]="WORKEXP_DETAIL_EMPLOYER"
                                                                            onkeypress="return /[a-a-zA-Z'-'\s]/i.test(event.key)"
                                                                            [ngModelOptions]="{standalone:true}"
                                                                            class="form-control form-control-sm">
                                                                        <!-- <span class="text-blue"><small>(the
                                                       field of
                                                       study/programme
                                                       you are applying for in
                                                       this
                                                       application)</small></span> -->
                                                                    </div>
                                                                </div>



                                                                <div class="row mb-2">
                                                                    <div class="col-md-4">Start
                                                                        Date
                                                                    </div>


                                                                    <div class="col-md-3">
                                                                        <div class="form-group">
                                                                            <label>Year</label>
                                                                            <select class="form-control form-control-sm"
                                                                                [(ngModel)]="selectedYearStartWrk"
                                                                                (change)="generateDropdowns();validateDateRange('wrk');clearMonthDayWrk('start','wrk')"
                                                                                [ngModelOptions]="{standalone:true}">
                                                                                <option *ngFor="let year of years"
                                                                                    [value]="year">{{
                                                                                    year }}</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-md-2">
                                                                        <div class="form-group">
                                                                            <label>Month</label>
                                                                            <select class="form-control form-control-sm"
                                                                                [(ngModel)]="selectedMonthStartWrk"
                                                                                (ngModelChange)="generateDropdowns();validateDateRange('wrk')"
                                                                                [ngModelOptions]="{standalone:true}">
                                                                                <option *ngFor="let month of months"
                                                                                    [value]="month">{{
                                                                                    month }}</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-md-3">
                                                                        <div class="form-group">
                                                                            <label>Day</label>
                                                                            <select class="form-control form-control-sm"
                                                                                [(ngModel)]="selectedDayStartWrk"
                                                                                (ngModelChange)="generateDropdowns();validateDateRange('wrk')"
                                                                                [ngModelOptions]="{standalone:true}">
                                                                                <option *ngFor="let day of wrkStartDays"
                                                                                    [value]="day">{{ day
                                                                                    }}</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                                <div class="row mb-">
                                                                    <div class="col-md-4">End date
                                                                    </div>

                                                                    <div class="col-md-3">
                                                                        <div class="form-group">
                                                                            <label>Year</label>
                                                                            <select class="form-control form-control-sm"
                                                                                [(ngModel)]="selectedYearEndWrk"
                                                                                (change)="generateDropdowns();validateDateRange('wrk');clearMonthDayWrk('end','')"
                                                                                [ngModelOptions]="{standalone:true}">
                                                                                <option *ngFor="let year of years"
                                                                                    [value]="year">{{
                                                                                    year }}</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-md-2">
                                                                        <div class="form-group">
                                                                            <label>Month</label>
                                                                            <select class="form-control form-control-sm"
                                                                                [(ngModel)]="selectedMonthEndWrk"
                                                                                (ngModelChange)="generateDropdowns();validateDateRange('wrk')"
                                                                                [ngModelOptions]="{standalone:true}">
                                                                                <option *ngFor="let month of months"
                                                                                    [value]="month">{{
                                                                                    month }}</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-md-3">
                                                                        <div class="form-group">
                                                                            <label>Day</label>
                                                                            <select class="form-control form-control-sm"
                                                                                [(ngModel)]="selectedDayEndWrk"
                                                                                (ngModelChange)="generateDropdowns();validateDateRange('wrk')"
                                                                                [ngModelOptions]="{standalone:true}">
                                                                                <option *ngFor="let day of wrkEndDays"
                                                                                    [value]="day">{{ day
                                                                                    }}</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>


                                                                </div>

                                                                <!-- <div class="row mb-2">
                                           <div class="col-md-4">Start Date
                                           </div>
                                           <div class="col-md-6"> <input
                                                   type="date"
                                                   [(ngModel)]="WORKEXP_DETAIL_FROM_YEAR"
                                                   (keydown)="disableDate()"
                                                   (change)="validateStartDate('w')"
                                                   [ngModelOptions]="{standalone:true}"
                                                   class="form-control form-control-sm">
                                           </div>
                                       </div>
                                       <div class="row mb-2">
                                           <div class="col-md-4">End Date</div>
                                           <div class="col-md-6"> <input
                                                   type="date"
                                                   (change)="validateEndDate('w')"
                                                   [(ngModel)]="WORKEXP_DETAIL_TO_YEAR"
                                                   (keydown)="disableDate()"
                                                   [ngModelOptions]="{standalone:true}"
                                                   class="form-control form-control-sm">
                                           </div>
                                       </div> -->
                                                                <div class="row mb-2 pt-2">
                                                                    <div class="col-md-4">Designation
                                                                    </div>
                                                                    <div class="col-md-6"> <input type="text"
                                                                            [(ngModel)]="WORKEXP_DETAIL_DESIGNATION"
                                                                            onkeypress="return /[a-a-zA-Z'-'\s]/i.test(event.key)"
                                                                            [ngModelOptions]="{standalone:true}"
                                                                            class="form-control form-control-sm">
                                                                    </div>
                                                                    <!-- <div
                                               class="col-xl-3 col-md-6 col-12 mb-3 pt-4">
                                               <button
                                                   class="btn btn-danger btn-sm"
                                                   (click)="removeApplicantDetails(i)">Remove</button>
                                           </div> -->
                                                                </div>
                                                                <div class="row mb-2">
                                                                    <div class="col-md-12 text-center">
                                                                        <button type="button"
                                                                            class="btn btn-sm admin-btn btn-sm px-4 rounded-0"
                                                                            (click)="addApplicantDetails()">Add
                                                                            and Save</button>
                                                                    </div>
                                                                </div>
                                                            </div>


                                                            <div *ngIf="ApplicantData.length">
                                                                <table datatable
                                                                    class=" table-striped table-bordered mt-2 pt-2 table-sm small table-responsive"
                                                                    style="width:100%">
                                                                    <thead class="top"
                                                                        style="background-color: #624FD7; color:#ffffff;">
                                                                        <tr>
                                                                            <th>Company/Employer</th>

                                                                            <th>Start Date</th>
                                                                            <th>End Date</th>
                                                                            <th>Designation</th>

                                                                            <th>Action</th>

                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr
                                                                            *ngFor="let row of ApplicantData;let i = index">
                                                                            <td>{{row?.WORKEXP_DETAIL_EMPLOYER}}
                                                                            </td>
                                                                            <td>{{row?.WORKEXP_DETAIL_FROM_YEAR}}
                                                                            </td>
                                                                            <td>{{row?.WORKEXP_DETAIL_TO_YEAR}}
                                                                            </td>
                                                                            <td>{{row?.WORKEXP_DETAIL_DESIGNATION}}
                                                                            </td>
                                                                            <td style="display: flex;">
                                                                                <button class="btn btn-save btn-sm"
                                                                                    (click)="editdata(row,'wrkExp')">Edit</button>
                                                                                &nbsp;&nbsp;
                                                                                <button class="btn btn-danger btn-sm "
                                                                                    (click)="removeApplicantDetails(i)">Remove</button>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <div class="text-center" *ngIf="!ApplicantData.length">
                                                                No Records to display
                                                            </div>

                                                            <!-- <div class="row mb-2">
                                   <div class="col-md-12 text-center"><button
                                           type="button"
                                           class="btn btn-sm admin-btn btn-sm px-4 rounded-0">Add</button>
                                   </div>
                               </div> -->
                                                        </div>
                                                        <div class="col-12 mt-3">
                                                            <a class="float-start btn btn-sm admin-btn btn-sm px-xl-4 px-2 rounded-0 btnPrevious"
                                                                (click)="previousTab()">Previous</a>
                                                            <a class="float-end btn btn-sm admin-btn btn-sm px-xl-4 px-2 rounded-0 btnNext"
                                                                (click)="saveWorkExpData()">Save &
                                                                Next</a>
                                                            <!-- <button
                                           class="float-end btn btn-sm admin-btn btn-sm px-xl-4 px-2 rounded-0 btnNext"
                                           (click)="nextTab()"
                                           [disabled]="!ApplicantData.length">Next</button> -->
                                                        </div>
                                                    </div>
                                                    <div class="card-footer bg-white mt-3 d-none">
                                                        <div style="width: 35%" class="mb-2"><img
                                                                src="../../../../assets/img/OUK-Admissions-logo.png"
                                                                class="img-fluid" alt=""></div>
                                                        <p class="small">OUK is a public university that
                                                            was
                                                            established and
                                                            Chartered in 2023. We
                                                            aim
                                                            to provide
                                                            inclusive and flexible high quality higher
                                                            education
                                                            opportunities suited for the global
                                                            citizens in the
                                                            21st century. At OUK admissions, we recruit
                                                            and
                                                            enroll diverse
                                                            class of students.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="Recommendations-tab" role="tabpanel"
                                    aria-labelledby="Recommendations">
                                    <div class="card border-0 card-shadow h-100">
                                        <div class="card-body assesment pt-0 style-1 ">
                                            <div class="row">
                                                <div class="col-md-12 m-auto">
                                                    <div class="card border-0 card-shadow h-100">
                                                        <div class="card-body ">
                                                            <div class="card_title mb-3 px-2 py-1 text-white">
                                                                <h6 class="mb-0">Recommendations</h6>
                                                            </div>
                                                            <div>


                                                                <div class="row mb-2">
                                                                    <div class="col-md-4">Full Name
                                                                        <span class="text-danger">*</span>
                                                                    </div>
                                                                    <div class="col-md-6"><input type="text"
                                                                            [(ngModel)]="NAME"
                                                                            onkeypress="return /[a-a-zA-Z'-'\s]/i.test(event.key)"
                                                                            [ngModelOptions]="{standalone:true}"
                                                                            class="form-control form-control-sm">
                                                                        <!-- <span class="text-blue"><small>(the
                                                      field of
                                                      study/programme
                                                      you are applying for in
                                                      this
                                                      application)</small></span> -->
                                                                    </div>
                                                                </div>
                                                                <div class="row mb-2">
                                                                    <div class="col-md-4">
                                                                        Organization<span class="text-danger">*</span>
                                                                    </div>
                                                                    <div class="col-md-6"><input type="text"
                                                                            onkeypress="return /[a-a-zA-Z'-'\s]/i.test(event.key)"
                                                                            [(ngModel)]="ORGANIZATION"
                                                                            [ngModelOptions]="{standalone:true}"
                                                                            class="form-control form-control-sm">
                                                                    </div>
                                                                </div>
                                                                <div class="row mb-2">
                                                                    <div class="col-md-4">Country <span
                                                                            class="text-danger">*</span>
                                                                    </div>
                                                                    <div class="col-md-6">
                                                                        <select [(ngModel)]="COUNTRY_ID"
                                                                            [ngModelOptions]="{standalone: true}"
                                                                            (change)="changeCountryCode($any($event.target)?.value)"
                                                                            class="form-select form-select-sm">
                                                                            <option value="0">Select
                                                                            </option>
                                                                            <option *ngFor='let item of country'
                                                                                [value]='item.COUNTRY_ID'>
                                                                                {{item.COUNTRY_NAME}}
                                                                            </option>
                                                                        </select>

                                                                    </div>
                                                                </div>

                                                                <div class="row mb-2">
                                                                    <div class="col-md-4">Mobile Number
                                                                    </div>
                                                                    <div class="col-md-3">
                                                                        <select class="form-select form-select-sm"
                                                                            [(ngModel)]="MOBILENO_CODE_ID"
                                                                            [ngModelOptions]="{standalone:true}">
                                                                            <option value="">Select
                                                                                country code</option>
                                                                            <option *ngFor="let item of country"
                                                                                [value]="item.COUNTRY_ID">
                                                                                {{item.COUNTRY_CONNECTING_CODE}}
                                                                            </option>
                                                                        </select>

                                                                        <!-- <input type="text" disabled class="form-control form-control-sm" [(ngModel)]="countryCode" [ngModelOptions]="{standalone:true}"> -->
                                                                    </div>&nbsp;
                                                                    <div class="col-md-4">
                                                                        <input type="text"
                                                                            (keypress)="onKeyPress($event)"
                                                                            maxlength="12" [(ngModel)]="MOBILE_NUMBER"
                                                                            [ngModelOptions]="{standalone:true}"
                                                                            class="form-control form-control-sm">
                                                                    </div>
                                                                </div>



                                                                <div class="row mb-2">
                                                                    <div class="col-md-4">City/Town
                                                                    </div>
                                                                    <div class="col-md-6"><input type="text"
                                                                            onkeypress="return /[a-a-zA-Z'-'\s]/i.test(event.key)"
                                                                            [(ngModel)]="CITY_TOWN"
                                                                            [ngModelOptions]="{standalone:true}"
                                                                            class="form-control form-control-sm">
                                                                    </div>
                                                                </div>
                                                                <div class="row mb-2">
                                                                    <div class="col-md-4">Nationality
                                                                    </div>
                                                                    <div class="col-md-6">
                                                                        <select class="form-select form-control-sm"
                                                                            [(ngModel)]="NATIONALITY"
                                                                            [ngModelOptions]="{standalone: true}">
                                                                            <option value="0">Select
                                                                            </option>
                                                                            <option *ngFor="let item of nationalityList"
                                                                                [value]="item.NAN_ID">
                                                                                {{item.NAN_CODE}}
                                                                            </option>
                                                                        </select>
                                                                        <!-- <input
                                                  type="text" onkeypress="return /[a-a-zA-Z'-'\s]/i.test(event.key)"
                                                  [(ngModel)]="NATIONALITY"
                                                  [ngModelOptions]="{standalone:true}"
                                                  class="form-control form-control-sm"> -->
                                                                    </div>
                                                                </div>
                                                                <div class="row mb-2">
                                                                    <div class="col-md-4">Email Address
                                                                    </div>
                                                                    <div class="col-md-6"><input type="text"
                                                                            (change)="validateEmail($event,'sponserEmail')"
                                                                            [(ngModel)]="EMAIL_ADDRESS"
                                                                            [ngModelOptions]="{standalone:true}"
                                                                            class="form-control form-control-sm">
                                                                    </div>

                                                                </div>
                                                                <!-- <div
                                          class="col-xl-3 col-md-6 col-12 mb-3 pt-4">
                                          <button
                                              class="btn btn-danger btn-sm "
                                              (click)="removeRefreeData(i)">Remove</button>
                                      </div> -->
                                                                <div class="row mb-2">
                                                                    <div class="col-md-12 text-center">
                                                                        <button type="button"
                                                                            class="btn btn-sm admin-btn btn-sm px-4 rounded-0"
                                                                            (click)="addRefreeData()">Add
                                                                            and Save</button>
                                                                    </div>
                                                                </div>
                                                            </div>



                                                            <div *ngIf="RefreeData.length" class="table-responsive">
                                                                <table datatable
                                                                    class=" table-striped table-bordered mt-2 pt-2 table-sm small "
                                                                    style="width:100%">
                                                                    <thead class="top"
                                                                        style="background-color: #624FD7; color:#ffffff;">
                                                                        <tr>
                                                                            <th>Full Name</th>
                                                                            <th>Organization</th>
                                                                            <th>Mobile Number</th>
                                                                            <!-- <th>City/Town</th> -->
                                                                            <!-- <th>Nationality</th> -->
                                                                            <th>Email Address</th>
                                                                            <th>Action</th>

                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr
                                                                            *ngFor="let row of RefreeData;let i = index">
                                                                            <td>{{row?.NAME}}</td>
                                                                            <td>{{row?.ORGANIZATION}}
                                                                            </td>
                                                                            <td>+{{row?.MOBILENO_CODE_ID}}
                                                                                {{row?.MOBILE_NUMBER}}
                                                                            </td>
                                                                            <!-- <td>{{row.CITY_TOWN}}</td> -->
                                                                            <!-- <td>{{row.NATIONALITY}}</td> -->
                                                                            <td>{{row?.EMAIL_ADDRESS}}
                                                                            </td>
                                                                            <!-- <td>{{row.MOBILE_NUMBER}}</td> -->
                                                                            <td style="display: flex;">
                                                                                <button class="btn btn-save btn-sm"
                                                                                    (click)="editdata(row,'refre')">Edit</button>
                                                                                &nbsp;&nbsp;
                                                                                <button class="btn btn-danger btn-sm "
                                                                                    (click)="removeRefreeData(i)">Remove</button>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <div class="text-center" *ngIf="!RefreeData.length">
                                                                No Records to display
                                                            </div>
                                                            <div class="col-12 mt-3">
                                                                <!-- <button type="button"
                                          class="btn btn-sm admin-btn btn-sm px-4 rounded-0 text-center"
                                          (click)="addRefreeData()">Add</button> -->
                                                                <a class="float-start btn btn-sm admin-btn btn-sm px-xl-4 px-2 rounded-0 btnPrevious"
                                                                    (click)="previousTab()">Previous</a>
                                                                <!-- <a class="float-end btn btn-sm admin-btn btn-sm px-xl-4 px-2 rounded-0 btnNext"
                                          (click)="nextTab()">Next</a> -->
                                                                <button
                                                                    class="float-end btn btn-sm admin-btn btn-sm px-xl-4 px-2 rounded-0 btnNext"
                                                                    (click)="saveRecommondationData()"
                                                                    [disabled]="!RefreeData.length">Save
                                                                    & Next</button>
                                                            </div>
                                                        </div>
                                                        <div class="card-footer bg-white mt-3 d-none">
                                                            <div style="width: 35%" class="mb-2"><img
                                                                    src="../../../../assets/img/OUK-Admissions-logo.png"
                                                                    class="img-fluid" alt=""></div>
                                                            <p class="small">OUK is a public university
                                                                that
                                                                was established
                                                                and Chartered in 2023. We
                                                                aim
                                                                to provide
                                                                inclusive and flexible high quality
                                                                higher
                                                                education
                                                                opportunities suited for the global
                                                                citizens in the
                                                                21st century. At OUK admissions, we
                                                                recruit
                                                                and enroll
                                                                diverse class of students.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="Next-of-Kin-tab" role="tabpanel"
                                    aria-labelledby="Next-of-Kin">
                                    <div class="card border-0 card-shadow h-100">
                                        <div class="card-body assesment pt-0 style-1 ">
                                            <div class="row">
                                                <div class="col-md-12 m-auto">
                                                    <div class="card border-0 card-shadow h-100">
                                                        <div class="card-body">
                                                            <div class="card_title mb-3 px-2 py-1 text-white">
                                                                <h6 class="mb-0">Next of Kin/
                                                                    Emergency
                                                                    Contact</h6>
                                                            </div>
                                                            <div class="row mb-2">
                                                                <div class="col-md-4">Full Name <span
                                                                        class="text-danger"
                                                                        style="font-size:20px;">*</span>
                                                                </div>
                                                                <div class="col-md-6"> <input type="text"
                                                                        formControlName="NAME_OF_EMERGENCY"
                                                                        onkeypress="return /[a-a-zA-Z'-'\s]/i.test(event.key)"
                                                                        class="form-control form-control-sm">
                                                                </div>
                                                            </div>
                                                            <div class="row mb-2">
                                                                <div class="col-md-4">Relationship <span
                                                                        class="text-danger"
                                                                        style="font-size:20px;">*</span>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <select class="form-select form-select-sm"
                                                                        formControlName="RELATIONSHIPNAME_OF_EMERGENCY">
                                                                        <option value="0">Select
                                                                            Relationship</option>
                                                                        <option value="1">Father
                                                                        </option>
                                                                        <option value="2">Mother
                                                                        </option>
                                                                        <option value="3">Guardian
                                                                        </option>
                                                                        <option value="4">Spouse
                                                                        </option>
                                                                    </select>

                                                                </div>
                                                            </div>
                                                            <div class="row mb-2">
                                                                <div class="col-md-4">Country <span class="text-danger"
                                                                        style="font-size:20px;">*</span>
                                                                </div>
                                                                <div class="col-md-6"> <select
                                                                        (change)="changeCountry($any($event.target)?.value,'nextofKin')"
                                                                        class="form-select form-select-sm"
                                                                        formControlName="COUNTRYID_OF_EMERGENCY">
                                                                        <option value="0">Select
                                                                        </option>
                                                                        <option *ngFor='let item of country'
                                                                            [value]='item.COUNTRY_ID'>
                                                                            {{item.COUNTRY_NAME}}
                                                                        </option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div class="row mb-2" *ngIf="nextofKinCountyDrpdown">
                                                                <div class="col-md-4">County
                                                                    <span class="text-danger"
                                                                        style="font-size:20px;">*</span>
                                                                </div>
                                                                <div class="col-md-6"> <select
                                                                        class="form-select form-select-sm"
                                                                        formControlName="COUNTIES_ID_OF_EMERGENCY">
                                                                        <option value="">Select
                                                                        </option>
                                                                        <option *ngFor='let item of County'
                                                                            [value]='item.COUNTIES_CODE'>
                                                                            {{item.COUNTIES_NAME}}
                                                                        </option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div class="row mb-2">
                                                                <div class="col-md-4">Telephone <span
                                                                        class="text-danger"
                                                                        style="font-size:20px;">*</span>
                                                                </div>
                                                                <div class="col-md-3">
                                                                    <select class="form-select form-select-sm"
                                                                        formControlName="EMERGENCY_MOBILENO_CODE_ID"
                                                                        [(ngModel)]="emergencyCountryCode">
                                                                        <option *ngFor="let item of country"
                                                                            [value]="item.COUNTRY_ID">
                                                                            {{item.COUNTRY_CONNECTING_CODE}}
                                                                        </option>
                                                                    </select>
                                                                    <!-- <input type="text" disabled class="form-control form-control-sm" [(ngModel)]="emergencyCountryCode" [ngModelOptions]="{standalone:true}"> -->
                                                                </div>&nbsp;
                                                                <div class="col-md-4"> <input
                                                                        placeholder="Ex : 712345678, do not start with 0"
                                                                        type="text" (blur)="mobileValidation($event,3)"
                                                                        (keypress)="onKeyPress($event)"
                                                                        formControlName="MOBILE_NO_EMERGENCY"
                                                                        maxlength="12"
                                                                        class="form-control form-control-sm">
                                                                    <!-- <span class="text-blue"><small>Include
                                               your country code in the
                                               designated
                                               field.</small></span> -->
                                                                </div>
                                                            </div>
                                                            <div class="row mb-2">
                                                                <div class="col-md-4">Email Address
                                                                </div>
                                                                <div class="col-md-6"><input type="text"
                                                                        (change)="validateEmail($event,'Email')"
                                                                        class="form-control form-control-sm"
                                                                        [(ngModel)]="Emailid"
                                                                        formControlName="EMAILID_OF_EMERGENCY">
                                                                </div>
                                                            </div>
                                                            <div class="row mb-2">
                                                                <div class="col-md-4">Postal Address
                                                                </div>
                                                                <div class="col-md-6"> <input type="text"
                                                                        placeholder="P.O.Box"
                                                                        formControlName="ADDRESS_OF_EMERGENCY"
                                                                        class="form-control form-control-sm">
                                                                </div>
                                                            </div>
                                                            <div class="row mb-2">
                                                                <div class="col-md-4">Postal Code</div>
                                                                <div class="col-md-6"><input type="text"
                                                                        class="form-control form-control-sm"
                                                                        maxlength="10"
                                                                        formControlName="POSTALCODE_OF_EMERGENCY">
                                                                </div>
                                                            </div>
                                                            <div class="row mb-2">
                                                                <div class="col-md-4">City/Town</div>
                                                                <div class="col-md-6"> <input type="text"
                                                                        formControlName="TOWNCITY_OF_EMERGENCY"
                                                                        onkeypress="return /[a-a-zA-Z'-'\s]/i.test(event.key)"
                                                                        class="form-control form-control-sm">
                                                                </div>
                                                            </div>



                                                            <div class="col-12 mt-3">
                                                                <a class="float-start btn btn-sm admin-btn btn-sm px-xl-4 px-2 rounded-0 btnPrevious"
                                                                    (click)="previousTab()">Previous</a>
                                                                <a class="float-end btn btn-sm admin-btn btn-sm px-xl-4 px-2 rounded-0 btnNext"
                                                                    (click)="saveNextofkinData(signUpForm)">Save
                                                                    & Next</a>
                                                                <!-- <button type="button"
                                       class="float-end btn btn-sm admin-btn-blue btn-sm `px-xl-4 px-2 rounded-0 me-2"
                                       (click)="save(signUpForm)">Submit
                                       to Admissions</button> -->
                                                            </div>
                                                        </div>
                                                        <div class="card-footer bg-white mt-3 d-none">
                                                            <div style="width: 35%" class="mb-2"><img
                                                                    src="../../../../assets/img/OUK-Admissions-logo.png"
                                                                    class="img-fluid" alt=""></div>
                                                            <p class="small">OUK is a public university
                                                                that
                                                                was established
                                                                and Chartered in 2023. We
                                                                aim
                                                                to provide
                                                                inclusive and flexible high quality
                                                                higher
                                                                education
                                                                opportunities suited for the global
                                                                citizens in the
                                                                21st century. At OUK admissions, we
                                                                recruit
                                                                and enroll
                                                                diverse class of students.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="Review-tab" role="tabpanel" aria-labelledby="Review">
                                    <div class="card border-0 card-shadow h-100">
                                        <div class="card-body assesment pt-0 style-1 ">
                                            <div class="row">
                                                <div class="col-md-12 m-auto">
                                                    <div class="card border-0 card-shadow h-100">
                                                        <div class="card-body ">
                                                            <div class="card_title mb-3 px-2 py-1 text-white">
                                                                <h6 class="mb-0">Review and submit</h6>
                                                            </div>
                                                            <div class="row mb-2">
                                                                <div class="col-md-12">Remember that
                                                                    once
                                                                    you submit your
                                                                    application you cannot make any
                                                                    additional changes or
                                                                    view the application.</div>
                                                            </div>
                                                            <div class="card_title mb-3 px-2 py-1 text-white">
                                                                <h6 class="mb-0 text-center">Review your
                                                                    filled application
                                                                    form here</h6>
                                                            </div>
                                                            <div class="row mb-2">
                                                                <div class="col-md-12 p-4 h4 text-blue text-center"
                                                                    style="background-color: #CBCBCB;">
                                                                    <!-- *ngIf="(aprvId == 0|| aprvId==2 ) && (payId == 0)" -->
                                                                    <button
                                                                        class="btn btn-sm admin-btn-blue btn-sm px-4 rounded-0"
                                                                        (click)="update(signUpForm,2);">Save
                                                                        & Preview</button>
                                                                </div>
                                                            </div>
                                                            <div class="d-flex justify-content-between">
                                                                <!-- *ngIf="aprvId == 3 && payId==2" -->

                                                                <button type="button"
                                                                    class="float-start btn btn-sm admin-btn btn-sm px-xl-4 px-2 rounded-0"
                                                                    (click)="previousTab()">Previous</button>
                                                                <!-- *ngIf="(aprvId == 0|| aprvId==2 ) && (payId == 0)" -->
                                                                <!-- [disabled]="signUpForm.invalid" -->
                                                                <!-- <button type="button"
                                                                class="float-end btn btn-sm admin-btn btn-sm px-xl-4 px-2 rounded-0"
                                                                (click)="makePayment()">Make Payment</button> -->
                                                                <button type="button"
                                                                    class="float-end btn btn-sm admin-btn-blue btn-sm `px-xl-4 px-2 rounded-0 me-2"
                                                                    (click)="update(signUpForm,4)"
                                                                    *ngIf="userDetails?.result?.is_submit!=5 && aprvId != 4">Update
                                                                </button>
                                                                <button type="button" class="btn btn-info btn-sm"
                                                                    (click)="update(signUpForm,8)"
                                                                    *ngIf="userDetails?.result?.is_submit==5 && aprvId == 4">Enroll</button>

                                                            </div>
                                                        </div>
                                                        <div class="card-footer bg-white mt-3 d-none">
                                                            <div style="width: 35%" class="mb-2"><img
                                                                    src="../../../../assets/img/OUK-Admissions-logo.png"
                                                                    class="img-fluid" alt=""></div>
                                                            <p class="small">OUK is a public university
                                                                that
                                                                was established
                                                                and Chartered in 2023. We
                                                                aim
                                                                to provide
                                                                inclusive and flexible high quality
                                                                higher
                                                                education
                                                                opportunities suited for the global
                                                                citizens in the
                                                                21st century. At OUK admissions, we
                                                                recruit
                                                                and enroll
                                                                diverse class of students.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-danger btn-sm" id="updateClose"
                    data-bs-dismiss="modal">Close</button>
                <!-- <button type="button" class="btn btn-info btn-sm" [disabled]="userDetails?.result?.is_submit==3"
                    (click)="update(signUpForm,4)"
                    *ngIf="userDetails?.result?.is_submit!=5 && aprvId != 4">Update</button>
                <button type="button" class="btn btn-info btn-sm" (click)="update(signUpForm,8)"
                    *ngIf="userDetails?.result?.is_submit==5 && aprvId == 4">Enroll</button> -->
            </div>
        </div>
    </div>
</div>


<div class="modal fade" tabindex="-1" data-backdrop="static" data-keyboard="false" id="admissionForm">
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Admission Letter</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body" id="printtable">
                <table id="printtable" width="800" cellspacing="0" cellpadding="0" align="center">
                    <tbody>
                        <tr>
                            <td>
                                <table width="100%" cellspacing="0" cellpadding="0">
                                    <td width="25%">&nbsp;</td>
                                    <td width="25%">&nbsp;</td>
                                    <td width="20%">&nbsp;</td>
                                    <td width="10%" height="20px"
                                        style="border-top: 15px #047B90 solid; vertical-align: top;">&nbsp;</td>
                                    <td width="10%" height="20px"
                                        style="border-top: 15px #ff7f50 solid; vertical-align: top;">&nbsp;</td>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td height="561">
                                <table width="700" style="margin: auto" border="0" cellspacing="10" cellpadding="0">
                                    <tbody>
                                        <tr>
                                            <td width="205" style="text-align: center; padding-bottom: 5px; "
                                                valign="middle"><img src="../../../../assets/img/logo.png" width="100"
                                                    alt=""></td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td style="text-align: center; ">
                                                <h3 style="margin-top: 0; margin-bottom: 0; font-weight: bold;">OFFICE
                                                    OF THE VICE CHANCELLOR</h3>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td style="border-bottom:2px solid #ff7f50; font-size:14px;padding:5px 0">

                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="2" style="padding: 0px 0px ">
                                                <table class="content_t1" width="100%" border="0" cellspacing="0"
                                                    cellpadding="6">
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <h3 style="text-align: center;">CONFIDENTIAL</h3>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>{{userDetails?.result?.offer_date|
                                                                date:'dd-MMMM, yyyy'}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <p style="margin-bottom: 20px; line-height: 1.5;">
                                                                    <b>Your Ref: {{userDetails?.result?.ref_code}}</b>
                                                                    <br><b>{{userDetails?.result?.exam_reg_no==null?"":userDetails?.result?.exam_reg_no}}</b>
                                                                </p>
                                                                <!-- <p style="margin-bottom: 20px; line-height: 1.5;"><b>{{userDetails?.result?.ref_code}}</b></p> -->

                                                            </td>
                                                        </tr>
                                                        <tr *ngIf="userDetails?.result?.registration_number">
                                                            <td>
                                                                <p style="margin-bottom: 20px; line-height: 1.5;"><b>Our Ref/Reg. No:<br />
                                                                    {{ userDetails?.result?.registration_number }}
                                                                </b></p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <p>
                                                                    Dear <b
                                                                        style="text-transform: uppercase;">{{userDetails?.result?.firstname}}
                                                                        {{userDetails?.result?.lastname}},</b>
                                                                </p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <p
                                                                    style="text-transform: uppercase;padding-bottom: 5px;">
                                                                    <b><u> RE: ADMISSION TO THE UNIVERSITY 2023/2024
                                                                            ACADEMIC YEAR</u></b>
                                                                </p>

                                                                <p style="margin-bottom: 15px; line-height: 1.3;">
                                                                    Following your application for admission to this
                                                                    university, I am pleased to
                                                                    inform you that you have been offered admission in
                                                                    the <span
                                                                        style="text-transform: uppercase; font-weight: bold;">{{userDetails?.result?.course_category_name}}
                                                                    </span> for a course
                                                                    leading to the award of <span
                                                                        style="text-transform: uppercase; font-weight: bold;">{{userDetails?.result?.course_name}}</span>
                                                                    under the Open University of Kenya
                                                                    programmes, commenced on <span
                                                                        style=" font-weight: bold;">{{userDetails?.result?.commencement_date
                                                                        |date:'dd-MMMM, yyyy'}}</span></p>
                                                                <p style="margin-bottom: 15px; line-height: 1.3;">On
                                                                    behalf of the University, I
                                                                    welcome you to the
                                                                    innovative university for inclusive prosperity
                                                                    committed to provide quality learning experiences
                                                                    which
                                                                    enable you to acquire
                                                                    knowledge, skills and values in your chosen field of
                                                                    study as well as nurture you into an
                                                                    innovative researcher, leader, entrepreneur and a
                                                                    global citizen.</p>
                                                                <p style="margin-bottom: 15px; line-height: 1.3;">This
                                                                    offer is made on the basis of the statement of your
                                                                    qualifications as
                                                                    contained in the copies of certificates that you
                                                                    supplied along with your
                                                                    application for admission and is therefore subject
                                                                    to satisfactory verification by
                                                                    the University. Any information found to be untrue
                                                                    will automatically lead to
                                                                    nullification of this offer.</p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <p>The offer is also subject to the following
                                                                    conditions:</p>
                                                                <p>&nbsp;</p>
                                                                <ol type="A"
                                                                    style="margin: 5px 0px;padding-left: 1.5rem;">
                                                                    <li>Your undertaking to adhere to the Rules and
                                                                        Regulations, governing
                                                                        studentship of the Open University.</li>
                                                                    <li>Your acceptance to pay fees in accordance with
                                                                        the Open University of
                                                                        Kenya Statute and;</li>
                                                                    <li>Your promise to abide by the terms and
                                                                        conditions set out in the attached
                                                                        declaration for Admission </li>

                                                                </ol>
                                                                <p>&nbsp;</p>
                                                                <p>Fees is payable in full on or before commencement of
                                                                    each semester or course. All fee downpayment shall
                                                                    be made upon acceptance of admission in the portal.
                                                                    Subsequent fee payments can be made through the Fee
                                                                    accounts menu in the student portal by following the
                                                                    steps as follows: -Click Pay now - Choose Payment
                                                                    service – Tuition Fee - Choose payment modes -Pay.
                                                                </p>



                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <h6>TUITION FEE {{userDetails?.get_cluster?.BANK_CODE}}.
                                                                </h6>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <p>The fee payment receipts will reflect in the student
                                                                    portal.</p><br>
                                                                <p>If you require government financial support you MUST
                                                                    make an application for consideration through the
                                                                    official scholarship and loan application portal,
                                                                    www.hef.co.ke. Should the government scholarship,
                                                                    loan and bursary not be sufficient to cover the
                                                                    entire cost of your programme the remaining fees
                                                                    balance will be the responsibility of your
                                                                    parent/guardian.</p><br>
                                                                <p>Please accept this offer and complete registration
                                                                    available under  Studentship - My Student Registration tab available at  <a
                                                                    href="https://my.ouk.ac.ke/">https://my.ouk.ac.ke/</a>
                                                                    I take this
                                                                    opportunity to congratulate you on your admission to
                                                                    this University and wish you success in your
                                                                    academic pursuit.</p>
                                                            </td>
                                                        </tr>
                                                        <!-- <tr>
                                                            <td style="text-align: center; font-size: 15px;">
                                                                <p>OUK FEES COLLECTION
                                                                    {{userDetails?.get_cluster?.BANK_CODE}}, Equity
                                                                    Bank, Machakos
                                                                    Branch, A/C No
                                                                    <b>{{userDetails?.get_cluster?.ACCOUNT_NO}}</b>
                                                                </p>
                                                            </td>
                                                        </tr> -->
                                                        <!-- <tr>
                                                            <td>
                                                                <p>Student shall retain the banking slips as proof of
                                                                    payment.</p>
                                                            </td>
                                                        </tr> -->
                                                        <!-- <tr>
                                                            <td>
                                                                <p>
                                                                    If you require government financial support you MUST
                                                                    make an application for consideration through the
                                                                    official scholarship and loan application portal, <a
                                                                        href="https://www.hef.co.ke/"
                                                                        target="_blank">www.hef.co.ke</a> . Should the
                                                                    government scholarship, loan and bursary not be
                                                                    sufficient to cover the entire cost of your
                                                                    programme the remaining fees balance will be the
                                                                    responsibility of your parent/guardian.
                                                                </p>
                                                            </td>
                                                        </tr> -->
                                                        <!-- <tr>
                                                            <td>
                                                                <p>
                                                                   


                                                                    Please accept this offer and complete registration
                                                                    available under My Enrollment tab available at <a
                                                                        href="https://admissions.ouk.ac.ke/login"
                                                                        target="_blank">https://admissions.ouk.ac.ke/</a>

                                                                </p>
                                                            </td>
                                                        </tr> -->
                                                        <!-- <tr>
                                                            <td>

                                                                <p style="margin-bottom: 15px; line-height: 1.3;">I take
                                                                    this opportunity to congratulate you on your
                                                                    admission to this University and wish you success in
                                                                    your academic pursuit.</p>
                                                            </td>
                                                        </tr> -->
                                                        <tr>
                                                            <td>&nbsp;</td>
                                                        </tr>
                                                        <tr>
                                                            <td>&nbsp;</td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <table width="100%">
                                                                    <tr>
                                                                        <td> <img style="width: 150px;  "
                                                                                src="../../../../assets/img/sig.jpeg">
                                                                        </td>
                                                                        <td></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style="border-bottom:2px solid #ff7f50; font-size:14px;padding:5px 0; "
                                                                            width="50%">

                                                                        </td>
                                                                        <td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td colspan="2"
                                                                            style="text-transform: uppercase; "><b>PROF.
                                                                                ELIJAH I. OMWENGA</b></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td colspan="2"
                                                                            style="text-transform: uppercase"><b>VICE
                                                                                CHANCELLOR</b></td>
                                                                    </tr>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>

                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <table width="100%"
                                                    style="font-size: 11px; font-weight: 700; line-height: 1.5;">
                                                    <tr>
                                                        <td width="30%" style="vertical-align: bottom;">
                                                            <table width="100%" cellspacing="0" cellpadding="0">
                                                                <td width="35%" height="20px"
                                                                    style="border-bottom: 15px #047B90 solid; vertical-align: top;">
                                                                    &nbsp;</td>
                                                                <td width="35%" height="20px"
                                                                    style="border-bottom: 15px #ff7f50 solid; vertical-align: top;">
                                                                    &nbsp;</td>
                                                                <td width="25%" height="20px">&nbsp;</td>
                                                            </table>
                                                        </td>
                                                        <td width="35%"
                                                            style="border-left: 7px #ff7f50 solid; vertical-align: top;">
                                                            <table style="margin: 0px 5px; width: 100%;">
                                                                <tr>
                                                                    <td><img src="../../../../assets/img/f-icon1.png"
                                                                            style="width: 15px; height: auto" alt="">
                                                                    </td>
                                                                    <td>
                                                                        <p>Konza Technopolis<br />P.O. Box 2440-00606
                                                                            Nairobi </p>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td><img src="../../../../assets/img/f-icon2.png"
                                                                            style="width: 15px; height: auto" alt="">
                                                                    </td>
                                                                    <td>
                                                                        <p>@ OpenUniKenya</p>
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </td>
                                                        <td width="35%"
                                                            style="border-left: 7px #037b90 solid; vertical-align: top;">
                                                            <table style="margin: 0px 5px; width: 100%;">
                                                                <tr>
                                                                    <td><img src="../../../../assets/img/f-icon3.png"
                                                                            style="width: 15px; height: auto" alt="">
                                                                    </td>
                                                                    <td><a style="text-decoration: none; color: #000;"
                                                                            href="mailto:admissions@ouk.ac.ke">admissions@ouk.ac.ke</a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td><img src="../../../../assets/img/f-icon5.png"
                                                                            style="width: 15px; height: auto" alt="">
                                                                    </td>
                                                                    <td><a style="text-decoration: none; color: #000;"
                                                                            href="https://ouk.ac.ke">https://ouk.ac.ke</a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td style="vertical-align: middle;"><img
                                                                            src="../../../../assets/img/f-icon4.png"
                                                                            style="width: 15px; height: auto" alt="">
                                                                    </td>
                                                                    <td style="vertical-align: middle;">
                                                                        <p>@ OpenUniKenya</p>
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                </table>

                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>

                    </tbody>
                </table>

                <table class="pt-2" width="800" cellspacing="0" cellpadding="0" align="center">
                    <tbody>
                        <tr>
                            <td>
                                <table width="100%" cellspacing="0" cellpadding="0">
                                    <td width="25%">&nbsp;</td>
                                    <td width="25%">&nbsp;</td>
                                    <td width="20%">&nbsp;</td>
                                    <td width="10%" height="20px"
                                        style="border-top: 15px #047B90 solid; vertical-align: top;">&nbsp;</td>
                                    <td width="10%" height="20px"
                                        style="border-top: 15px #ff7f50 solid; vertical-align: top;">&nbsp;</td>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td height="561">
                                <table width="700" style="margin: auto" border="0" cellspacing="10" cellpadding="0">
                                    <tbody>
                                        <tr>
                                            <td width="205" style="text-align: center; padding-bottom: 12px;"
                                                valign="middle"><img src=" ../../../../../../assets/img/logo.png"
                                                    width="100" alt=""></td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td style="text-align: center; ">
                                                <h5 style="margin-top: 0; margin-bottom: 0; font-weight: bold;">
                                                    PROGRAMMES FEE STRUCTURE FOR ACADEMIC
                                                    YEAR 2023/2024</h5>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td style="border-bottom:2px solid #ff7f50; font-size:14px;padding:5px 0">

                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="2" style="padding: 15px 0px ">
                                                <table class="content_t1" width="100%" border="0" cellspacing="0"
                                                    cellpadding="6">
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <h4>I. FEE STRUCTURE</h4>

                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <p style="margin-bottom: 20px; line-height: 1.5;">The
                                                                    annual fee for the undergraduate degrees
                                                                    and postgraduate diploma programmes will be as
                                                                    follows:</p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <table class="tbl" style="width:100%">

                                                                    <tr>
                                                                        <td>1</td>
                                                                        <td>Bachelor of Data Science</td>
                                                                        <td style="font-weight:bold">KES 105,000</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>2</td>
                                                                        <td>Bachelor of Science in Cyber Security and
                                                                            Digital Forensics</td>
                                                                        <td style="font-weight:bold">KES 105,000</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>3</td>
                                                                        <td>Bachelor of Technology Education</td>
                                                                        <td style="font-weight:bold">KES 115,000</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>4</td>
                                                                        <td>Bachelor of Business and Entrepreneurship
                                                                        </td>
                                                                        <td style="font-weight:bold">KES 105,000</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>5</td>
                                                                        <td>Bachelor of Economics and Statistics</td>
                                                                        <td style="font-weight:bold">KES 105,000</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>6</td>
                                                                        <td>Post Graduate Diploma in Learning Design and
                                                                            Technology</td>
                                                                        <td style="font-weight:bold">KES 110,000</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>7</td>
                                                                        <td>Post Graduate Diploma in Leadership and
                                                                            Accountability</td>
                                                                        <td style="font-weight:bold">KES 110,000</td>
                                                                    </tr>

                                                                </table>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td>&nbsp;</td>
                                                        </tr>
                                                        <tr>
                                                            <td>&nbsp;</td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <h4>II. GOVERNMENT FINANCIAL SUPPORT</h4>

                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <p style="margin-bottom: 20px; line-height: 1.5;">If you
                                                                    require government financial support you MUST make
                                                                    an application for consideration through the
                                                                    official scholarship and loan application portal, <a
                                                                        href="https://www.hef.co.ke/"
                                                                        target="_blank">www.hef.co.ke</a> . Should the
                                                                    government scholarship, loan and bursary not be
                                                                    sufficient to cover the entire cost of your
                                                                    programme the remaining fees balance will be the
                                                                    responsibility of your parent/guardian.</p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <h4>III. IMPORTANT NOTES AND FEE PAYMENT</h4>

                                                            </td>
                                                        </tr>
                                                        <!-- <tr>
                                        <td>
                                          <p style="margin-bottom: 20px; line-height: 1.5;"></p>
                                        </td>
                                      </tr> -->

                                                        <tr>
                                                            <td>
                                                                <ol>
                                                                    <li>The fee structure can be varied at the sole
                                                                        discretion of the university.</li>
                                                                    <li>Students outside East Africa will pay more by
                                                                        10%.</li>
                                                                    <!-- <li>Students are further advised that tuition fees
                                                                        are payable to:</li> -->
                                                                    <li>Students are further advised that tuition fees
                                                                        are payable through the e-citizen system by
                                                                        below services:</li>
                                                                </ol>

                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <ul>
                                                                    <li>TUITION FEE GSSP, for KUCCPS Placed students OR
                                                                    </li>
                                                                    <li>TUITION FEE PSSP, for Privately Sponsored
                                                                        students.</li>
                                                                    <!-- <li>OUK FEES COLLECTION GSSP, Equity Bank, Machakos
                                                                        Branch, A/C No 0600284659950 for KUCCPS Placed
                                                                        students OR</li>
                                                                    <li>OUK FEES COLLECTION PSSP, Equity Bank, Machakos
                                                                        Branch, A/C No 0600284659964 for Privately
                                                                        Sponsored students.</li> -->
                                                                </ul>

                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <p>The fee payment receipts will reflect in the student
                                                                    portal under the Fee account menu.</p>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <table width="100%"
                                                    style="font-size: 11px; font-weight: 700; line-height: 1.5;">
                                                    <tr>
                                                        <td width="30%" style="vertical-align: bottom;">
                                                            <table width="100%" cellspacing="0" cellpadding="0">
                                                                <td width="35%" height="20px"
                                                                    style="border-bottom: 15px #047B90 solid; vertical-align: top;">
                                                                    &nbsp;</td>
                                                                <td width="35%" height="20px"
                                                                    style="border-bottom: 15px #ff7f50 solid; vertical-align: top;">
                                                                    &nbsp;</td>
                                                                <td width="25%" height="20px">&nbsp;</td>
                                                            </table>
                                                        </td>
                                                        <td width="35%"
                                                            style="border-left: 7px #ff7f50 solid; vertical-align: top;">
                                                            <table style="margin: 0px 5px; width: 100%;">
                                                                <tr>
                                                                    <td><img src="../../../../../../assets/img/f-icon1.png"
                                                                            style="width: 15px; height: auto" alt="">
                                                                    </td>
                                                                    <td>
                                                                        <p>Konza Technopolis<br />P.O. Box 2440-00606
                                                                            Nairobi</p>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td><img src="../../../../../../assets/img/f-icon2.png"
                                                                            style="width: 15px; height: auto" alt="">
                                                                    </td>
                                                                    <td>
                                                                        <p>@ OpenUniKenya</p>
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </td>
                                                        <td width="35%"
                                                            style="border-left: 7px #037b90 solid; vertical-align: top;">
                                                            <table style="margin: 0px 5px; width: 100%;">
                                                                <tr>
                                                                    <td><img src="../../../../../../assets/img/f-icon3.png"
                                                                            style="width: 15px; height: auto" alt="">
                                                                    </td>
                                                                    <td><a style="text-decoration: none; color: #000;"
                                                                            href="mailto:admissions@ouk.ac.ke">admissions@ouk.ac.ke</a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td><img src="../../../../../../assets/img/f-icon5.png"
                                                                            style="width: 15px; height: auto" alt="">
                                                                    </td>
                                                                    <td><a style="text-decoration: none; color: #000;"
                                                                            href="https://ouk.ac.ke">https://ouk.ac.ke</a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td style="vertical-align: middle;"><img
                                                                            src="../../../../../../assets/img/f-icon4.png"
                                                                            style="width: 15px; height: auto" alt="">
                                                                    </td>
                                                                    <td style="vertical-align: middle;">
                                                                        <p>@ OpenUniKenya</p>
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                </table>

                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>

                    </tbody>
                </table>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-danger btn-sm" id="admsionForm"
                    data-bs-dismiss="modal">Close</button>&nbsp;&nbsp;
                <button class="btn btn-save btn-sm" (click)="downloadForm('admissionletter')"> Download</button>
            </div>
        </div>
    </div>
</div>