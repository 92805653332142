import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SidebarStateService } from 'src/app/services/sidebar-state.service';
import { CommonService } from 'src/app/services/common.service';
import { ChatService } from 'src/app/services/chat.service';
import { environment } from 'src/environments/environment';
declare var $: any;
declare const closeAllSockets: any;
declare function googleTranslate(): any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  @Output() superMaduleEvent = new EventEmitter<any>();
  userName: string = localStorage.getItem('Username')?.toUpperCase();
  roleName: string = localStorage.getItem('RoleName');
  applicantId = localStorage.getItem('IsApplicant');
  table: Array<any> = [];
  viewMsg: Array<any> = [];
  message: Array<any> = [];
  levelUpdate: any;
  notifications: Array<any> = [];
  notification: any;
  viewNoti: Array<any> = [];
  OPERATION: any;
  ROLEID = localStorage.getItem('RoleId');
  myForm: FormGroup;
  oldPassword: any;
  chatMessage: any = ''
  chatMessagesList: any = [];
  differnceTime: any;
  startTime: any = new Date();
  endtime: any = new Date();
  patient_id: any;
  existingsname: any;
  studentArray: any[];
  student_id: any;
  property: string;
  TeachersCount: any;
  onlineTeachers: any;
  notificationCount: any;
  loginDetails: any = {
    member: 'Student',
    trainer: 'Lecturer',
    admin: 'Admin',
    admin1: 'Administrator L1',
    admin2: 'Administrator L2',
    applicantMember: 'Applicant'
  }
  isExpanded: boolean;
  isWaterAnimationActive: boolean = false;
  userDetails: any;
  url: string = environment.videoUrl;
  roles: Array<any> = [];
  selectRole: string = '';
  selectedModule: any = "1"

  constructor(private route: Router, private chatService: ChatService, private CommonService: CommonService, private fb: FormBuilder, private toastr: ToastrService, private sidebarStateService: SidebarStateService) {

    this.ROLEID = atob(this.ROLEID);
    this.sidebarStateService.isExpanded$.subscribe(isExpanded => {
      this.isExpanded = isExpanded;
    });

    this.loadMessage();
    // this.loadNotification();
    this.getOldPassword();
    if (this.roleName == 'Member') {
      if (this.applicantId === '1') {
        this.roleName = this.loginDetails.applicantMember
      } else {
        this.roleName = this.loginDetails.member
      }
    } else if (this.roleName == 'Trainer') {
      this.roleName = this.loginDetails.trainer;
    } else if (this.roleName == 'Admin') {
      this.roleName = this.loginDetails.admin;
    } else if (this.roleName == 'Academic L1') {
      this.roleName = this.loginDetails.admin1;
    } else if (this.roleName == 'Academic L2') {
      this.roleName = this.loginDetails.admin2;
    }
  }

  ngOnInit(): void {
    this.myForm = this.fb.group({
      oldPassword: ['', [Validators.required, Validators.minLength(6)]],
      password: ['', [Validators.required, Validators.minLength(6), Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(6)]],
    })
    this.selectedModule = atob(localStorage.getItem("RoleId")) || "1";
    this.superMaduleEvent.emit(this.selectedModule);
    // googleTranslate();
    // this.getOnlineTeachers(+localStorage.getItem('TenantCode'));
    this.loadingToggled(); this.getUserProfile();
    this.getRoles();
    setTimeout(() => {

      //$(".menu-toggle").click(function (e) {
      // alert("d")


      // var left: any = document.getElementsByClassName('topbar');
      // left = left[0].style.left;
      // e.preventDefault();
      // $(".wrapper").toggleClass("toggled");
      // (left == "250px" || left == "") ? $(".page-wrapper").css({ "left": "70px" }) : $(".page-wrapper").css({ "left": "250px" });

      // (left == "250px" || left == "") ? $(".topbar").css({ "left": "70px" }) : $(".topbar").css({ "left": "250px" });
      // })
    }, 100);

  }

  getUserProfile() {
    this.CommonService.postCall('Registration/GetAudioPath', {
      "USERID": localStorage.getItem('UserId'),
      "COURSE_ID": localStorage.getItem('CourseId')
    }).subscribe((res: any) => {
      this.userDetails = res;

    })
  }

  toggleIsExpanded() {
    this.sidebarStateService.toggleIsExpanded();
    if (this.isExpanded) {
      this.isWaterAnimationActive = false;
    } else {
      this.isWaterAnimationActive = true;
    }
  }

  loadingToggled() {

    $(function () {
      "use strict";


      $(".mobile-toggle-menu").on("click", function () {
        $(".body-wrapper").addClass("toggled");
      });

      // toggle menu button
      $(".toggle-icon").click(function (e) {
        e.preventDefault();
        // alert();
        if ($(".body-wrapper").hasClass("toggled")) {
          // unpin sidebar when hovered
          $(".body-wrapper").removeClass("toggled");
          $(".sidebar-wrapper").unbind("hover");
        } else {
          $(".body-wrapper").addClass("toggled");
          $(".sidebar-wrapper").hover(function () {
            $(".body-wrapper").addClass("sidebar-hovered");
          }, function () {
            $(".body-wrapper").removeClass("sidebar-hovered");
          })
        }
      });

    });

  }

  Signout() {
    this.timeTracker();
    this.chatService.setMessages(null);
    this.clearToken();
    setTimeout(() => {
      localStorage.clear();
      sessionStorage.clear();
      // closeAllSockets();
      // this.chatService.closeWebSocket();
      this.route.navigate(['/login']);
    }, 500);
  }

  clearToken() {
    this.CommonService.postCall('Account/ClearToken', { "UserID": localStorage.getItem('UserId') }).subscribe((res: any) => {

    })
  }
  menuClick() {
    $('#sidebar').toggleClass('slide');
  }
  loadMessage() {
    this.notifications = [];
    if (this.ROLEID == '1') {
      this.OPERATION = 'GETNOTIFICATIONS'
    } else if (this.ROLEID == '2') {
      this.OPERATION = 'TRAINERNOTIFICATIONS'
    } else if (this.ROLEID == '3') {
      this.OPERATION = 'STUDENTNOTIFICATIONS'
    }

    this.CommonService.getCall(`Notification/GetNotification/${this.OPERATION}/${localStorage.getItem('TenantCode')}/${localStorage.getItem('UserId')}`).subscribe((res: any) => {
      // this.table = [];
      this.notification = res;
    }, e => { console.log(e) })
  }

  loadNotification() {
    this.notification = [];
    this.CommonService.getCall(`EmsAlerts/GetNotificationsByUser/${localStorage.getItem('UserId')}`).subscribe((res: any) => {
      this.notification = res?.lstt;
      this.notificationCount = res;
    })
  }

  viewNotification(event: any) {
    this.viewNoti = [];
    this.viewNoti = +event == 0 ? this.notification : this.notification.filter(e => e.ID == event);

    this.CommonService.getCall(`Notification/UpdateNotification/UPDATE/${event}`).subscribe((res: any) => {

    })
    let payload = {
      "user_id": localStorage.getItem('UserId'),
      "notification_id": event,
      "is_read": true,
      "tnt_code": localStorage.getItem('TenantCode'),
      "createdby": localStorage.getItem('UserId'),
      "modifiedby": localStorage.getItem('UserId')
    }
    this.CommonService.postCall('EmsAlerts/CreateReadMessages', payload).subscribe((res: any) => {

    })
  }

  // get online Teachers 27-04-2023
  getOnlineTeachers(TenantCode: number) {
    [this.TeachersCount, this.onlineTeachers] = [0, []];
    // this.CommonService.getData(`${this.WebSocket_URL}telecon/doctorstatus/getall/?TENANT_ID=${TenantCode}`).subscribe(res => {
    this.CommonService.getData(`telecon/doctorstatus/getall/?TENANT_ID=${TenantCode}`).subscribe(res => {
      [this.onlineTeachers, this.TeachersCount] = [res, res.length];
    });
  }
  // end of get online Teachers 
  viewMessage(event: any) {
    this.viewNoti = [];
    this.viewNoti = +event == 0 ? this.notification : this.notification.filter(e => e.ID == event);
  }

  delete(id: any, type: any, notificationid: any) {
    if (id) {
      type = 2;
    }
    else if (notificationid) {
      type = 1;
    }
    return
    this.CommonService.getCall(`Notification/DeleteNotification/${id}/${type}`).subscribe((res: any) => {

    })
  }

  close() {
    this.getUnViewdNotificationCount();
    this.loadMessage();
    // window.location.reload();
  }

  close1() {
    this.myForm.reset();
  }

  getOldPassword() {
    this.CommonService.postCall(`Registration/GetOldPassWord`, { "userid": localStorage.getItem('UserId') }).subscribe((res: any) => {
      this.oldPassword = res.password;
    })
  }


  passwordCheck() {
    let pControl = this.myForm.controls['password'];
    let cControl = this.myForm.controls['confirmPassword'];
    if (!pControl.value) {
      this.toastr.warning('Please enter the password');
      cControl.setValue(null);
      return;
    }
    if (pControl.value != cControl.value) {
      this.toastr.warning('New Password and Confirm Password Should be same');
      cControl.setValue(null);
    }
  }

  ChangePass(form: any) {
    let value = form.value;
    let payload = {
      "userid": localStorage.getItem('UserId'),
      "password": value.password
    }
    if (this.oldPassword == value.oldPassword) {
      this.CommonService.postCall(`Registration/UpdateUserPassWord`, payload).subscribe((res: any) => {
        this.toastr.success("Password Changed Successfully");
        document.getElementById('passPopupClose').click();
        this.Signout();
      }, err => {
        this.toastr.error(err.error ? err.error : 'Password Not Changed');
      })
    } else {
      this.toastr.warning('Old Password is Incorrect');
    }
  }

  getUnViewdNotificationCount1() {
    return this.table.filter(m => m.IsRead == false).length;
  }

  // getUnViewdNotificationCount() {
  //   return this.notification.filter(m => m.notification_read_status == 0).length;
  // }
  getUnViewdNotificationCount() {
    return this.notification?.length;
  }

  apply() {
    document.getElementById('Noti_close')?.click();
  }

  userManual() {
    https://openuni.samvaadpro.com/home/userManual/Student_Process_Flow.docx
    window.open(`${environment.videoUrl}/home/userManual/Student_Process_Flow.docx`);
    // let adminUrl = `${location.origin}/home/userManual/Student_Process_Flow.docx`;
    // let trainerUrl = `${location.origin}/home/userManual/Student_Process_Flow.docx`;
    // let memberUrl = `${location.origin}/home/userManual/Student_Process_Flow.docx`;
    // if (this.ROLEID == '1') {
    //   window.open(adminUrl, '_blank');
    // } else if (this.ROLEID == '2') {
    //   window.open(trainerUrl, '_blank');
    // } else if (this.ROLEID == '3') {
    //   window.open(memberUrl, '_blank');
    // } else {
    //   this.toastr.warning('There is no user manual');
    // }
  }

  profileNavigate(role: any) {
    let id = localStorage.getItem('UserId');
    sessionStorage.isTabsDisabled = false;
    this.CommonService.postCall('EditRegistrationByUserId', { CREATEDBY: id }).subscribe(
      (res: any) => {
        if (role == 'admin') {
          this.route.navigate(['HOME/registration/editUserRegistration'], { queryParams: { token: res.value, wId: 64, rId: 2071 } })
        } else {
          // this.route.navigate(['HOME/registration/editRegistrationForm'])
          this.route.navigate(['HOME/registration/profile']);
        }
      }, err => {
      }
    )

  }

  viewMessages(event: any) { }
  viewNotifications(event: any) { }

  timeTracker() {
    this.startTime = localStorage.getItem('loginTime');
    let Sdate: any = new Date(this.startTime);
    const timestamp = Sdate.toLocaleTimeString();

    this.endtime = new Date();
    this.getDataDiff(Sdate, this.endtime);

    let payload =
    {
      "loginaudit_userid": localStorage.getItem('UserId'),
      "loginaudit_login_intime": timestamp,
      "loginaudit_login_outtime": this.endtime.toLocaleTimeString(),
      "loginaudit_login_timespent": (`${this.differnceTime.hour}:${this.differnceTime.minute}:${this.differnceTime.second}`),
      "loginaudit_createdby": localStorage.getItem('UserId'),
      "tnt_code": localStorage.getItem('TenantCode')
    }
    this.CommonService.postCall('LoginAudit/CreateAudit', payload).subscribe((res: any) => { })
  }

  getDataDiff(startDate: any, endDate: any) {
    let diff = endDate.getTime() - startDate.getTime();
    let days = Math.floor(diff / (60 * 60 * 24 * 1000));
    let hours = Math.floor(diff / (60 * 60 * 1000)) - (days * 24);
    let minutes = Math.floor(diff / (60 * 1000)) - ((days * 24 * 60) + (hours * 60));
    let seconds = Math.floor(diff / 1000) - ((days * 24 * 60 * 60) + (hours * 60 * 60) + (minutes * 60));
    this.differnceTime = { day: days, hour: hours, minute: minutes, second: seconds };
  }


  //chat implementation 

  // sendMessage() {
  //   this.chatMessagesList.push(this.chatMessage);
  //   this.chatMessage = '';
  // }


  getRoles() {
    let payload = {
      "USERID": localStorage.getItem('UserId'),
      "TENANTCODE": localStorage.getItem('TenantCode'),
      // "ROLEID": atob(localStorage.getItem('RoleId'))
    }
    this.CommonService.postCall('Registration/LoadRolesDashboard', payload).subscribe((res: any) => {
      this.roles = res;
    })
  }
  changeRoles(event: any) {
    let val = event.target.value;
    let payload = {
      "USERID": localStorage.getItem('UserId'),
      "TENANTCODE": localStorage.getItem('TenantCode'),
      "ROLEID": val
    }
    this.CommonService.postCall('Registration/LoadRolesByUser', payload).subscribe((res: any) => {
      // let userData: any = this.roles.find(e => e.ROLE_ID == val);
      let userData: any = res[0];
      localStorage.setItem("RoleId", btoa(val));
      localStorage.setItem('SchoolId', userData.CATEGORY_ID);
      localStorage.setItem('DepartmentId', userData.DEPARTMENT_ID);
      localStorage.setItem('TYPE', userData.TYPE);
      localStorage.setItem('RoleName', userData.ROLE_NAME);
      this.roleName = userData.ROLE_NAME;
      this.superMaduleEvent.emit(val);
      this.route.navigate(['/HOME'], { queryParams: { req: val } });
    }, (err: any) => {
      this.toastr.error('Failed!');
    })

  }
}
