import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from '../base.component';
@Component({
  selector: 'app-student-available-programs',
  templateUrl: './student-available-programs.component.html',
  styleUrls: ['./student-available-programs.component.css']
})
export class StudentAvailableProgramsComponent extends BaseComponent implements OnInit {

  roleId: any = localStorage.getItem('RoleId');
  isShow: any = localStorage.getItem('UserBaseSidebarMenu');
  refreshed: boolean = false;
  reload: string = '';
  constructor(CommonService: CommonService, private route: Router, toastr: ToastrService, private active: ActivatedRoute, private router: Router) {
    super(CommonService, toastr);
    this.roleId = atob(this.roleId);
    this.active.queryParams.subscribe((res: any) => {
      this.reload = res.reload;
    })
  }

  ngOnInit(): void {
    if (this.reload == 'reg') {
      this.refresh();
    }
  }
  redirectToReg(){
    return  this.route.navigate(['/HOME/components/prnlDetailsRegForm'])
  }
  refresh() {
    // window.location.reload();
    if (!sessionStorage.getItem('refreshed')) {
      sessionStorage.setItem('refreshed', 'true');
      window.location.reload();
    }
  }
}
