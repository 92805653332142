import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import html2canvas from 'html2canvas';
import jspdf from 'jspdf';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-application-form',
  templateUrl: './application-form.component.html',
  styleUrls: ['./application-form.component.css']
})
export class ApplicationFormComponent implements OnInit {
  @Output() closeModal = new EventEmitter<boolean>();
  // @Input() UserId = '';

  userDetails: any;
  constructor(private CommonService: CommonService) { }

  ngOnInit(): void {
    this.getUserDetails();
  }
  getUserDetails() { 
    this.CommonService.postCall('Registration/GetAudioPath', { "USERID": localStorage.getItem('UserId') }).subscribe((res: any) => {
      this.userDetails = res;
    })

  }
  downloadForm() {
    this.CommonService.activateSpinner();
    var data: any = document.getElementById('printtable');
    html2canvas(data).then((canvas: any) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jspdf('p', 'mm', [297, 210]);
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      const contentDataURL = canvas.toDataURL('image/png')
      var position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, 0, pdfWidth, pdfHeight)
      pdf.save('ApplicationForm.pdf'); // Generated PDF
      this.CommonService.deactivateSpinner();
      setTimeout(() => {
        document.getElementById('md_close')?.click();
        this.closeModal.emit(true);
      }, 2000);
    });

  }

}
