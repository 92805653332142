import { Component, Input, OnInit, Renderer2, ViewChild, ChangeDetectorRef } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { CommonService } from "../services/common.service";
import { DomSanitizer } from "@angular/platform-browser";
import { environment } from "src/environments/environment";
import { SidebarStateService } from "../services/sidebar-state.service";
import { ToastrService } from "ngx-toastr";
import { MenuService } from "../services/menu.service";
import { filter } from "rxjs/operators";
import { MultiSelectComponent } from 'ng-multiselect-dropdown';
const url = environment.fileUrl;
declare var onloadfn: any;
declare var loadCr: any;
declare var $: any;
@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.css"],
})
export class SidebarComponent implements OnInit {
  @ViewChild('dropdown', { static: false }) dropdown: MultiSelectComponent;
  roleId: any = localStorage.getItem("RoleId") || 3;
  tenantCode: any = localStorage.getItem("TenantCode");
  menus: Array<any> = [];
  clientLogo: any;
  isLogo: boolean = false;
  url: any;
  selectedMenuItem: any = "Dashboard"; // Initialize with 'Dashboard' or the default active item
  lastSelectedMenuItem: any = null; // Initialize as null
  isShow: any = localStorage.getItem("UserBaseSidebarMenu");
  isSubmit: any = localStorage.getItem("isStudent");
  isExpanded: boolean;
  currentMenu: any;
  allChilds: any[] = [];
  dropdownSettings: any = {}
  selectedMenu: any = [];
  @Input() sMaduleId: any = 1;

  constructor(
    private renderer: Renderer2, private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private router: Router,
    private service: CommonService,
    private sanitizer: DomSanitizer,
    private sidebarStateService: SidebarStateService,
    private toastr: ToastrService,
    private menuService: MenuService
  ) {
    this.loadScript();
    // this.getMenu();

    this.sidebarStateService.isExpanded$.subscribe((isExpanded) => {
      this.isExpanded = isExpanded;
    });

    this.roleId = atob(this.roleId);
    if (localStorage.getItem("Logo") == "") {
      this.isLogo = true;
    } else {
      this.isLogo = false;
    }
  }
  ngOnChanges(changes: any): void {
    if (+changes.sMaduleId.currentValue) {
      this.getMenu();
    }
    this.dropdownSettings = {
      singleSelection: true,
      idField: 'path',
      textField: 'Name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      enableCheckAll: false
    };
  }
  ngOnInit(): void {
    this.url = window.location.pathname;
    this.clientLogo = this.sanitizer.bypassSecurityTrustResourceUrl(
      `data:image/png;base64, ${localStorage.getItem("Logo")}`
    );
    if (this.sMaduleId !== 1) {
      this.getMenu();
      this.sMaduleId = localStorage.getItem("RoleId");
    }
  }
  ngAfterViewInit(): void {
    // The dropdown will be available here
  }
  getClass(name) {
    return "mdi " + name + " menu-icon";
  }
  loadScript() {
    const script = this.renderer.createElement("script");
    script.src = "assets/plugins/metismenu/js/metisMenu.min.js";
    script.async = true;
    script.defer = true;
    this.renderer.appendChild(document.body, script);
  }
  navigate(path, menu) {
    if (menu.Name === "Dashboard") {
      // Only set the last selected item if it's not the Dashboard
      if (this.selectedMenuItem !== "Dashboard") {
        this.lastSelectedMenuItem = this.selectedMenuItem;
      }
    }
    this.selectedMenuItem = menu.Name;
    localStorage.setItem("menuName", this.selectedMenuItem);
    // Handle navigation logic here

    if (!menu.childs?.length && path) {
      let url = this.url.replaceAll("/HOME/", "");

      let findPath = JSON.stringify(this.menus).includes(path || url);
      if (findPath) {
        this.router.navigate(["HOME/" + path]);
        this.closeLeftMenuforMobile();
        return;
      } else {
        alert(12);
      }
    }
  }
  navigateToOuk() {
    // window.open('https://ouk.ac.ke/')
    this.router.navigate(["/HOME/dashboard"]);
  }
  updateCurrentMenu(url: string): void {
    let currentUrl = url.replace("/HOME/", "");
    this.currentMenu = this.findParentObject(this.menus, currentUrl);
    // console.log(this.currentMenu,'current Menu',currentUrl);
  }
  findParentObject(menus: any, currentPath: any): any {
    const paths = [
      'registration/editUserRegistration',
      'registration/profile',
      'registration/editRegistrationForm',
      'components/regForm'
    ];
    if (currentPath === "dashboard") {
      return "Dashboard";
    }
    else if (currentPath.includes('components/payment')) {
      return "Fees Accounts"
    }
    else if (paths.some(path => currentPath.includes(path))) {
      return "Personal Info";
    }
    else if (currentPath === "components/prnlDetailsRegForm") {
      return "Personal Details Registration";
    }
    else if (currentPath === "myApplication") {
      return "My Application";
    }

    for (const menu of menus) {
      // Check if the current menu path matches the current path
      if (menu.path === currentPath) {
        return menu.Name;
      }
      if (menu.childs && menu.childs.length > 0) {
        for (const child of menu.childs) {
          if (child.path === currentPath) {
            return menu.Name;
          } else if (child.isParent && child.childs.length > 0) {
            const result = this.findParentObject(child.childs, currentPath);
            if (result) {
              return menu.Name;
            }
          }
        }
      }
    }
    return null;
  }
  getMenu() {
    let applicantId = localStorage.getItem("IsApplicant");
    let payLoad = {
      Roleid_base64string:
        applicantId == "0" || applicantId == "2"
          ? localStorage.getItem("RoleId")
          : localStorage.getItem("ApplicantRoleId"),
      USER_ID: parseInt(localStorage.getItem("UserId")),
      TENANT_CODE: parseInt(localStorage.getItem("TenantCode")),
    };

    this.service.postCall("Account/LoadMenusByRoleId", payLoad).subscribe(
      (res: any[]) => {
        this.menus = res;
        this.menuService.setMenus(this.menus);
        let currentURL = window.location.pathname
        this.allChilds = this.getAllChilds(this.menus);
        this.updateCurrentMenu(currentURL);
        this.router.events
          .pipe(filter((event) => event instanceof NavigationEnd))
          .subscribe((event: NavigationEnd) => {
            if (event.urlAfterRedirects.includes("HOME")) {
              this.updateCurrentMenu(event.urlAfterRedirects);
            }
          });
        // setTimeout(() => {
        //   if (onloadfn) {
        //     onloadfn();
        //   }
        //   if (loadCr) {
        //     loadCr();
        //   }
        // }, 100)
        return;
        let isSubmitbool = localStorage.getItem("isStudent");
        let roleId: any = atob(this.roleId);
        // Check if 'isSubmitbool' is not null or undefined and convert it to boolean
        if (
          isSubmitbool &&
          isSubmitbool.toLowerCase() === "false" &&
          roleId == 3
        ) {
          // Filter menu items to include only required items for students
          this.menus = this.menus.filter((item) => {
            return (
              item.Name === "My Program" ||
              item.Name === "My Application" ||
              item.Name === "My Student Registration"
            );
          });
        }
        // Handle navigation or further processing of filtered menus here if needed
      },
      (err) => {
        // Handle error
      }
    );
  }

  getId(id: any) {
    return id.replaceAll(/\s/g, "");
  }

  closeLeftMenuforMobile() {
    if (window.matchMedia("(max-width: 767px)").matches) {
      const idClickEvent = document.getElementById("sidebar");
      if (idClickEvent) {
        idClickEvent.classList.value = "wrapper";
      }
    }
  }
  userGuide() {
    const URL = `${url}home/KNA/OUK-StudentRegistration-UserGuide.pdf`;
    window.open(URL);
  }
  navigateToLibraryUrl(url: any) {
    window.open(url)
  }

  flattenChilds(node: any): any[] {
    let result = [];
    if (node.childs && node.childs.length > 0) {
      node.childs.forEach(child => {
        result.push(child);
        result = result.concat(this.flattenChilds(child));
      });
    }
    return result;
  }

  getAllChilds(data: any[]): any[] {
    let allChilds = [];
    data.forEach(item => {
      allChilds = allChilds.concat(this.flattenChilds(item));
    });
    return allChilds;
  }

  SearchMenuNav() {
    $('#staticBackdrop').modal('show');
  }

  onItemSelect(item: any) {
    if (item.path.split('/').length > 2) {
      this.cdr.detectChanges();
      this.router.navigate(['HOME/' + item.path]);
      this.closeSearch();
    } else {
      this.toastr.warning('Screen not available for selected item please select another item');
    }
  }

  onItemDeSelect(item: any) {
  }

  closeSearch() {
    $('#staticBackdrop').modal('hide');
    this.selectedMenu = [];
  }
}
