import { environment } from 'src/environments/environment';
import { WindowRefService } from './../../services/window-ref.service';
import { dataDictionary } from 'src/app/dataDictionary';
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { Subscription, forkJoin, timer } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FileuploadService } from '../../services/fileupload.service';
import { ICourse } from '../Shared/models/course';
import { HttpEventType } from '@angular/common/http';
import html2canvas from 'html2canvas';
import jspdf from 'jspdf';
declare var $: any;

@Component({
  selector: 'app-new-view-course-details',
  templateUrl: './new-view-course-details.component.html',
  styleUrls: ['./new-view-course-details.component.css']
})
export class NewViewCourseDetailsComponent implements OnInit {
  @ViewChild('fileInputRef') fileInputRef: ElementRef;
  showTable: boolean = true;
  courseDetails: any;
  courseId: any;
  courseList: Array<any> = [];
  chapterList: Array<any> = [];
  sessionList: Array<ISessionDetails> = [];
  sessionDetails: ISessionDetails;
  myCourses: Array<any> = [];
  enroll: any;
  payId: any;
  // myForm: FormGroup;
  private readonly onDestroy = new Subscription();
  isLogin: boolean = false;
  isAlreadyCourseTaken: boolean = false;
  isCourseExpired: boolean = false;
  dateofbirth: string = '';
  EXAM_REG_NO: string = '';
  ADDRESS: string = '';
  // isRegistrationEnd : Boolean =false;
  // COURSESHD_ID: number = 0;
  aprvId: any;
  paymentstatus: boolean = false;
  hjfdh: Array<any> = [{ id: 34 }, { id: 2 }]
  InstallmentList: Array<any> = [];
  isInstallment: boolean = false;
  mpesaRes: any;
  timerSubscription: Subscription;
  mobileNumber: string = '';
  courseFee: number = 1;
  paymentRes: any = {};
  paymentAPi: Subscription;
  clearPayment: any = null;
  courseYear: Array<any> = [];
  semesterList: Array<any> = [];
  subjects: Array<any> = [];
  chapters: Array<any> = [];
  signUpForm: FormGroup;
  upload1: File;
  upload2: File;
  upload3: File;
  upload4: File;
  upload5: File;
  userDetails: any = {};
  oukPrograms: string = '';
  gender: Array<any> = []; MartialStatus: Array<any> = []; country: Array<any> = [];
  fileName1: any;
  fileName2: any; fileName3: any; fileName4: any; fileName5: any;
  INSTITUTION_NAME: string = '';
  QUALIFICATION: string = '';
  FROM_PERIOD: string = '';
  TO_PERIOD: string = '';
  ADDITIONAL_INFORMATION: string = '';
  LEVEL_OF_STUDY: string = '';
  EDUCATION_COUNTRY_ID: string = '';
  educationData: Array<any> = [{
    INSTITUTION_NAME: '',
    QUALIFICATION: '',
    LEVEL_OF_STUDY: '',
    EDUCATION_COUNTRY_ID: '',
    EXAM_REG_NO: '',
    PERCENTAGE: 0,
    FROM_PERIOD: '',
    TO_PERIOD: '',
    ADDITIONAL_INFORMATION: '',
  }];
  WORKEXP_DETAIL_EMPLOYER: string = '';
  WORKEXP_DETAIL_DESIGNATION: string = '';
  WORKEXP_DETAIL_FROM_YEAR: string = '';
  WORKEXP_DETAIL_TO_YEAR: string = '';
  ApplicantData: Array<any> = [{
    WORKEXP_DETAIL_EMPLOYER: '',
    WORKEXP_DETAIL_DESIGNATION: '',
    WORKEXP_DETAIL_FROM_YEAR: '',
    WORKEXP_DETAIL_TO_YEAR: '',
  }];
  NAME: string = '';
  ORGANIZATION: string = '';
  CITY_TOWN: string = '';
  NATIONALITY: string = '';
  EMAIL_ADDRESS: string = '';
  MOBILE_NUMBER: string = '';
  RefreeData: Array<any> = [{
    NAME: '',
    ORGANIZATION: '',
    CITY_TOWN: '',
    NATIONALITY: '',
    EMAIL_ADDRESS: '',
    MOBILE_NUMBER: ''
  }]
  typesOfFile: object = {
    'Uploaded Material': {
      types: ['pdf', 'jpg', 'png', 'jpeg'],
      message: 'Please upload the',
    },

  };
  sponserEmail: any;
  Email: any;
  Emailid: any;
  uploading = false;
  progress = 0;
  upload_1: any;
  upload_2: any;
  upload_3: any;
  constructor(private winRef: WindowRefService,
    private location: Location, private CommonService: CommonService,
    private fb: FormBuilder, private FileuploadService: FileuploadService, private toastr: ToastrService, private route: Router) {
    this.setDefaultCourse();
    this.setDefaultSession();
    this.getCategoryWiseCourses();
    if (+(sessionStorage.RoleId || 0) > 0) {
      this.isLogin = true;
    }
    // this.aprvId = localStorage.getItem('aprvId');
    this.courseId = localStorage.getItem('cID');
  }


  ngOnInit(): void {
    const resultState: unknown = this.location.getState();
    delete resultState['navigationId'];
    // console.log(resultState);
    this.courseDetails = resultState;
    if (this.courseDetails && !this.isLogin)
      sessionStorage.courseDetails = JSON.stringify(this.courseDetails);
    else
      sessionStorage.removeItem('courseDetails');
    // this.courseDetails = Object.values(resultState) ;
    // console.log(this.courseDetails);
    if (this.courseDetails.moreoptions)
      this.courseDetails.moreoptions = this.courseDetails.moreoptions.split(",");
    else
      this.courseDetails.moreoptions = [];

    const courseId: number = this.courseDetails.COURSE_ID || 0;
    if (courseId > 0)
      this.loadSessions(courseId);
    // this.getCourses();
    this.uploadForm();
    this.getAll();
    this.approveStatusCheck();


  }
  savePreview() {
    // const url = environment.videoUrl + this.route.navigate(['/application']);
    // console.log(url);

    // window.open(url);
    // this.route.navigate(['/application']); // Perform navigation
  }
  // closeModal(event: any) {
  //   document.getElementById('md_close')?.click();
  // }


  downloadForm() {
    this.CommonService.activateSpinner();
    var data: any = document.getElementById('printtable');
    html2canvas(data).then((canvas: any) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jspdf('p', 'mm', [297, 210]);
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      const contentDataURL = canvas.toDataURL('image/png')
      var position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, 0, pdfWidth, pdfHeight)
      pdf.save('ApplicationForm.pdf'); // Generated PDF
      this.CommonService.deactivateSpinner();
      setTimeout(() => {
        // document.getElementById('Close')?.click();
        // this.closeModal.emit(true);
      }, 2000);
    });

  }



  validateDates(row: any) {
    // row.TO_PERIOD = null;
    const rowIndex = this.educationData.indexOf(row);
    if (rowIndex >= 0) {
      for (let i = 0; i < rowIndex; i++) {
        const previousRow = this.educationData[i];
        if (previousRow.TO_PERIOD && row.FROM_PERIOD) {
          const previousTo = new Date(previousRow.TO_PERIOD);
          const currentFrom = new Date(row.FROM_PERIOD);
          if (currentFrom <= previousTo) {

          }
        }
      }
    }
  }
  isToDateInvalid(row: any): boolean {
    if (!row.FROM_PERIOD || !row.TO_PERIOD) {
      return false;
    }

    const from = new Date(row.FROM_PERIOD);
    const to = new Date(row.TO_PERIOD);

    return from > to;
  }
  ngOnDestroy(): void {
    // if (this.paymentAPi) {
    // this.paymentAPi.unsubscribe();
    // }
    this.PaymentClear();
  }
  getAll() {
    let types = this.CommonService.postCall('GetDictionaryByKey', { DictionaryCode: dataDictionary.Gender });//Gender
    let martialStatus = this.CommonService.postCall('GetDictionaryByKey', { DictionaryCode: dataDictionary.MaritalStatus })//MartialStatus
    let country = this.CommonService.postCall('GetDictionaryByKey', { DictionaryCode: dataDictionary.Country });
    forkJoin([types, martialStatus, country]).subscribe((res) => {
      [this.gender, this.MartialStatus, this.country] = [...res]
    })

  }
  enableOrDisabledSpinner(flag: boolean = true) {
    flag ? this.CommonService.activateSpinner() : this.deactiveSpinner();
  }


  approveStatusCheck() {
    this.activeSpinner();
    this.getUserDetails();
    // if(this.userDetails)
    this.edit();
    this.CommonService.postCall('Registration/LoadpaymentOrApproveStatus', { "APPL_PAYDET_USER_ID": localStorage.getItem('UserId') }).subscribe((res: any) => {
      this.aprvId = res?.IS_APPROVED;
      this.payId = res?.APPL_PAYDET_ISPAID;
      this.deactiveSpinner();
    })
  }
  edit() {
    this.activeSpinner();
    this.CommonService.postCall('Registration/GetAudioPath', { "USERID": localStorage.getItem('UserId') }).subscribe((res: any) => {
      this.userDetails = res;
      this.signUpForm.patchValue({
        "NAME_OF_EMERGENCY": this.userDetails?.result.name_of_emergency,
        "FIRSTNAME": this.userDetails?.result?.firstname,
        "LASTNAME": this.userDetails?.result?.lastname,
        "RELATIONSHIPNAME_OF_EMERGENCY": this.userDetails?.result.relationshipname_of_emergency,
        "MOBILE_NO_EMERGENCY": this.userDetails?.result.mobile_no_emergency,
        "ADDRESS_OF_EMERGENCY": this.userDetails?.result.address_of_emergency,
        "POSTALCODE_OF_EMERGENCY": this.userDetails?.result.postalcode_of_emergency,
        "TOWNCITY_OF_EMERGENCY": this.userDetails?.result.towncity_of_emergency,
        "COUNTRYID_OF_EMERGENCY": this.userDetails?.result.countryid_of_emergency,
        "EMAILID_OF_EMERGENCY": this.userDetails?.result.emailid_of_emergency,
        "MIDDLENAME": this.userDetails?.result.middlename,
        // "KCSE_INDEX_NO": this.userDetails?.result.kcse_index_no,
        "POSTAL_ADDRESS": this.userDetails?.result.postal_address,
        "PHYSICAL_ADDRESS": this.userDetails?.result.physical_address,
        "DOB": this.userDetails?.result.dob,
        "NATIONALITY": this.userDetails?.result.nationality,
        "NEAREST_TOWN": this.userDetails?.result.nearest_town,
        "COUNTRY_ID": this.userDetails?.result.country_id,
        "ID_PASSPORT": this.userDetails?.result.id_passport,
        "GENDER": this.userDetails?.result.gender,
        "MOBILENO": this.userDetails?.result.mobileno,
        "MARTIALSTATUS": this.userDetails?.result.martialstatus,
        "PROGRAMME_CATEGORY_ID": this.userDetails?.result.programme_category_id,
        "MODE_OF_STUDY": this.userDetails?.result.mode_of_study,
        "SPONSORS_NAME": this.userDetails?.result.sponsors_name,
        "SPONSORS_MOBILE": this.userDetails?.result.sponsors_mobile,
        "SPONSORS_EMAIL": this.userDetails?.result.sponsors_email,
        "POSTAL_CODE": this.userDetails?.result.POSTAL_CODE
        // "UPLOAD_1":this.signUpForm.get('UPLOAD_1').setValue('this.userDetails?.result.upload_1')
      })
      if (this.userDetails.lst.length != 0) {
        this.courseId = this.userDetails?.result.courseid;
        this.educationData = this.userDetails?.lst;
        // this.educationData.forEach(data => {
        //   data.FROM_PERIOD = new Date(data.FROM_PERIOD).toISOString().split('T')[0];
        //   data.TO_PERIOD = new Date(data.TO_PERIOD).toISOString().split('T')[0];
        // });
        this.signUpForm.get('UPLOAD_1').clearValidators();
        this.signUpForm.get('UPLOAD_1').updateValueAndValidity(); // Update the form control
        this.signUpForm.get('UPLOAD_3').clearValidators();
        this.signUpForm.get('UPLOAD_3').updateValueAndValidity();
        this.signUpForm.get('UPLOAD_4').clearValidators();
        this.signUpForm.get('UPLOAD_4').updateValueAndValidity();
        const prefixToRemove = "/home/KNA/registrationDocuments/registrationDocuments";
        this.upload_1 = this.userDetails?.result.upload_1?.replace(prefixToRemove, '');
        this.upload_2 = this.userDetails?.result.upload_4?.replace(prefixToRemove, '');
        this.upload_3 = this.userDetails?.result.upload_3?.replace(prefixToRemove, '');

      }
      if (this.userDetails.lstt.length != 0) {
        this.ApplicantData = this.userDetails?.lstt;
        this.ApplicantData.forEach(data => {
          data.WORKEXP_DETAIL_FROM_YEAR = new Date(data.WORKEXP_DETAIL_FROM_YEAR).toISOString().split('T')[0];
          data.WORKEXP_DETAIL_TO_YEAR = new Date(data.WORKEXP_DETAIL_TO_YEAR).toISOString().split('T')[0];
        });
      }




      this.deactiveSpinner();
    }, (err: any) => {
      this.toastr.error(err.error ? err.error : 'Failed');
      this.deactiveSpinner();
    })
  }
  loadSessions(courseId: number) {
    this.sessionList = [];
    this.enableOrDisabledSpinner();
    const ob1$ = this.CommonService.getCall('GetSessionsByCourseId', `/${courseId}`).subscribe((res: any) => {
      this.sessionList = res.dtCourseScehdule;
      if (this.sessionList.length > 0) {
        this.sessionDetails = { ...this.sessionList[0], Total_Payable_Amount: 0 };
        this.sessionChanged(this.sessionDetails.COURSESHD_ID);
      }
      this.enableOrDisabledSpinner(false);
    }, e => { this.enableOrDisabledSpinner(false); });
    this.onDestroy.add(ob1$);
  }

  setDefaultCourse() {
    this.courseDetails = {
      COURSE_ID: 0,
      COURSE_NAME: '',
      COURSE_DESCRIPTION: '',
      COURSE_CATEGORY_ID: 0,
      COURSE_STATUS: true,
      COURSE_RATING: 0,
      COURSE_IMAGE: '',
      COURSE_FEES: 0,
      IMAGE_URL: '',
      USERID: 0,
      FIRSTNAME: '',
      LASTNAME: '',
      COURSE_TRENDING: true,
      COURSESHD_AMOUNT: 0,
      moreoptions: []
    };
  }

  setDefaultSession() {
    this.sessionDetails = {
      COURSESHD_AMOUNT: 0,
      COURSESHD_COURSE_ID: 0,
      COURSESHD_ENDDATE: '',
      COURSESHD_ENDTIME: '',
      COURSE_FEES: 0,
      COURSESHD_ID: 0,
      COURSESHD_NAME: '',
      COURSESHD_STARTDATE: '',
      REGISTRATION_STARTDATE: '',
      REGISTRATION_ENDDATE: '',
      COURSESHD_STARTTIME: '',
      COURSESHD_PAYMENT_METHOD: 0,
      Total_Payable_Amount: 0,

    };
  }
  getCategoryWiseCourses() {
    // const { company_id } = sessionStorage;
    [this.courseList] = [[]];
    this.enableOrDisabledSpinner();
    const ob1$ = this.CommonService.getCall(`CourseSchedule/GetAllCoursesByCategoryId/${0}/${0}/${localStorage.getItem('TenantCode')}`).subscribe((res: any) => {

      this.courseList = res?.dtCourseScehdule;
      this.enableOrDisabledSpinner(false);
    }, e => { this.enableOrDisabledSpinner(false); });
    this.onDestroy.add(ob1$);
  }

  uploadForm() {
    this.signUpForm = this.fb.group({
      "UPLOAD_1": ['', Validators.required],
      "BIRTH_COUNTRY_ID": ['', Validators.required],
      "UPLOAD_3": [''],
      "UPLOAD_4": ['', Validators.required],
      "IS_RPL": [''],
      "COUNTRY_ID": ['', Validators.required],
      "MOBILENO": ['', Validators.required],
      "MOBILENO2": [''],
      "DOB": ['', Validators.required],
      "ID_PASSPORT": ['', Validators.required],
      "GENDER": ['', Validators.required],
      "MARTIALSTATUS": ['', Validators.required],
      "COURSE_INTERESTED_IN": [''],
      "PROGRAMME_CATEGORY_ID": [],
      "POSTAL_ADDRESS": ['', Validators.required],
      "PHYSICAL_ADDRESS": ['', Validators.required],
      "NAME_OF_EMERGENCY": ['', Validators.required],
      "RELATIONSHIPNAME_OF_EMERGENCY": ['', Validators.required],
      "MOBILE_NO_EMERGENCY": ['', Validators.required],
      "ADDRESS_OF_EMERGENCY": [''],
      "POSTALCODE_OF_EMERGENCY": [''],
      "TOWNCITY_OF_EMERGENCY": [''],
      "COUNTRYID_OF_EMERGENCY": [''],
      "EMAILID_OF_EMERGENCY": [''],
      "SPONSORS_NAME": [''],
      "SPONSORS_MOBILE": [''],
      "NATIONALITY": ['', Validators.required],
      "FIRSTNAME": [''],
      "LASTNAME": [''],
      "SPONSORS_EMAIL": [''],
      "MODE_OF_STUDY": [''],
      "POSTAL_CODE": [''],
      "MIDDLENAME": [''],
      "NEAREST_TOWN": [''],
      "APPLICATION_TYPE": ['', Validators.required],
      "BASIC_EDUCATION_SYSTEM": [''],
      "MEAN_GRADE": [''],
      "CLUSTER_SUBJECT1_NAME": [''],
      "CLUSTER_SUBJECT2_NAME": [''],
      "CLUSTER_SUBJECT3_NAME": [''],
      "CLUSTER_SUBJECT4_NAME": [''],
      "CLUSTER_SUBJECT1_GRADE": [''],
      "CLUSTER_SUBJECT2_GRADE": [''],
      "CLUSTER_SUBJECT3_GRADE": [''],
      "CLUSTER_SUBJECT4_GRADE": [''],
      "ENGLISH_PROFICIENCY": [''],
      "COMPUTING_SKILLS_PROFICIENCY": [''],

    })
  }
  // closeModal() {
  //   document.getElementById('Closemodal')?.click();
  // }
  getUserDetails() {
    this.CommonService.postCall('Registration/GetAudioPath', { "USERID": localStorage.getItem('UserId') }).subscribe((res: any) => {
      this.userDetails = res;
      // let ctrls = this.signUpForm.controls
      // Object.keys(ctrls).map((key) => {
      //   let ctrl = ctrls[key];
      //   ctrl.setValue(this.userDetails[key]);
      // });
    })

  }
  disableDate() {
    return false;
  }
  getToday(): string {
    return new Date().toISOString().split('T')[0]
  }
  agevalidate(event: any) {
    let date = event.target.value;
    let dob = new Date(date);
    let timeDiff = Math.abs(Date.now() - dob.getTime());
    let age = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365.25);
    if (age < 16) {
      this.toastr.warning("Age Should be greater than 16");
      this.dateofbirth = '';
    }
  }
  changeFile(event: any, id: number) {



    if (event.target.files.length > 0) {
      const file: File = event.target.files[0];
      let name = file.name;
      let filetype = name.split('.').pop();
      let materialType: object = this.typesOfFile['Uploaded Material'];
      let check = materialType['types'].includes(filetype.toLowerCase());
      if (check) {
        switch (id) {
          case 1:
            this.upload1 = file;
            this.upload(id);
            break;
          case 2:
            this.upload2 = file;
            this.upload(id);
            break;
          case 3:
            this.upload3 = file;
            this.upload(id);
            break;
          case 4:
            this.upload4 = file;
            this.upload(id);
            break;
          default:
            this.upload5 = file;
            this.upload(id);
            break;
        }
      } else {
        // alert(' Please upload pdf and doc file formats only.')
        this.toastr.warning(materialType['message'] + JSON.stringify(materialType['types']))
        event.target.value = '';
      }

    }
  }

  upload(id: number) {
    const formData = new FormData();
    switch (id) {
      case 1:
        formData.append('upload_1', this.upload1);
        break;
      case 2:
        formData.append('upload_2', this.upload2);
        break;
      case 3:
        formData.append('upload_3', this.upload3);
        break;
      case 4:
        formData.append('upload_4', this.upload4);
        break;
      default:
        formData.append('upload_5', this.upload5);
        break;
    }

    formData.append('DIRECTORY_NAME', 'registrationDocuments');
    this.activeSpinner();
    this.FileuploadService.upload(formData, 'Registration/Upload_File').subscribe(
      (res: any) => {
        try {
          if (id == 1) {
            this.activeSpinner()
            this.fileName1 = res.path;
            this.upload1 = this.fileName1;
            setTimeout(() => {
              if (res.path != '') {
                this.toastr.success('Uploaded!')
                this.deactiveSpinner();
                return
              } else {
                this.toastr.warning("upload Failed, Please try again!");
                return
              }
            }, 2000);

          } else if (id == 2) {
            this.activeSpinner();
            this.fileName2 = res.path;
            setTimeout(() => {
              if (res.path != '') {
                this.toastr.success('Uploaded!');
                this.deactiveSpinner();
                return
              } else {
                this.toastr.warning("upload Failed, Please try again!");
                return
              }
            }, 2000);
          } else if (id == 3) {
            this.activeSpinner();
            this.fileName3 = res.path;
            this.upload3 = this.fileName3;
            setTimeout(() => {
              if (res.path != '') {
                this.toastr.success('Uploaded!');
                this.deactiveSpinner();
                return
              } else {
                this.toastr.warning("upload Failed, Please try again!");
                return
              }
            }, 2000);
          } else if (id == 4) {
            this.activeSpinner();
            this.fileName4 = res.path;
            this.upload_2 = this.fileName4;
            setTimeout(() => {
              if (res.path != '') {
                this.toastr.success('Uploaded!');
                this.deactiveSpinner();
                return
              } else {
                this.toastr.warning("upload Failed, Please try again!");
                return
              }
            }, 2000);
          } else {
            this.activeSpinner();
            this.fileName5 = res.path;
            setTimeout(() => {
              if (res.path != '') {
                this.toastr.success('Uploaded!');
                this.deactiveSpinner();
                return
              } else {
                this.toastr.warning("upload Failed, Please try again!");
                return
              }
            }, 2000);
          }

          this.deactiveSpinner();
        } catch (e) {
          console.log(e);
        }
      },
      (err) => {
        this.deactiveSpinner();
      }
    );
  }
  updateDetails() {
    let payload = {
      "APPL_PAYDET_USER_ID": localStorage.getItem('UserId'),
      "APPL_PAYDET_ISPAID": 2
    }
    this.CommonService.postCall('Registration/UpdateApplicationFeeStatus', payload).subscribe((res: any) => {
      if (res.message == "true") {
        let payId: any = 1;
        localStorage.setItem('IsPaid', payId)
      }

      // document.getElementById('payData')?.click();


    })
  }

  save(form: any, id: any) {
    this.activeSpinner();
    let value = form.value;
    let payload = {
      "UPLOAD_1": this.fileName1 || '',
      "UPLOAD_3": this.fileName3 || '',
      "UPLOAD_4": this.fileName4 || '',
      "USERID": localStorage.getItem('UserId'),
      "FIRSTNAME": value.FIRSTNAME || this.userDetails?.result?.firstname,
      "LASTNAME": value.LASTNAME || this.userDetails?.result?.lastname,
      "MIDDLENAME": value.MIDDLENAME,
      "COURSEID": this.courseId,
      "COUNTRY_ID": value.COUNTRY_ID,
      "MOBILENO": value.MOBILENO,
      "DOB": value.DOB,
      "ID_PASSPORT": value.ID_PASSPORT,
      "GENDER": value.GENDER,
      "APPL_PAYDET_ISPAID": id == '0' ? 0 : 2,
      "MARTIALSTATUS": value.MARTIALSTATUS,
      "COURSE_INTERESTED_IN": localStorage.getItem('cID'),
      "PROGRAMME_CATEGORY_ID": value.PROGRAMME_CATEGORY_ID,
      "MODE_OF_STUDY": value.MODE_OF_STUDY,
      "POSTAL_CODE": value.POSTAL_CODE,
      "DLC_REGISTRATION_DETAILS":
      {
        "POSTAL_ADDRESS": value.POSTAL_ADDRESS,
        "BIRTH_COUNTRY_ID": value.BIRTH_COUNTRY_ID,
        "MOBILENO2": value.MOBILENO2,
        "SPONSORS_EMAIL": value.SPONSORS_EMAIL,
        "PHYSICAL_ADDRESS": value.PHYSICAL_ADDRESS,
        "NAME_OF_EMERGENCY": value.NAME_OF_EMERGENCY,
        "RELATIONSHIPNAME_OF_EMERGENCY": value.RELATIONSHIPNAME_OF_EMERGENCY,
        "MOBILE_NO_EMERGENCY": value.MOBILE_NO_EMERGENCY,
        "SPONSORS_NAME": value.SPONSORS_NAME,
        "SPONSORS_MOBILE": value.SPONSORS_MOBILE,
        "NATIONALITY": value.NATIONALITY,
        "ADDRESS_OF_EMERGENCY": value.ADDRESS_OF_EMERGENCY,
        "POSTALCODE_OF_EMERGENCY": value.POSTALCODE_OF_EMERGENCY,
        "TOWNCITY_OF_EMERGENCY": value.TOWNCITY_OF_EMERGENCY,
        "COUNTRYID_OF_EMERGENCY": value.COUNTRYID_OF_EMERGENCY,
        "EMAILID_OF_EMERGENCY": value.EMAILID_OF_EMERGENCY,
        "IS_RPL": value.IS_RPL,
        // "SECONDARY_SCHOOL_NAME1": value.SECONDARY_SCHOOL_NAME1,
        // "SECONDARY_FROM1": value.SECONDARY_FROM1,
        // "SECONDARY_TO1": value.SECONDARY_TO1,
        // "SECONDARY_SCHOOL_NAME2": value.SECONDARY_SCHOOL_NAME2,
        // "SECONDARY_FROM2": value.SECONDARY_FROM2,
        // "SECONDARY_TO2": value.SECONDARY_TO2,
        "NEAREST_TOWN": value.NEAREST_TOWN,
        // "PHYSICAL_DISABILITY": value.PHYSICAL_DISABILITY,
        "SPECIFIC_THE_NATURE_OF_DISABILITY": value.SPECIFIC_THE_NATURE_OF_DISABILITY,
        // "OUK_PROGRAMMES": value.OUK_PROGRAMMES,
        "CREATEDBY": localStorage.getItem('UserId'),
        "MODIFIEDBY": localStorage.getItem('UserId'),
        "TNT_CODE": localStorage.getItem('TenantCode'),
        // "KCSE_INDEX_NO": value.KCSE_INDEX_NO,
        "IS_SUBMIT": id == '1' ? 1 : 0
      },
      "dlc_Education_Details": this.educationData,
      "dlc_workexperiance_details": this.ApplicantData,
      "dlc_Recommendations": this.RefreeData,
      "DLC_CLUSTERS": {
        "APPLICATION_TYPE": value.APPLICATION_TYPE,
        "BASIC_EDUCATION_SYSTEM": value.APPLICATION_TYPE,
        "MEAN_GRADE": value.APPLICATION_TYPE,
        "CLUSTER_SUBJECT1_NAME": value.APPLICATION_TYPE,
        "CLUSTER_SUBJECT2_NAME": value.APPLICATION_TYPE,
        "CLUSTER_SUBJECT3_NAME": value.APPLICATION_TYPE,
        "CLUSTER_SUBJECT4_NAME": value.APPLICATION_TYPE,
        "CLUSTER_SUBJECT1_GRADE": value.APPLICATION_TYPE,
        "CLUSTER_SUBJECT2_GRADE": value.APPLICATION_TYPE,
        "CLUSTER_SUBJECT3_GRADE": value.APPLICATION_TYPE,
        "CLUSTER_SUBJECT4_GRADE": value.APPLICATION_TYPE,
        "ENGLISH_PROFICIENCY": value.APPLICATION_TYPE,
        "COMPUTING_SKILLS_PROFICIENCY": value.APPLICATION_TYPE,
        "MODE_OF_STUDY": value.APPLICATION_TYPE,
        "CLUSTER_ID": value.APPLICATION_TYPE,
      }
    }

    this.CommonService.postCall('Registration/UpdateRegistrationFiles', payload).subscribe((res: any) => {
      // id == '1' ? document.getElementById('md_close')?.click() : '';
      if (id == '1') {
        document.getElementById('md_close')?.click();
        this.toastr.success("Submitted Successful!");
        this.updateDetails();
        this.deactiveSpinner();
      } else {
        this.getUserDetails();
      }



      this.deactiveSpinner();
    }, (err: any) => {
      this.toastr.error(err.error ? err.error : 'Not Registered!');
      this.deactiveSpinner();
    })
  }
  sessionChanged(id: number) {
    this.isInstallment = false;
    if (id > 0) {
      this.sessionDetails = { ... this.sessionList.find(e => e.COURSESHD_ID == id), Total_Payable_Amount: 0 };
      this.loadChapters(this.sessionDetails.COURSESHD_ID, this.sessionDetails.COURSESHD_COURSE_ID);
      // this.loadsemester();
      this.isInstallment = +this.sessionDetails.COURSESHD_PAYMENT_METHOD == 2;
      if (this.isInstallment)
        this.loadInstallmentDetails(+this.sessionDetails.COURSESHD_ID, +sessionStorage.UserId);
      const dt1 = new Date();
      const currentDate: string = `${dt1.getFullYear()}-${dt1.getMonth() + 1}-${dt1.getDate()}`;
      this.isCourseExpired = 0 <= this.compareDates(currentDate, this.sessionDetails.REGISTRATION_ENDDATE.replace("T00:00:00", ""));
      // this.isRegistrationEnd = 0 <= this.compareDates(currentDate, this.sessionDetails.REGISTRATION_ENDDATE?.replace("T00:00:00", ""));
      this.isExpired();
    }
    else {
      this.setDefaultSession();
      this.chapterList = [];
    }
  }
  onKeyPress(event: any) {
    const charCode = event.which ? event.which : event.keyCode;

    // Allow only numbers (0-9) and navigation keys
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
    }
  }
  installmentChanged(checkedItem: any, installmentItem: any) {
    if (installmentItem) {
      // installmentItem.status = checkedItem.checked;
      // if (checkedItem.checked)
      //   this.sessionDetails.Total_Payable_Amount += installmentItem.TERM_AMOUNT;
      // else
      //   this.sessionDetails.Total_Payable_Amount -= installmentItem.TERM_AMOUNT;

      if (checkedItem.checked == false) {
        this.InstallmentList.filter(e => e.CSI_INSTALMENT_ID >= installmentItem.CSI_INSTALMENT_ID).forEach(e => {
          if (e.status) {
            this.sessionDetails.Total_Payable_Amount -= e.TERM_AMOUNT;
            e.status = false;
          }
        });
      }
      else if (checkedItem.checked) {
        this.sessionDetails.Total_Payable_Amount = 0;
        this.InstallmentList.filter(e => e.CSI_INSTALMENT_ID <= installmentItem.CSI_INSTALMENT_ID).forEach(e => {
          this.sessionDetails.Total_Payable_Amount += e.TERM_AMOUNT;
          e.status = true;
        });
      }

      console.log(this.InstallmentList);
    }
  }

  loadInstallmentDetails(COURSESHD_ID: number, STUDENT_ID: number) {
    this.InstallmentList = [];
    this.enableOrDisabledSpinner();
    const ob1$ = this.CommonService.getCall('CourseSchedule/GetUpaidInstallments', `/${COURSESHD_ID}/${STUDENT_ID}`).subscribe((res: any) => {
      this.InstallmentList = res.dtCourseScehdule.map((e, index) => ({ ...e, status: !index }));//res.dtCourseScehdule;
      this.sessionDetails.Total_Payable_Amount += this.InstallmentList.length > 0 ? this.InstallmentList[0].TERM_AMOUNT : 0;
      console.log(this.InstallmentList);
      this.enableOrDisabledSpinner(false);
    }, e => { this.enableOrDisabledSpinner(false); });
    this.onDestroy.add(ob1$);
  }

  loadChapters(COURSESHD_ID: number, COURSE_ID: number) {
    this.courseYear = [];
    this.enableOrDisabledSpinner();
    // const ob1$ = this.CommonService.getCall('getChaptersByCourseId', `/${COURSESHD_ID}/${COURSE_ID}`).subscribe((res: any) => {
    const ob1$ = this.CommonService.postCall('Courses/LoadChaptersByCourse', { "COURSE_ID": COURSE_ID }).subscribe((res: any) => {
      // this.chapterList = res.map(e => ({ ...e, CHAPTER_DESCRIPTION: e.CHAPTER_DESCRIPTION?.replace("\n", "<br>") }));
      // console.log(this.chapterList);
      // this.courseYear = this.chapterList[0].dlc_LoadAcademic;
      // console.log(this.chapterList[0].dlc_LoadAcademic);
      // console.log(this.chapterList[0].dlc_LoadAcademic.map(obj => obj.Load_semesters));
      this.courseYear = res.dlc_LoadAcademic;



      this.enableOrDisabledSpinner(false);
    }, e => { this.enableOrDisabledSpinner(false); });
    this.onDestroy.add(ob1$);
  }
  loadsemester() {
    // this.semesterList = this.courseYear[0]?.Load_semesters;
  }
  viewSemester(cYearId: any) {
    // if (!cYearId) {
    //   this.loadsemester();
    // }

    this.semesterList = [];
    let SelectedYaer = this.courseYear.find((e: any) => e.COURSESHD_COURSEYEAR == cYearId);
    this.semesterList = SelectedYaer.Load_semesters
  }
  viewSubjects(semId: any) {
    this.subjects = [];
    let selectedSemester = this.semesterList.find((e: any) => e.COURSESHD_SEMESTER == semId);
    this.subjects = selectedSemester.Load_Subjects;
  }
  viewChapters(chapId: any) {
    this.chapters = [];
    let slectedSubject = this.subjects.find((e: any) => e.SUBJECT_ID == chapId);
    this.chapters = slectedSubject.Load_Chapters;
  }
  Enroll() {
    if (this.isLogin)
      document.getElementById('btnEnrolled').click();
    else
      this.route.navigate(['/login']).then(() => {
        this.toastr.warning("Please login!");
      });
  }

  isEnrolled(details: any): boolean {
    // const index = this.myCourses?.findIndex(m => { m.COURSE_ID == details.COURSE_ID && m.COURSESHD_ID == details.COURSESHD_ID });
    const index = this.myCourses?.findIndex(m => { m.COURSE_ID == details.COURSE_ID });
    return index > 0;

  }

  EnrollSuccess() {
    const { UserId, TenantCode } = sessionStorage;
    if (!UserId) {
      this.route.navigate(['/login']);
    } else {
      let payLoad = [];
      if (this.isInstallment && this.InstallmentList.length > 0) {
        payLoad = this.InstallmentList.filter(f => f.status == true).map(e =>
        ({
          CourseId: this.sessionDetails.COURSESHD_COURSE_ID,
          CREATEDBY: UserId,
          TNT_CODE: TenantCode,
          UserId: UserId,
          CourseScheduleId: this.sessionDetails.COURSESHD_ID,
          ModifiedBy: UserId,
          STUDENT_PAYMENT_COURSE_FEE: this.sessionDetails.COURSESHD_AMOUNT,
          STUDENT_PAYMENT_TOTAL_AMOUNT_PAID: e.TERM_AMOUNT,
          STUDENT_PAYMENT_CSI_INSTALLMENT_ID: e.CSI_INSTALMENT_ID
        }));
        if (payLoad.length == 0) {
          this.toastr.warning('Please select at least one installment');
          return;
        }
      }
      else {
        payLoad = [{
          CourseId: this.sessionDetails.COURSESHD_COURSE_ID,
          CREATEDBY: UserId,
          TNT_CODE: TenantCode,
          UserId: UserId,
          CourseScheduleId: this.sessionDetails.COURSESHD_ID,
          ModifiedBy: UserId,
          STUDENT_PAYMENT_COURSE_FEE: this.sessionDetails.COURSESHD_AMOUNT,
          STUDENT_PAYMENT_TOTAL_AMOUNT_PAID: this.sessionDetails.COURSESHD_AMOUNT,
          STUDENT_PAYMENT_CSI_INSTALLMENT_ID: 0
        }];
      }

      // return;
      this.CommonService.postCall('Enroll/CreateEnroll', payLoad).subscribe((res: any) => {
        // this.enroll = res;
        if (res) {
          document.getElementById('md_close')?.click();
          this.toastr.success('Payment Successfully');
          // this.ngOnDestroy();
          this.PaymentClear();

          const { student_payment_id } = res.STUDENT_PAYMENT_ID;
          this.make_Payment(student_payment_id);
        }
        // document.getElementById('md_close').click();
        // this.toastr.success('Payment Successfully');
        // this.route.navigate(['HOME/my-courses']);

      });

    }
  }

  onPaymentClicked() {
    if (this.mobileNumber == "") {
      return this.toastr.warning("Please enter mobile number")
    } else {
      if (this.isInstallment) {
        if (this.InstallmentList.length == 0) {
          document.getElementById('md_close').click();
          this.toastr.error('You have already taken this course');
          return;
        }
        const isSelectedInstallments: boolean = this.InstallmentList.some(e => e.status == true);
        if (isSelectedInstallments == false) {
          this.toastr.error('Please select at least one installment.');
          return;
        }
        this.mpesaPayment();

        // this.timerSubscription = timer(0, 10000).pipe(
        //   map(() => {
        //     this.paymentConformation(); 
        //   })
        // ).subscribe();


      }
      else
        this.IsPaidforSchedule();
    }

  }

  mpesaPayment() {
    this.EnrollSuccess()
    // localStorage.getItem('CourseFee')
    // let payload = {
    //   MobileNumber: this.mobileNumber,
    //   Amount: "1"
    // }
    // this.CommonService.postCall('MPesaIntegration/MpesaPush', payload).subscribe((res: any) => {
    //   this.mpesaRes = res.checkoutId;
    //   this.clearPayment = setInterval(() => this.paymentConformation(), 10000);
    // });

  }

  PaymentClear() {
    clearInterval(this.clearPayment);
  }

  activeSpinner() {
    this.CommonService.activateSpinner();
  }
  deactiveSpinner() {
    this.CommonService.deactivateSpinner();
  }
  paymentConformation() {
    let count = 0;
    count++;
    this.paymentAPi = this.CommonService.getCall(`MPesaIntegration/ConfirmationBooking/${this.mpesaRes}`).subscribe((res: any) => {
      this.paymentRes = res.status;
      if (this.paymentRes == 1) {
        this.EnrollSuccess();
      } else if (this.paymentRes == 2) {
        this.toastr.warning('Payment Cancelled');
        setTimeout(() => {
          // this.ngOnDestroy();
          this.PaymentClear();
        }, 100)
      }
      else {
        this.activeSpinner();
        if (count == 5) {
          this.PaymentClear();
        }
        setTimeout(() => {
          this.deactiveSpinner();
        }, 12000);
      }
    })
  }


  IsPaidforSchedule() {
    const { UserId } = sessionStorage;
    const COURSESHD_ID: number = this.sessionDetails.COURSESHD_ID;
    if (COURSESHD_ID > 0) {
      this.enableOrDisabledSpinner();
      const Schedule$ = this.CommonService.getCall('Courses/IsPaidforSchedule/', `${UserId}/${COURSESHD_ID}`).subscribe((res: any) => {
        const isCourseTaken = res;
        if (isCourseTaken) {
          document.getElementById('md_close').click();
          this.toastr.error('You have already taken this course');
        }
        else {
          this.mpesaPayment();
        }
        this.enableOrDisabledSpinner(false);
      }, e => { this.enableOrDisabledSpinner(false); });
      this.onDestroy.add(Schedule$);
    }
    else
      this.toastr.warning('Please select a Schedule');
  }

  getCourses() {
    this.myCourses = [];
    this.enableOrDisabledSpinner();
    const mycourse$ = this.CommonService.getCall('CourseSchedule/GetMyCourses/', `${sessionStorage.UserId}`).subscribe((res: any) => {
      this.myCourses = res.dtCourseScehdule;
      if (this.isLogin) {
        this.isAlreadyCourseTaken = this.isEnrolled(this.courseDetails);
      }
      this.isExpired();
      this.enableOrDisabledSpinner(false);
    }, e => { this.enableOrDisabledSpinner(false); });
    this.onDestroy.add(mycourse$);

  }

  isExpired() {
    const exp: boolean = this.sessionDetails.COURSESHD_ID > 0 && (this.isCourseExpired && (!this.isAlreadyCourseTaken));
    if (!exp) {
      this.toastr.warning('Yours Registration date is Ended' || 'Your course is Expired');
    }

  }

  close() {
    this.sessionDetails.Total_Payable_Amount = 0;
    this.signUpForm.reset();
    this.educationData = [];
    this.getUserDetails();
  }

  GSTPercentages(val: number) {
    const totalGST = val * 18 / 100;
    return totalGST;
  }

  make_Payment(student_payment_id: number) {
    const currentDate = new Date();
    const newDate = `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${currentDate.getDate()}`;
    currentDate.setDate(currentDate.getDate() + 10);
    const expiryDate = `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${currentDate.getDate()}`;
    const totalAmt = this.isInstallment ? this.sessionDetails.Total_Payable_Amount : this.sessionDetails.COURSESHD_AMOUNT;
    const { UserId, TenantCode } = sessionStorage;
    const payload = {
      payment_id: student_payment_id,
      user_regid: UserId,
      groupadmin_regid: 0,
      amount: totalAmt + this.GSTPercentages(totalAmt),
      payment_date: newDate,
      payment_mode: 1,
      airport_id: 1,
      course_id: 1,
      approved_tranction_details_id: 0,
      expiry_date: expiryDate,
      // tnt_code: 12345678,
      tnt_code: TenantCode,
      created_by: UserId,
      create_date: newDate
    };

    this.enableOrDisabledSpinner();
    const mycourse$ = this.CommonService.postCall('Payment/MakePayment', payload).subscribe((res: any) => {
      this.payWithRazor(res);
      this.enableOrDisabledSpinner(false);
    }, e => { this.enableOrDisabledSpinner(false); });
    this.onDestroy.add(mycourse$);

  }

  payWithRazor(val: any) {
    const options: any = {
      prefill: {
        email: sessionStorage.USERNAME,
        contact: sessionStorage.MobileNo
      },
      key: environment.RazorPay_Key,
      // key: 'rzp_test_n9kOog8GmBqfim',
      // amount: val.amount, // amount should be in paise format to display Rs 1255 without decimal point
      amount: 1, // amount should be in paise format to display Rs 1255 without decimal point
      currency: 'INR',
      name: 'shiksion', // company name or product name
      description: '',  // product description
      image: './../../../assets/img/samvaad_tutor_logo.png', // company logo or product image
      order_id: val.order_id,//'order_K4gst603mrTngY', // order_id created by you in backend
      modal: {
        // We should prevent closing of the form when esc key is pressed.
        escape: false,
      },
      notes: {
        // include notes if any
      },
      theme: {
        color: '#0c238a'
      }
    };

    options.handler = ((response: any, error: any) => {
      options.response = response;
      console.log(response);
      console.log(options);
      console.log(error)
      response.json = JSON.stringify(response);
      response.payment_id = val.payment_id;
      response.created_by = sessionStorage.UserId,
        this.paymentResponse(response);
      // call your backend api to verify payment signature & capture transaction
    });
    options.modal.ondismiss = (() => {
      // handle the case when user closes the form while transaction is in progress
      console.log('Transaction cancelled.');
    });
    const rzp = new this.winRef.nativeWindow.Razorpay(options);
    rzp.on('payment.failed', function (respons: any) {
      console.log(respons);
      alert("This step of Payment Failed");
    });
    rzp.open();
  }

  paymentResponse(data: any) {
    this.CommonService.postCall('Payment/confirm', data).subscribe((res: any) => {
      setTimeout(() => {
        this.paymentstatus = true;
        // setTimeout(() => { this.route.navigate(['/']) }, 30000);
        document.getElementById('md_close').click();
        this.toastr.success('Payment Successfully');
        this.route.navigate(['HOME/my-courses']);

      }, 2000);

      // this.cloasewindow();

    }, e => {

    })

  }

  compareDates(d1: string, d2: string): number {
    const date1 = new Date(d1).getTime();
    const date2 = new Date(d2).getTime();
    let returnval = 0;
    if (date1 < date2) {
      console.log(`${d1} is less than ${d2}`);
      returnval = 1;
    } else if (date1 > date2) {
      console.log(`${d1} is greater than ${d2}`);
      returnval = -1;
    } else {
      console.log(`Both dates are equal`);
      returnval = 0;
    }
    return returnval;
  }
  add() {
    if (this.educationData.length < 4) {
      this.educationData.push({
        INSTITUTION_NAME: this.INSTITUTION_NAME,
        EDUCATION_COUNTRY_ID: this.EDUCATION_COUNTRY_ID,
        QUALIFICATION: this.QUALIFICATION,
        EXAM_REG_NO: this.EXAM_REG_NO,
        LEVEL_OF_STUDY: this.LEVEL_OF_STUDY,
        PERCENTAGE: 0,
        FROM_PERIOD: this.FROM_PERIOD,
        TO_PERIOD: this.TO_PERIOD,
        ADDITIONAL_INFORMATION: this.ADDITIONAL_INFORMATION,
      })
    }
  }
  remove(index: number) {
    if (index >= 0 && index < this.educationData.length) {
      this.educationData.splice(index, 1);
    }
  }

  addApplicantDetails() {
    this.ApplicantData.push({
      WORKEXP_DETAIL_EMPLOYER: this.WORKEXP_DETAIL_EMPLOYER,
      WORKEXP_DETAIL_DESIGNATION: this.WORKEXP_DETAIL_DESIGNATION,
      WORKEXP_DETAIL_FROM_YEAR: this.WORKEXP_DETAIL_FROM_YEAR,
      WORKEXP_DETAIL_TO_YEAR: this.WORKEXP_DETAIL_TO_YEAR,
    })
  }
  removeApplicantDetails(index: any) {
    if (index >= 0 && index < this.ApplicantData.length) {
      this.educationData.splice(index, 1);
    }
  }
  addRefreeData() {
    this.RefreeData.push({
      NAME: this.NAME,
      ORGANIZATION: this.ORGANIZATION,
      CITY_TOWN: this.CITY_TOWN,
      NATIONALITY: this.NATIONALITY,
      EMAIL_ADDRESS: this.EMAIL_ADDRESS,
      MOBILE_NUMBER: this.MOBILE_NUMBER
    })
  }
  removeRefreeData(index: any) {
    if (index >= 0 && index < this.RefreeData.length) {
      this.RefreeData.splice(index, 1);
    }
  }
  validateEmail(event: any, targetField: string) {
    let val = event.target.value;
    let exp = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;

    if (targetField === 'sponserEmail') {
      if (new RegExp(exp).test(val)) {
        this.sponserEmail = val;
      } else {
        this.sponserEmail = null;
        this.toastr.warning('Please Enter Valid Email');
      }
    } else if (targetField === 'Email') {
      if (new RegExp(exp).test(val)) {
        this.Emailid = val;
      } else {
        this.Emailid = null;
        this.toastr.warning('Please Enter Valid Email');
      }
    }
  }
  removeFile() {
    this.fileInputRef.nativeElement.value = '';
  }
}

interface ISessionDetails {
  COURSESHD_AMOUNT: number;
  COURSESHD_COURSE_ID: number;
  COURSESHD_ENDDATE: string;
  COURSESHD_ENDTIME: string;
  COURSESHD_ID: number;
  COURSESHD_NAME: string;
  COURSE_FEES: number;
  COURSESHD_STARTDATE: string;
  COURSESHD_STARTTIME: string;
  REGISTRATION_STARTDATE: string;
  REGISTRATION_ENDDATE: string;
  COURSESHD_PAYMENT_METHOD: number;
  Total_Payable_Amount: number;
}


